import * as React from 'react';
import { Link } from "react-router-dom";
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import DashboardIcon from '@mui/icons-material/Dashboard';
import WorkIcon from '@mui/icons-material/Work';
import BarChartIcon from '@mui/icons-material/BarChart';
//import LayersIcon from '@mui/icons-material/Layers';
//import AssignmentIcon from '@mui/icons-material/Assignment';
//import PersonIcon from '@mui/icons-material/Person';
import EmailIcon from '@mui/icons-material/Email';
import ShowChartIcon from '@mui/icons-material/ShowChart';
//import SlideshowIcon from '@mui/icons-material/Slideshow';
import PaymentsIcon from '@mui/icons-material/Payments';
import StorageIcon from '@mui/icons-material/Storage';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import BiotechIcon from '@mui/icons-material/Biotech';
import './stratfin.css'

export const mainListItems = (
  <React.Fragment>
    <Link to="/stratfin/welcome" className="link-text">
      <ListItemButton>
        <ListItemIcon>
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText primary="Welcome" />
      </ListItemButton>
    </Link>
    <Link to="/stratfin/careers" className="link-text">
    <ListItemButton>
      <ListItemIcon>
        <WorkIcon  />
      </ListItemIcon>
      <ListItemText primary="Career Guide"/>
    </ListItemButton>
    </Link>

    {/* Income Statement */}
    <Link to="/stratfin/incomestatement" className="link-text">
      <ListItemButton>
        <ListItemIcon>
          <PaymentsIcon />
        </ListItemIcon>
        <ListItemText primary="Income Statement" />
      </ListItemButton>
    </Link>
    <Link to="/stratfin/incomestatement/google" className="link-text">
      <ListItemButton>
        <ListItemIcon>
          <PaymentsIcon className="blank-icon" />
        </ListItemIcon>
        <ListItemText primary="Google" className="blank-text"/>
      </ListItemButton>
    </Link>
    <Link to="/stratfin/incomestatement/southwest" className="link-text">
      <ListItemButton>
        <ListItemIcon>
          <PaymentsIcon className="blank-icon" />
        </ListItemIcon>
        <ListItemText primary="Southwest" className="blank-text"/>
      </ListItemButton>
    </Link>
    <Link to="/stratfin/incomestatement/tesla" className="link-text">
      <ListItemButton>
        <ListItemIcon>
          <PaymentsIcon className="blank-icon" />
        </ListItemIcon>
        <ListItemText primary="Tesla" className="blank-text"/>
      </ListItemButton>   
    </Link>

    <Link to="/stratfin/financialmodeling" className="link-text">
     <ListItemButton>
      <ListItemIcon>
        <BarChartIcon />
      </ListItemIcon>
      <ListItemText primary="Financial Modeling" />
     </ListItemButton>
    </Link>
    {/* <ListItemButton>
      <ListItemIcon>
        <BarChartIcon className="blank-icon" />
      </ListItemIcon>
      <ListItemText primary="P&L Model" className="blank-text"/>
    </ListItemButton>     */}
    {/* <ListItemButton><ListItemIcon><BarChartIcon className="blank-icon" /></ListItemIcon><ListItemText primary="Strategy" className="blank-text"/></ListItemButton>    
    <ListItemButton><ListItemIcon><BarChartIcon className="blank-icon" /></ListItemIcon><ListItemText primary="Analysis" className="blank-text"/></ListItemButton>    
    <ListItemButton><ListItemIcon><BarChartIcon className="blank-icon" /></ListItemIcon><ListItemText primary="Excel Functions" className="blank-text"/></ListItemButton>   
     */}

    {/* Forecasting */}
    {/* <Link to="/stratfin/forecasting" className="link-text">
    <ListItemButton>
      <ListItemIcon>
        <LayersIcon />
      </ListItemIcon>
      <ListItemText primary="Forecasting" />
    </ListItemButton>
    </Link>
    <ListItemButton><ListItemIcon><LayersIcon className="blank-icon" /></ListItemIcon><ListItemText primary="Model" className="blank-text"/></ListItemButton>    
    <ListItemButton><ListItemIcon><LayersIcon className="blank-icon" /></ListItemIcon><ListItemText primary="Reporting" className="blank-text"/></ListItemButton>    
    <ListItemButton><ListItemIcon><LayersIcon className="blank-icon" /></ListItemIcon><ListItemText primary="Process" className="blank-text"/></ListItemButton>    
     */}

    {/* Presentations */}
    {/* <ListItemButton>
      <ListItemIcon>
        <SlideshowIcon />
      </ListItemIcon>
      <ListItemText primary="Presentations" />
    </ListItemButton> */}
    
    {/* Metrics */}
    <Link to="/stratfin/metrics" className="link-text">
    <ListItemButton>
      <ListItemIcon>
        <ShowChartIcon />
      </ListItemIcon>
      <ListItemText primary="Metrics" />
    </ListItemButton>
    </Link>
    <Link to="/stratfin/metrics/businessreviews" className="link-text"><ListItemButton><ListItemIcon><ShowChartIcon className="blank-icon" /></ListItemIcon><ListItemText primary="Business Reviews" className="blank-text"/></ListItemButton></Link>  
    <Link to="/stratfin/metrics/uniteconomics" className="link-text"><ListItemButton><ListItemIcon><ShowChartIcon className="blank-icon" /></ListItemIcon><ListItemText primary="Unit Economics" className="blank-text"/></ListItemButton></Link>  


    <Link to="/stratfin/sql" className="link-text">
    <ListItemButton>
      <ListItemIcon>
        <StorageIcon />
      </ListItemIcon>
      <ListItemText primary="SQL" />
    </ListItemButton>
    </Link>
    
    {/* Unit Economics */}
    {/* <ListItemButton>
      <ListItemIcon>
        <SlideshowIcon />
      </ListItemIcon>
      <ListItemText primary="Unit Economics" />
    </ListItemButton> */}

    {/* Research */}
    <Link to="/stratfin/research" className="link-text">
      <ListItemButton>
        <ListItemIcon>
          <LibraryBooksIcon />
        </ListItemIcon>
        <ListItemText primary="Research" />
      </ListItemButton>
    </Link>

    {/* Deep Dives */}
    <Link to="/stratfin/deepdive" className="link-text">
      <ListItemButton>
        <ListItemIcon>
          <BiotechIcon />
        </ListItemIcon>
        <ListItemText primary="Deep Dives" />
      </ListItemButton>
    </Link>

  </React.Fragment>
);

export const secondaryListItems = (
  <React.Fragment>
    <ListSubheader component="div" inset>
      Need Help?
    </ListSubheader>
    <Link to="/stratfin/resources" className="link-text">
      <ListItemButton>
        <ListItemIcon>
          <EmailIcon />
        </ListItemIcon>
        <ListItemText primary="Email Us" />
      </ListItemButton>
    </Link>
    {/* <ListItemButton>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Last quarter" />
    </ListItemButton> */}
  </React.Fragment>
);