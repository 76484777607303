import * as React from 'react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { mainListItems, secondaryListItems } from '../listitems';
import '../sql.css'

const drawerWidth = 260;

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
    },
  }),
);

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme({
    typography: {
      fontFamily: "Inter"
    },
  });

export default function Formatting() {
  const [open, setOpen] = React.useState(true);
  const toggleDrawer = () => {
    setOpen(!open);
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <Drawer variant="permanent" open={open}>
          <Toolbar sx={{ display: 'flex', alignItems: 'center', backgroundColor: '#1b213b', justifyContent: 'flex-end', px: [1],}}>
            <h3 className='program-title'>SQL For Business</h3>
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon  style={{ color: 'white' }}/>
            </IconButton>
          </Toolbar>
          <Divider />
          <List component="nav">
            {mainListItems}
            <Divider sx={{ my: 1 }} />
            {secondaryListItems}
          </List>
        </Drawer>
        <Box component="main" sx={{ backgroundColor: 'white', flexGrow: 1, height: '100vh', overflow: 'auto', }}>
          <Container maxWidth="lg" sx={{ mt: 4, mb: 2, ml: 1, mr: 1}}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={2} lg={2}></Grid>
              <Grid item xs={12} md={8} lg={8}>
                <Paper sx={{p: 2,display: 'flex', flexDirection: 'column', boxShadow: 'none'}}>

                  <h1 className='program-header'>Formatting</h1><br/>
                    <p className='program-text'>You could argue writing clean SQL code is just as important as knowing how to use it. You will be sharing SQL code with your co-workers so writing queries that are easy to read is very important. Writing clean code will both save time and make you look really good at writing SQL queries.</p>
                    <p className='program-text'>There is no <b>correct</b> way to write SQL, but there are some general principles you should follow. To get started, here is what we consider to be <b>clean</b> code.</p>
                    <br/>
                    <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', backgroundColor: '#f1f1f1'}}>
                      <p className='program-text-single'>SELECT</p>
                      <p className='program-text-single'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;customer_id,</p>
                      <p className='program-text-single'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;first_name,</p>
                      <p className='program-text-single'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;last_name,</p>
                      <p className='program-text-single'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;birthday,</p>
                      <p className='program-text-single'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;rewards_member</p>
                      <p className='program-text-single'>FROM</p>
                      <p className='program-text-single'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;customer_table</p>
                      <p className='program-text-single'>WHERE</p>
                      <p className='program-text-single'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;rewards_member = TRUE</p>
                      <p className='program-text-single'>ORDER BY</p>
                      <p className='program-text-single'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;customer_id</p>
                    </Paper>
                    <br/>
                    <p className='program-text'>Compare the query above to the one below. It is the same exact query just written in a different format.</p>
                    <br/>
                    <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', backgroundColor: '#f1f1f1'}}>
                      <p className='program-text-single'>select customer_id, first_name, last_name, birthday, rewards_member from customer_table</p>
                      <p className='program-text-single'>where rewards_member = true order by customer_id</p>
                    </Paper>
                    <br/>
                    <p className='program-text'>Yes the code takes up less lines - but it's harder for the eyes to read.</p>
                    <p className='program-text'>Here are our formatting best practices for SQL queries. You don't need to worry about formatting for very basic queries. Writing good SQL means making it easy to read. You'll pick this up as you practice more.</p>

                    <br/>
                    <h2 className='program-sub-header-unbold'>Spacing</h2>
                    <p className='program-text'>Every column name and command should have their own line. Reading SQL is about skimming the code until you find what you are looking for. So laying out code vertically will take up more space, but it makes it easier to skim reading vertically.</p>
                    <p className='program-text'>Second, indent everything after the command so that the command sticks out. Doing this allows the user to visually understand how the query is setup before even reading it.</p>

                    <br/>
                    <h2 className='program-sub-header-unbold'>Capitalization</h2>
                    <p className='program-text'>Another way to make the commands stand out is by putting them in all caps. This easily allows the reader to determine if a word is a command or something else. Related to that, keep all column names in lower case, no need to capitalize any parts of it.</p>

                    <br/>
                    <h2 className='program-sub-header-unbold'>Commas</h2>
                    <p className='program-text'>Some people put the commas that separate column names before the column name. We prefer to put it afterwards because thats how humans read. We read the text and then pause at the commas.</p>

                </Paper>
              </Grid>
              <Grid item xs={12} md={2} lg={2}></Grid>
              <Grid item xs={12} md={2} lg={2}></Grid>
            </Grid>
          </Container>    
        </Box>
      </Box>
    </ThemeProvider>
  );
}