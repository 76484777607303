import * as React from 'react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { mainListItems, secondaryListItems } from '../listitems';
import '../sql.css'

const drawerWidth = 260;

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
    },
  }),
);

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme({
    typography: {
      fontFamily: "Inter"
    },
  });

export default function Glossary() {
  const [open, setOpen] = React.useState(true);
  const toggleDrawer = () => {
    setOpen(!open);
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <Drawer variant="permanent" open={open}>
          <Toolbar sx={{ display: 'flex', alignItems: 'center', backgroundColor: '#1b213b', justifyContent: 'flex-end', px: [1],}}>
            <h3 className='program-title'>SQL For Business</h3>
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon  style={{ color: 'white' }}/>
            </IconButton>
          </Toolbar>
          <Divider />
          <List component="nav">
            {mainListItems}
            <Divider sx={{ my: 1 }} />
            {secondaryListItems}
          </List>
        </Drawer>
        <Box component="main" sx={{ backgroundColor: 'white', flexGrow: 1, height: '100vh', overflow: 'auto', }}>
          <Container maxWidth="lg" sx={{ mt: 4, mb: 2, ml: 1, mr: 1}}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={2} lg={2}></Grid>
              <Grid item xs={12} md={8} lg={8}>
                <Paper sx={{p: 2,display: 'flex', flexDirection: 'column', boxShadow: 'none'}}>

                  <h1 className='program-header'>SQL In The Real World</h1><br/>
                    <p className='program-text'>This section provides tips and insights for how SQL is really used in the business world. Using SQL in the real world involves a lot of guess and checking, a lot of Googling, and a lot of waiting. </p>   
                    <br/>
                    <p className='program-text'><b>Open Notebook Test</b></p>
                    <p className='program-text'>The best part of using SQL in the real world is that you can cheat. You technically don't need to memorize any SQL commands or syntax since you can just look it up. The answer to pretty much any SQL problem you have can easily be found online and we're at the point where Gen AI tools can write queries for you.</p>
                    <br/>
                    <p className='program-text'><b>Guess and Check</b></p>
                    <p className='program-text'>Business professionals make a lot of mistakes writing SQL. It is easy to forget a comma, miss a command, or mess up a join. You will make a lot of mistakes. It doesn't matter because SQL will usually tell you what you did wrong in the error message so you can fix it and run it again. Don't get discouraged when you have to re-write a query eight times, happens to all of us.</p>
                    <br/>
                    <p className='program-text'><b>Waiting</b></p>
                    <p className='program-text'>Something you haven't experience too much in this program is how long some queries can take to run. The practice sets in this program involve smaller data tables compared to the tables you will run in your career. It is common to wait several minutes for a SQL query to run because it contains so much data.</p>
                    <p className='program-text'>Waiting for a SQL query to run is the most frustrating part of the job. If you make a mistake in your query, you have to correct it and then wait again for the query to run.</p>

                </Paper>
              </Grid>
              <Grid item xs={12} md={2} lg={2}></Grid>
              <Grid item xs={12} md={2} lg={2}></Grid>
            </Grid>
          </Container>    
        </Box>
      </Box>
    </ThemeProvider>
  );
}