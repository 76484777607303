import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Homepage from './Homepage';
import Programs from './org/programs';
import Financials from './org/financials';
import Blog from './org/blog';
import About from './org/About.js';
import Netflix from './blog/Netflix';
import Disney from './blog/Disney';

//StratFin Program
import StratFin from './stratfin/Dashboard';
import Welcome from './stratfin/pages/welcome';
import Careers from './stratfin/pages/careers';
import Analyst from './stratfin/pages/analyst';
import IncomeStatement from './stratfin/pages/incomestatement';
import GoogleIS from './stratfin/pages/google';
import SouthwestIS from './stratfin/pages/southwest';
import TeslaIS from './stratfin/pages/tesla';
import FinancialModeling from './stratfin/pages/financialmodeling';
import Forecasting from './stratfin/pages/forecasting';
import Metrics from './stratfin/pages/metrics';
import BusinessReviews from './stratfin/pages/businessreviews';
import UnitEconomics from './stratfin/pages/uniteconomics';
import SQL from './stratfin/pages/sql';
import Research from './stratfin/pages/research';
import DeepDive from './stratfin/pages/deepdive';
import StratFinResources from './stratfin/pages/resources';


//SQL For Business Program
import SqlForBusiness from './sql/pages/homepage';
import GettingData from './sql/pages/gettingdata';
import SortingData from './sql/pages/sortingdata';
import Formatting from './sql/pages/formatting';
import Filtering from './sql/pages/filtering';
import Calculations from './sql/pages/calculations';
import Combining from './sql/pages/combining';
import RealWorld from './sql/pages/realworld';
import Glossary from './sql/pages/glossary';
import Resources from './sql/pages/resources';

//CSS
import './App.css'; // Assuming you have some basic CSS in App.css

function App() {
  return (
    <BrowserRouter>
        <Routes>
            <Route path="/" element={<Homepage />} />
            <Route path="/programs" element={<Programs />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/about" element={<About />} />
            <Route path="/blog/netflix" element={<Netflix />} />
            <Route path="/blog/disney" element={<Disney />} />
            <Route path="/financials" element={<Financials />} />

            {/* StratFin */}
            <Route path="stratfin" element={<StratFin />} />
              <Route path="/stratfin/welcome" element={<Welcome/>} />
              <Route path="/stratfin/careers" element={<Careers />} />
              <Route path="/stratfin/analyst" element={<Analyst/>} />
              <Route path="/stratfin/incomestatement" element={<IncomeStatement/>} />
                <Route path="/stratfin/incomestatement/google" element={<GoogleIS/>} />
                <Route path="/stratfin/incomestatement/southwest" element={<SouthwestIS/>} />
                <Route path="/stratfin/incomestatement/tesla" element={<TeslaIS/>} />
              <Route path="/stratfin/financialmodeling" element={<FinancialModeling/>} />
              <Route path="/stratfin/forecasting" element={<Forecasting/>} />
              <Route path="/stratfin/metrics" element={<Metrics/>} />
                <Route path="/stratfin/metrics/businessreviews" element={<BusinessReviews/>} />
                <Route path="/stratfin/metrics/uniteconomics" element={<UnitEconomics/>} />
              <Route path="/stratfin/sql" element={<SQL/>} />
              <Route path="/stratfin/research" element={<Research/>} />
              <Route path="/stratfin/deepdive" element={<DeepDive/>} />
              <Route path="/stratfin/resources" element={<StratFinResources/>} />

              {/* SQL */}
              <Route path="/sql" element={<SqlForBusiness />} />
              <Route path="/sql/getting-data" element={<GettingData />} />
              <Route path="/sql/sorting-data" element={<SortingData />} />
              <Route path="/sql/formatting" element={<Formatting />} />
              <Route path="/sql/filtering-data" element={<Filtering/>} />
              <Route path="/sql/calculations" element={<Calculations/>} />
              <Route path="/sql/combining" element={<Combining/>} />
              <Route path="/sql/realworld" element={<RealWorld/>} />
              <Route path="/sql/resources" element={<Resources/>} />
              <Route path="/sql/glossary" element={<Glossary/>} />


        </Routes>
    </BrowserRouter>
  );
}

export default App;
