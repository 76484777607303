import React from 'react';
import AppBarStyle from './components/AppBarStyle';
import Hero from './components/Hero';
import Features from './components/Features';
import Highlights from './components/Highlights';
import FAQ from './components/FAQ';
import { Helmet } from 'react-helmet';
import './App.css'; // Assuming you have some basic CSS in App.css

function Homepage() {
  return (
      <div>
        <Helmet>
          <title>Alcove School</title>
        </Helmet>
          <AppBarStyle/>
          <Hero />
          <Features />
          <Highlights />
          <FAQ />
    </div>
  );
}

export default Homepage;
