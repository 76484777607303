import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function FAQ() {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Container
      id="faq"
      maxWidth="md" 
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: { xs: 3, sm: 6 },
      }}
    >
      <Typography
        component="h2"
        variant="h4"
        color="text.primary"
        sx={{
          width: { sm: '100%', md: '60%' },
          textAlign: { sm: 'left', md: 'center' },
        }}
      >
        <b>Frequently Asked Questions</b>
      </Typography>
      <Box sx={{ width: '100%' }}>
        <Accordion
          expanded={expanded === 'panel1'}
          onChange={handleChange('panel1')}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1d-content"
            id="panel1d-header"
          >
            <Typography component="h4" variant="subtitle1">
              Is everything really free? What is the catch?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              variant="body2"
              gutterBottom
              sx={{ maxWidth: { sm: '100%', md: '100%' } }}
            >
              Yes, everything at Alcove School is 100% free. No tuition, no paid courses, no ads. We don't even collect emails for marketing purposes. 
              Alcove School was created and currently run by working business professionals who volunteer their time to teach students of all ages and backgrounds. Our volunteers are motivated to provide a better learning experience than traditional business schools.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <br/>
        <Accordion
          expanded={expanded === 'panel3'}
          onChange={handleChange('panel3')}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3d-content"
            id="panel3d-header"
          >
            <Typography component="h4" variant="subtitle1">
              What can I do to get the most out of my experience?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              variant="body2"
              gutterBottom
              sx={{ maxWidth: { sm: '100%', md: '100%' } }}
            >
              We recommend you take the time to practice what you learn outside of the course. Our programs only offer so many practice problems, repeated practice over several months is the only proven way to retain what you learn.
            </Typography>
          </AccordionDetails>
        </Accordion><br/>
        <Accordion
          expanded={expanded === 'panel4'}
          onChange={handleChange('panel4')}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel4d-content"
            id="panel4d-header"
          >
            <Typography component="h4" variant="subtitle1">
                How can I get involved with Alcove School?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              variant="body2"
              gutterBottom
              sx={{ maxWidth: { sm: '100%', md: '100%' } }}
            >
              We are always looking for volunteers to help build out content and mentors students. Send us an email at hello@alcoveschool.com to reach out.
            </Typography>
          </AccordionDetails>
        </Accordion>
      </Box>
    </Container>
  );
}