import React from 'react';
import { Link } from "react-router-dom";
import { 
  Container, 
  Typography, 
  Grid, 
  Card, 
  CardContent, 
  CardMedia,
  Box
} from '@mui/material';
import Button from '@mui/material/Button';
//import StratFinIcon from '../images/stratfinicon.jpg';
//import HomeIcon1 from '../images/homeicon1.png';
//import HomeIcon2 from '../images/homeicon2.png';
//import HomeIcon3 from '../images/homeicon3.png';
import sqlprogram2 from '../images/sqlprogram2.png';
import stratfin1 from '../images/stratfin1.png';
import finstate from '../images/finstate.png';

const Features = () => {
  return (
    <Container maxWidth="lg">
        <Box my={4} sx={{}}>
        {/* <Grid container spacing={4} my={4}>
          <Grid item xs={12} md={6}>
            <Typography variant="body1" paragraph>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
            </Typography>
            <Typography variant="body1" paragraph>
              Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
            </Typography>
           
          </Grid>
          <Grid item xs={12} md={6}>
            <Card>
              <CardMedia
                component="img"
                height="300"
                image="https://source.unsplash.com/random/800x600?business"
                alt="About Us"
              />
            </Card>
          </Grid>
        </Grid><br /><br /> */}
        
        {/* <Typography variant="h4" component="h2" gutterBottom align="center" mt={6}>
          Our Progress
        </Typography> */}
        
        <Grid container spacing={4} my={4}>
            <Grid item xs={12} sm={6} md={4}>
              <Card>
                <CardMedia
                  component="img"
                  height="200"
                  image={stratfin1}
                />
                <CardContent>
                  <Typography gutterBottom variant="h6" component="div">
                    Strategic Finance Program
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Explore what we consider the most interesting career path in the business world.
                  </Typography>
                  <br/>
                  {/* backgroundColor: '#1b213b' */}
                  <Button
                    size="small"
                    sx={{backgroundColor: '#1b213b', textccolor: 'white'}}>
                    <Link to="/stratfin/welcome" target='_blank'><Typography sx={{fontSize: "14px"}}className='button-text'>Start Learning</Typography></Link>
                </Button>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Card>
                <CardMedia
                  component="img"
                  height="200"
                  image={sqlprogram2}
                />
                <CardContent>
                  <Typography gutterBottom variant="h6" component="div">
                    SQL for Business
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Learn all the SQL you need to know to level up your business skills.
                  </Typography>
                  <br/>
                  <Button size="small" sx={{backgroundColor: '#1b213b', textccolor: 'white'}}>
                    <Link to="/sql" target='_blank'><Typography sx={{fontSize: "14px"}}className='button-text'>Start Learning</Typography></Link>
                </Button>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
            <Card>
                <CardMedia
                  component="img"
                  height="200"
                  image={finstate}
                />
                <CardContent>
                  <Typography gutterBottom variant="h6" component="div">
                    Financial Statements 101
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Mastering the three financial statements are critical to any business career.
                  </Typography>
                  <br/>
                  <Button
                    sx = {{color: '#000', backgroundColor: '#ededed'}}
                    variant="contained"
                    size="small"
                    component="a"
                    target="_blank">
                    Coming Soon
                </Button>
                </CardContent>
              </Card>
            </Grid>
        </Grid>
      </Box><br /><br /><br /><br /><br />
    </Container>
  );
};

export default Features;
