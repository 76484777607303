import * as React from 'react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { mainListItems, secondaryListItems } from '../listitems';
import { Helmet } from 'react-helmet';
import '../sql.css'

import ImgAppleOffice from '../images/appleoffice.jpeg';

const drawerWidth = 260;

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
    },
  }),
);

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme({
    typography: {
      fontFamily: "Inter"
    },
  });

export default function Welcome() {
  const [open, setOpen] = React.useState(true);
  const toggleDrawer = () => {
    setOpen(!open);
  };

  return (
    <>
      <Helmet>
        <title>SQL For Business Program</title>
      </Helmet>
    <ThemeProvider theme={defaultTheme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <Drawer variant="permanent" open={open}>
          <Toolbar sx={{ display: 'flex', alignItems: 'center', backgroundColor: '#1b213b', justifyContent: 'flex-end', px: [1],}}>
            <h3 className='program-title'>SQL For Business</h3>
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon  style={{ color: 'white' }}/>
            </IconButton>
          </Toolbar>
          <Divider />
          <List component="nav">
            {mainListItems}
            <Divider sx={{ my: 1 }} />
            {secondaryListItems}
          </List>
        </Drawer>
        <Box component="main" sx={{ backgroundColor: 'white', flexGrow: 1, height: '100vh', overflow: 'auto', }}>
          <Container maxWidth="lg" sx={{ mt: 4, mb: 2, ml: 1, mr: 1}}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={2} lg={2}></Grid>
              <Grid item xs={12} md={8} lg={8}>
                <Paper sx={{p: 2,display: 'flex', flexDirection: 'column', boxShadow: 'none'}}>

                  <h1 className='program-header'>SQL For Business Program</h1><br/>
                    <p className='program-text'>Welcome to the SQL For Business program, where you will learn everything about using SQL in the business world. So why do you need to learn SQL?</p>
                    <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', backgroundColor: '#dee8fa', marginTop: '20px'}}>
                      <p className='program-text'>Business professionals use SQL to access data.</p>
                    </Paper><br/>
                    <p className='program-text'>Knowing SQL is a superpower in the business world because its gives you access to your company's data which you can turn into critical insights.</p>
                  <br/>
                  <h2 className='program-sub-header'>Data 101</h2> 
                      <p className='program-text'>Companies are swamped with data. Look at Google. They record your search history to create AI models to better serve you ads. They store your emails, photos, and your YouTube subsriptions.</p>
                      <p className='program-text'>All of that data needs to be stored electronically in a data center somewhere in the world. To keep track of this data, companies hire data engineers to store this data so that it can be easily accessed later. A common method for storing data is in a data table, which is a way to store data in a grid format like this:</p>
                      <br/>
                      <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 350,}} aria-label="simple table">
                            <TableHead>
                            <TableRow sx={{backgroundColor: '#1b213b'}}>
                                <TableCell sx={{color: 'white', lineHeight: '1.2'}}>YouTube User ID</TableCell>
                                <TableCell sx={{color: 'white', lineHeight: '1.2'}} align="right">Account Created Date</TableCell>
                                <TableCell sx={{color: 'white', lineHeight: '1.2'}} align="right">Number of Subscribers</TableCell>
                                <TableCell sx={{color: 'white', lineHeight: '1.2'}} align="right">Total Hours Watched</TableCell>
                                <TableCell sx={{color: 'white', lineHeight: '1.2'}} align="right">Video Like Count</TableCell>
                            </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                <TableCell component="th" scope="row">1034029304</TableCell>
                                <TableCell align="right">04/02/2007</TableCell>
                                <TableCell align="right">1</TableCell>
                                <TableCell align="right">1,964</TableCell>
                                <TableCell align="right">857</TableCell>
                                </TableRow>
                                <TableRow sx={{ backgroundColor: '#f4f4f4' }}>
                                <TableCell component="th" scope="row">494028404123</TableCell>
                                <TableCell align="right">07/19/2018</TableCell>
                                <TableCell align="right">0</TableCell>
                                <TableCell align="right">95</TableCell>
                                <TableCell align="right">3</TableCell>
                                </TableRow>
                                <TableRow>
                                <TableCell component="th" scope="row">985022457463</TableCell>
                                <TableCell align="right">12/11/2019</TableCell>
                                <TableCell align="right">9</TableCell>
                                <TableCell align="right">10</TableCell>
                                <TableCell align="right">8</TableCell>
                                </TableRow>
                                <TableRow sx={{ backgroundColor: '#f4f4f4' }}>
                                <TableCell component="th" scope="row">2837291224</TableCell>
                                <TableCell align="right">03/02/2011</TableCell>
                                <TableCell align="right">4,203</TableCell>
                                <TableCell align="right">2,109</TableCell>
                                <TableCell align="right">78</TableCell>
                                </TableRow>
                                <TableRow>
                                <TableCell component="th" scope="row">3392985476591</TableCell>
                                <TableCell align="right">04/03/2024</TableCell>
                                <TableCell align="right">0</TableCell>
                                <TableCell align="right">2</TableCell>
                                <TableCell align="right">3</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                        </TableContainer>

                  <br/>
                  <h2 className='program-sub-header'>SQL 101</h2>
                      <p className='program-text'>SQL is a computer programming language that allows for humans to speak with data.</p>
                      <p className='program-text'>SQL, an abbreviation for Structured Query Language, allows us to write english to communicate with our computers to analyze data. We communicate with the computer by using keywords, called 'commands', which are english phrases like 'select', 'where', and 'order by' to tell the computer what to do.</p>

                  <br/>
                  <h2 className='program-sub-header'>A SQL Example</h2> 
                      <p className='program-text'>An example might help here. Let's pretend you're a financial analyst at Apple and you need to find out how many active iPhones there are in the world for a project you are working on. Here is the view from your desk at Apple Headquarters.</p>
                      <br/><img src={ImgAppleOffice} alt='Apple Headquarters'/><br/>

                      <p className='program-text'>The first issue solving this problem is that there are over 1 billion iPhones in the world, far too many for this data to be stored in Excel or a Google Sheet. So Apple stores their data in a database (where cloud computing tools allow for cheap and easy storage of company data). </p>
                      <p className='program-text'>Second, you need to the most updated number of iPhones so looking through old internal documents or asking a co-worker won't help - you need the most updated number.</p>
                      <p className='program-text'>So the first step to find the answer is to open the cloud computing software which is where we can write SQL to access the data stored in the database. Since this hypothetical is about a bright financial analyst with solid SQL skills, they can easily write this SQL query to find out how many active iPhones there are in the world:</p>
                      <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', backgroundColor: '#f1f1f1', marginTop: '20px'}}>
                        <p className='program-text-single'>SELECT</p>
                        <p className='program-text-single'>&nbsp;&nbsp;&nbsp;COUNT(device_id)</p>
                        <p className='program-text-single'>FROM</p>
                        <p className='program-text-single'>&nbsp;&nbsp;&nbsp;active_iphone_data_table</p>
                      </Paper>
                      <br/>
                      <p className='program-text'>The software will then access the database directly based on what the SQL code tells it to do.</p>
                      <p className='program-text'>Aftering processing for a few seconds, the software displays the following number:</p>
                      <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', backgroundColor: '#f1f1f1', marginTop: '20px'}}>
                        <p className='program-text-single'>1,263,902,554</p>
                      </Paper>
                      <br/>
                      <p className='program-text'>The database keeps it nice and simple and tells us only what we asked for. The database is telling us that there are over 1.2 billion iPhones currently being used in the world. A quick check tells us that will 8 billion people in the world this implies that 15% of the world's population has an iPhone. Sounds reasonable enough to conclude this answer is correct.</p>
                    <br/>

   
                  <h2 className='program-sub-header'>Commands</h2>
                  <p className='program-text'>Learning SQL is identical to learning a human language. The only difference is that SQL only has a dozen or so words to learn, not thousands like most languages. </p>
                  <p className='program-text'>The words are called commands and we are going to go through each one individually so you can build up your 'vocabulary' and then write SQL queries with multiple commands.</p>
                  <p className='program-text'>Jump to the next section to learn your first two commands.</p>

                  </Paper>
              </Grid>
              <Grid item xs={12} md={2} lg={2}></Grid>
              <Grid item xs={12} md={2} lg={2}></Grid>
            </Grid>
          </Container>    
        </Box>
      </Box>
    </ThemeProvider></>
  );
}