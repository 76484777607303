import * as React from 'react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { mainListItems, secondaryListItems } from '../listitems';
import '../sql.css'

const drawerWidth = 260;

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
    },
  }),
);

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme({
    typography: {
      fontFamily: "Inter"
    },
  });

export default function SortingData() {
  const [open, setOpen] = React.useState(true);
  const toggleDrawer = () => {
    setOpen(!open);
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <Drawer variant="permanent" open={open}>
          <Toolbar sx={{ display: 'flex', alignItems: 'center', backgroundColor: '#1b213b', justifyContent: 'flex-end', px: [1],}}>
            <h3 className='program-title'>SQL For Business</h3>
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon  style={{ color: 'white' }}/>
            </IconButton>
          </Toolbar>
          <Divider />
          <List component="nav">
            {mainListItems}
            <Divider sx={{ my: 1 }} />
            {secondaryListItems}
          </List>
        </Drawer>
        <Box component="main" sx={{ backgroundColor: 'white', flexGrow: 1, height: '100vh', overflow: 'auto', }}>
          <Container maxWidth="lg" sx={{ mt: 4, mb: 2, ml: 1, mr: 1}}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={2} lg={2}></Grid>
              <Grid item xs={12} md={8} lg={8}>
                <Paper sx={{p: 2,display: 'flex', flexDirection: 'column', boxShadow: 'none'}}>

                  <h1 className='program-header'>Sorting Data</h1><br/>
                    <p className='program-text'>The <b>ORDER BY</b> command is how you sort data in SQL, and the only new command we will be learning in this section.</p>
                    <br/>
                    <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', backgroundColor: '#f1f1f1'}}>
                      <p className='program-text-single'>SELECT  &nbsp; * &nbsp; FROM &nbsp;continents</p>
                      <p className='program-text-single'>ORDER BY &nbsp;name</p>
                    </Paper>
                    <br/>
                    <p className='program-text'>We use the <b>ORDER BY</b> command at the end of any query because its usually the last step in any data process. We select the data we want first and then finish it by sorting the data in the order we want.</p>
                    <p className='program-text'>The code above filters the <i>continents</i> data table from the previous section. Here is the result: </p><br/>

                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 350}} aria-label="simple table">
                            <TableHead>
                            <TableRow sx={{backgroundColor: '#1b213b'}}>
                                <TableCell sx={{color: 'white', lineHeight: '1.2'}}><b>name</b></TableCell>
                                <TableCell sx={{color: 'white', lineHeight: '1.2'}}><b>population</b></TableCell>
                            </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow><TableCell component="th" scope="row">Africa</TableCell><TableCell component="th" scope="row">1,460,481,772	</TableCell></TableRow>
                                <TableRow><TableCell component="th" scope="row">Antarctica</TableCell><TableCell component="th" scope="row">0</TableCell></TableRow>
                                <TableRow><TableCell component="th" scope="row">Asia</TableCell><TableCell component="th" scope="row">4,753,079,726</TableCell></TableRow>
                                <TableRow><TableCell component="th" scope="row">Australia</TableCell><TableCell component="th" scope="row">46,004,866</TableCell></TableRow>
                                <TableRow><TableCell component="th" scope="row">Europe</TableCell><TableCell component="th" scope="row">740,433,713</TableCell></TableRow>
                                <TableRow><TableCell component="th" scope="row">North America</TableCell><TableCell component="th" scope="row">604,182,517</TableCell></TableRow>
                                <TableRow><TableCell component="th" scope="row">South America</TableCell><TableCell component="th" scope="row">439,719,009</TableCell></TableRow>
                            </TableBody>
                        </Table>
                        </TableContainer>

                        <br/>
                        <p className='program-text'><b>ORDER BY</b> sorted the rows in alphabetical order by the <i>name</i> column we specificed. Simple enough right? Let's now sort the data by the <i>population</i> column. What do you expect will happen?</p>

                        <br/>
                        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', backgroundColor: '#f1f1f1'}}>
                            <p className='program-text-single'>SELECT  &nbsp; * &nbsp; FROM &nbsp;continents</p>
                            <p className='program-text-single'>ORDER BY &nbsp;population</p>
                        </Paper>
                        <br/>
                        <p className='program-text'>Here is the result:</p>
                        <br/>
                        <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 350,}} aria-label="simple table">
                            <TableHead>
                            <TableRow sx={{backgroundColor: '#1b213b'}}>
                                <TableCell sx={{color: 'white', lineHeight: '1.2'}}><b>name</b></TableCell>
                                <TableCell sx={{color: 'white', lineHeight: '1.2'}}><b>population</b></TableCell>
                            </TableRow>
                            </TableHead>
                            <TableBody>
                            <TableRow><TableCell component="th" scope="row">Antarctica</TableCell><TableCell component="th" scope="row">0</TableCell></TableRow>
                            <TableRow><TableCell component="th" scope="row">Australia</TableCell><TableCell component="th" scope="row">46,004,866</TableCell></TableRow>
                            <TableRow><TableCell component="th" scope="row">South America</TableCell><TableCell component="th" scope="row">439,719,009</TableCell></TableRow>
                            <TableRow><TableCell component="th" scope="row">North America</TableCell><TableCell component="th" scope="row">604,182,517</TableCell></TableRow>
                            <TableRow><TableCell component="th" scope="row">Europe</TableCell><TableCell component="th" scope="row">740,433,713</TableCell></TableRow>
                            <TableRow><TableCell component="th" scope="row">Africa</TableCell><TableCell component="th" scope="row">1,460,481,772	</TableCell></TableRow>
                            <TableRow><TableCell component="th" scope="row">Asia</TableCell><TableCell component="th" scope="row">4,753,079,726</TableCell></TableRow>
                            </TableBody>
                        </Table>
                        </TableContainer>
                        <br/>
                        
                        <p className='program-text'>The data sorted from lowest to highest, probably not what we wanted. The <b>ORDER BY</b> command sorts text from A to Z and sorts numbers from lowest to highest. However, SQL gives us the ability to change that order. To do so, we can use <b>ASC</b> and <b>DESC</b>. </p>
                        <p className='program-text'>The ORDER BY command is set to ASC by default, so we rarely need to use this. What you'll see frequently is DESC, where we want to sort numbers from highest to lowest, and sometimes if we want to sort text from Z to A. Here is what it looks like:</p>
                 
                        <br/>
                        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', backgroundColor: '#f1f1f1'}}>
                            <p className='program-text-single'>SELECT  &nbsp; * &nbsp; FROM &nbsp;continents</p>
                            <p className='program-text-single'>ORDER BY &nbsp;population &nbsp;DESC</p>
                        </Paper>
                        <br/>

                        <p className='program-text'>This is how get the <i>continents</i> table finally sorted from highest to lowest population.</p>

                        <br/>
                        <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 350,}} aria-label="simple table">
                            <TableHead>
                            <TableRow sx={{backgroundColor: '#1b213b'}}>
                                <TableCell sx={{color: 'white', lineHeight: '1.2'}}><b>name</b></TableCell>
                                <TableCell sx={{color: 'white', lineHeight: '1.2'}}><b>population</b></TableCell>
                            </TableRow>
                            </TableHead>
                            <TableBody>
                            <TableRow><TableCell component="th" scope="row">Asia</TableCell><TableCell component="th" scope="row">4,753,079,726</TableCell></TableRow>
                            <TableRow><TableCell component="th" scope="row">Africa</TableCell><TableCell component="th" scope="row">1,460,481,772	</TableCell></TableRow>
                            <TableRow><TableCell component="th" scope="row">Europe</TableCell><TableCell component="th" scope="row">740,433,713</TableCell></TableRow>
                            <TableRow><TableCell component="th" scope="row">North America</TableCell><TableCell component="th" scope="row">604,182,517</TableCell></TableRow>
                            <TableRow><TableCell component="th" scope="row">South America</TableCell><TableCell component="th" scope="row">439,719,009</TableCell></TableRow>
                            <TableRow><TableCell component="th" scope="row">Australia</TableCell><TableCell component="th" scope="row">46,004,866</TableCell></TableRow>
                            <TableRow><TableCell component="th" scope="row">Antarctica</TableCell><TableCell component="th" scope="row">0</TableCell></TableRow>
                            </TableBody>
                        </Table>
                        </TableContainer>
                        <br/>

                    <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', backgroundColor: '#dee8fa', marginTop: '20px'}}>
                      <h3 className='program-practice-header'>Practice</h3>
                      <p className='program-text'>https://www.db-fiddle.com/f/npwz1ULaoSPBzazg6pJ3i4/0</p>
                   </Paper>



                 
                 
                 
                  </Paper>
              </Grid>
              <Grid item xs={12} md={2} lg={2}></Grid>
              <Grid item xs={12} md={2} lg={2}></Grid>
            </Grid>
          </Container>    
        </Box>
      </Box>
    </ThemeProvider>
  );
}