import * as React from 'react';
import { alpha } from '@mui/material';
import Box from '@mui/material/Box';
//import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
//import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
//import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

export default function Hero() {
  return (
    <Box
      id="hero"
      sx={(theme) => ({
        width: '100%',
        backgroundImage:
          theme.palette.mode === 'light'
            ? 'linear-gradient(180deg, #CEE5FD, #FFF)'
            : `linear-gradient(#02294F, ${alpha('#090E10', 0.0)})`,
        backgroundSize: '100% 60%',
        backgroundRepeat: 'no-repeat',
      })}
    ><br />
      <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          paddingBottom: '20px',
          pt: { xs: 14, sm: 20 },
          //pb: { xs: 8, sm: 12 },
        }}
      >
        <Stack spacing={2} useFlexGap sx={{ width: { xs: '100%', sm: '70%' } }}>
          <Typography
            variant="h1"
            sx={{
              display: 'flex',
              flexDirection: { md: 'row' },
              alignSelf: 'center',
              textAlign: 'center',
              fontSize: 'clamp(3.5rem, 10vw, 4rem)',
              pb: '8px'
            }}>
            <b><span style={{color: '#000'}}>Finally, a free </span><span style={{color: '#00448D'}}>business school</span></b>
          </Typography>
          <Typography
            textAlign="center"
            color="text.secondary"
            sx={{ alignSelf: 'center', width: { sm: '100%', md: '80%' } }}
          >
            Alcove School is a business school led by working finance professionals who volunteer to teach students the world of business through real-world experiences. 
          </Typography>
          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            alignSelf="center"
            spacing={1}
            useFlexGap
            sx={{ pt: 2, width: { xs: 'auto', sm: 'auto' } }}>

            {/* <Button variant="contained" sx={{backgroundColor:'#00448D'}}>
              Explore Programs
            </Button> */}

          </Stack>
        </Stack>
      </Container>
    </Box>
  );
}