import * as React from 'react';
import { Link } from "react-router-dom";
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import HomeIcon from '@mui/icons-material/Home';
import { mainListItems, secondaryListItems } from '../listitems';
import '../stratfin.css'

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
    },
  }),
);

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme({
    typography: {
      fontFamily: "Inter"
    },
  });

export default function Forecasting() {
  const [open, setOpen] = React.useState(true);
  const toggleDrawer = () => {
    setOpen(!open);
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar position="absolute" open={open}>
          <Toolbar
            sx={{
              pr: '24px', // keep right padding when drawer closed
              backgroundColor: '#1b213b' 
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: '36px',
                ...(open && { display: 'none' }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              component="h1"
              fontFamily={"Inter"}
              variant="h6"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              <b>Strategic Finance Program</b>
            </Typography>
            <Link to="/" className="link">
            <IconButton color="inherit">
                <HomeIcon />
            </IconButton>
            </Link>
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <Toolbar
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              px: [1],
            }}
          >
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <Divider />
          <List component="nav">
            {mainListItems}
            <Divider sx={{ my: 1 }} />
            {secondaryListItems}
          </List>
        </Drawer>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
        >
          <Toolbar />
          <Container maxWidth="lg" sx={{ mt: 4, mb: 2, ml: 1, mr: 1}}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={8} lg={9}>
                <Paper
                  sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    // height: 240,
                  }}
                >
                    <h1 className='glossary'>Forecasting</h1>
                    <p>Your forecasts will be wrong, but thats ok.</p>
{/*                     
                    <h3 className='glossary'>Job Description</h3>
                    <p className='glossary'>Analysts perform the most tactical work in a StratFin org. Analysts are responsible for building financial models, creating presentation decks, and leading research projects.</p>
                    <p className='glossary'>A sample day in the week of an analyst could look like this:</p>
                    <li className='bullet'>Monday: check business metric dashboards from the weekend and notify manager and director if any metrics look positive/negative that are worth mentioning. Update Google Sheets with latest metrics in preperation for department-wide metrics meeting that is held every Tuesday.</li>
                    <li className='bullet'>Tuesday: update this month's forecast model with latest expectations. As analyst, you are responsible for every update to the team's forecast model and presenting updates to your manager and team's director.</li>
                    <li className='bullet'>Wednesday: get assigned a project where you have to estimate the revenue potential of a new parternship.</li>
                    <br />
                   
                    <h3 className='glossary'>Compensation Expectations</h3>
                    <p className='glossary'>The largest component of an analyst's salary will be base compensation, in the range of $75,000-$125,000 in the United States. In high cost of living areas, the base salary range at a top company could be as high as $140,000-$170,000.</p>
                    <p className='glossary'>Around half of StratFin roles will offer an annual bonus, ranging from 5-15% of base salary. This will largely depend on the company's compensation plans, as it is increasingly common for non-sales roles to not have a bonus component.</p>
                    <p className='glossary'>The last piece of comepnsation is equity, where roles will have the widest pay bands. The average equity compensation component is around $20,000-$30,000 annually. The two factors you should be aware of during negotiations is if the equity id RSUs/options and if the company is public/private. In terms of upside, we have seen equity compensation as high as $60,000 per year.</p>
                    <p className='glossary'>All in, expect total compensation in the $100,000-$150,000 range. For senior analyst or senior associate roles, expect a 15% increase above that.</p>
                    <br />
                   
                    <h3 className='glossary'>What Companies Are Looking For in Analysts</h3>
                    <p className='glossary'>The job market is competitive right now. Most companies are looking for candidates with at least two years of experience, preferably in a previous finance role. It is now common to see lateral moves between companies, where stratfin analysts are moving to a new company with the same title, instead of a title boost. These candidates tend to have the advantage as they have stratin experiene already.</p>
                    <br />

                    <h3 className='glossary'>How to Succeed</h3>
                    <ol>
                        <li><b>Work independently:</b> as a non-entry level hire, you are expected to take on tasks and projects and lead them end-to-end with limited support. You should be able to create models from scratch and put together a deck that outlines key findings. Managers need analysts they can trust.</li><br />
                        <li><b>Solve problems:</b></li><br/>
                        <li><b>Attention to detail</b></li>
                    </ol> */}

                </Paper>
              </Grid>
              {/* <Grid item xs={12} md={4} lg={3}>
                <Paper
                  sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                  }}
                >
                <h2 className='glossary'>Job Descriptions</h2>
                <hr />
                    <img src='/images/uber-logo.jpg' alt='Uber'/>
                <p>Strategic Finance Associate</p>
                </Paper>
              </Grid> */}
            </Grid>
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
}