import * as React from 'react';
import { Link } from "react-router-dom";
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import HomeIcon from '@mui/icons-material/Home';
import { mainListItems, secondaryListItems } from '../listitems';
import '../stratfin.css'


const drawerWidth = 260;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
    },
  }),
);

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme({
    typography: {
      fontFamily: "Inter"
    },
  });

export default function FinancalModeling() {
  const [open, setOpen] = React.useState(true);
  const toggleDrawer = () => {
    setOpen(!open);
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar position="absolute" open={open}>
          <Toolbar
            sx={{
              pr: '24px', // keep right padding when drawer closed
              backgroundColor: '#1b213b' 
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: '36px',
                ...(open && { display: 'none' }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              component="h1"
              fontFamily={"Inter"}
              variant="h6"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              <b>Strategic Finance Program</b>
            </Typography>
            <Link to="/" className="link">
            <IconButton color="inherit">
                <HomeIcon />
            </IconButton>
            </Link>
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <Toolbar
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              px: [1],
            }}
          >
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <Divider />
          <List component="nav">
            {mainListItems}
            <Divider sx={{ my: 1 }} />
            {secondaryListItems}
          </List>
        </Drawer>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
        >
          <Toolbar />

          <Box component="main" sx={{ backgroundColor: 'white', flexGrow: 1, height: '100vh', overflow: 'auto', }}>
          <Container maxWidth="lg" sx={{ mt: 4, mb: 2, ml: 1, mr: 1}}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={2} lg={2}></Grid>
                <Grid item xs={12} md={8} lg={8}>
                  <Paper sx={{p: 2,display: 'flex', flexDirection: 'column', boxShadow: 'none'}}>

                    <h1 className='program-header'><b>Financial Modeling</b></h1>
                    <br/>
                    <p className='program-text'>Every job in Strategic Finance will require some degree of financial modeling. You will have intermediate modeling skills by the end of this program, more than enough to get you through any interview.</p>    
                    <p className='program-text'>Financial modeling can mean a three line model to a three statement model. Here are some model examples:</p>
                    <br/>
                    <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', backgroundColor: '#dee8fa' }}>
                      <h2 className='program-sub-header'>Financial Model Examples</h2>
                      <p className='program-text'></p>
                      <p className='program-text'><b>Amazon Discounted Cash Flow Model</b>: a classic DCF model with revenue segment forecasts, historical quarterly and annual data, and a ten-year free cash flow forecast. <a href='https://docs.google.com/spreadsheets/d/1vhyvINRvo_O4t7yyHqmUmVWAze2hrKjsTfLQSJqwKfY/edit?gid=507931593#gid=507931593' target="_blank" rel="noreferrer" >Open the model</a></p>
                      <p className='program-text'></p>
                      <p className='program-text'><b>Southwest Airlines Unit Economics</b>: a look into the factors that determines Southwest's passenger revenue, from number of planes to revenue. <a href='https://docs.google.com/spreadsheets/d/1gR54-lgGZie0BfJV3YQXflvgG7k3gZXssQychwql3go/edit?gid=0#gid=0' target="_blank" rel="noreferrer" >Open the model</a></p>
                      <p className='program-text'></p>
                    </Paper>

                    <br/>
                    <Divider/>
                    <br/>

                    <h2 className='program-sub-header'>Financial Modeling Guidelines</h2>
                    <br/>
                    <p className='program-text'><b>Clear output.</b> The goal of any financial model is to answer a question [Ex. How much money will we make on this deal? What should we charge for this product? Which option should we choose?] Your model needs to provide that answer front and center.</p>
                    <p className='program-text'><b>Is traceable.</b> Assumptions and formulas should be easily identified and understood. This means to call out any hardcoded numbers and explain where the assumption is from. </p>
                    <p className='program-text'><b>Easily refreshed.</b> Ensure your model can be easily updated and refreshed if it uses data from an external data source. The ideal model will be connected to a data source that update with one click. For manual updates, you should create import tabs where the data source is clearly identified.</p>
                    <p className='program-text'><b>Clean formatting.</b> A model that satifises all of the above but is poorly formatted is still not a good model. The model doesn't need to be pretty, it just can't look clunky.</p>
                    <br/>
                    <br/>
                    <br/>
                </Paper>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Box>
    </Box>
  </ThemeProvider>
  );
}