import * as React from 'react';
import { Link } from "react-router-dom";
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import HomeIcon from '@mui/icons-material/Home';
import { mainListItems, secondaryListItems } from '../listitems';
import '../stratfin.css'

import costcoue1 from '../images/costcoue1.png';
import costcoue2 from '../images/costcoue2.png';
import costcoue3 from '../images/costcoue3.png';
import costcoue4 from '../images/costcoue4.png';
import costcoue5 from '../images/costcoue5.png';
import costcoue6 from '../images/costcoue6.png';
import costcoue8 from '../images/costcoue8.png';
import costcoue9 from '../images/costcoue9.png';
import costcoue10 from '../images/costcoue10.png';

const drawerWidth = 260;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
    },
  }),
);

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme({
    typography: {
      fontFamily: "Inter"
    },
  });

export default function UnitEconomics() {
  const [open, setOpen] = React.useState(true);
  const toggleDrawer = () => {
    setOpen(!open);
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar position="absolute" open={open}>
          <Toolbar
            sx={{
              pr: '24px', // keep right padding when drawer closed
              backgroundColor: '#1b213b' 
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: '36px',
                ...(open && { display: 'none' }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              component="h1"
              fontFamily={"Inter"}
              variant="h6"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              <b>Strategic Finance Program</b>
            </Typography>
            <Link to="/" className="link">
            <IconButton color="inherit">
                <HomeIcon />
            </IconButton>
            </Link>
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <Toolbar
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              px: [1],
            }}
          >
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <Divider />
          <List component="nav">
            {mainListItems}
            <Divider sx={{ my: 1 }} />
            {secondaryListItems}
          </List>
        </Drawer>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
        >
          <Toolbar />

          <Box component="main" sx={{ backgroundColor: 'white', flexGrow: 1, height: '100vh', overflow: 'auto', }}>
          <Container maxWidth="lg" sx={{ mt: 4, mb: 2, ml: 1, mr: 1}}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={2} lg={2}></Grid>
                <Grid item xs={12} md={8} lg={8}>
                  <Paper sx={{p: 2,display: 'flex', flexDirection: 'column', boxShadow: 'none'}}>

                    <h1 className='program-header'><b>Unit Economics</b></h1>
                    <br/>
                    <p className='program-text'>Strategic Finance professionals should know their business inside and out. Unit economics is all about understanding what drives business performance at a granular level. </p>   
                    <p className='program-text'>This section will use Costco as a case study into how to understand unit economics.</p>
                    <br/>
                    <h2 className='program-sub-header'>Costco's Unit Economics</h2>
                    <p className='program-text'>Costco is one of the largest retailers in the world, bringing in $250 billion in annual revenue. Where does the $250 billion come from?</p>
                    <br/><img src={costcoue1} alt='Deep Dive'/><br/>
                    <p className='program-text'>We will go through several drivers of Costco's business that the Costco management tracks to understand their business better.</p>
                    <p className='program-text'>The first driver of Costco's revenue growth we will look at is store count. Costco generates the vast majority of their revenue from in-store, compared to online. So Costco can grow revenue by increasing the number of stores they own. For reference, Costco calls their stores 'warehouses'.</p>
                    <br/><img src={costcoue2} alt='Deep Dive'/><br/>
                    <p className='program-text'>Costco has steadily opened new warehouses each year 2012, with an average of 25 new warehouses per year. This breaks down to 2-3% warehouse count growth each year. </p>
                    <br/><img src={costcoue4} alt='Deep Dive'/><br/>
                    <p className='program-text'>On top of adding new warehouses, Costco is growing how much revenue each warehouse makes, which is now over $280 million per warehouse.</p>
                    <br/><img src={costcoue3} alt='Deep Dive'/><br/>
                    <p className='program-text'>The growth chart below highlights Costco's revenue per warehouse growth. Growth slowed in 2013-2016 but rebounded to 5-7% growth in 2017-2020.</p>
                    <p className='program-text'>Then the pandemic hit, and Costco had back-to-back years of double digit revenue per warehouse growth. </p>
                    <br/><img src={costcoue5} alt='Deep Dive'/><br/>
                    <p className='program-text'>So let's put these charts together, to get a bigger picture of Costco's warehouse unit economics.</p>
                    <br/><img src={costcoue6} alt='Deep Dive'/><br/>
                    <p className='program-text'>This one chart paints a quick picture of Costco's performance over the last decade: Costco's revenue growth is largely tied to revenue per warehouse growth, as warehouse count growth remains stable at 2-3% growth.</p>
                    <br/><br/>
                    <h2 className='program-sub-header'>Revenue Per Warehouse Growth</h2>
                    <p className='program-text'>We're just getting started. We now need to figure out the drivers behind revenue per warehouse growth. Which we know is the main driver of Costco's revenue growth. We're going to introduce two new factors:</p>
                    <ul>
                        <li className='bullet'><b>Membership Count Per Warehouse:</b> Costco is a members-only retailer. You need a paid membership to go into a Costco warehouse, unlike Target or Best Buy. Since Costco is "only" growing new warehouses by 2-3% per year, Costco is aiming to grow the number of members per store.  </li>
                        <li className='bullet'><b>Revenue Per Member:</b> the second factor is how much revenue each member spends at Costco. A Costco member could spend $10 per year at Costco or $10,000 per year. The company wants members to make more visits to their warehouses and also buy more during each visit.</li>
                    </ul>
                    <br/><img src={costcoue9} alt='Costco Unit Economics'/><br/>
                    <p className='program-text'>Ever wonder why your local Costco is always so busy? Costco has increased the number of members per warehouse by 30% in the last decade, growing from 60,000 to over 80,000 members per each warehouse.</p>
                    <br/><img src={costcoue8} alt='Costco Unit Economics'/><br/>
                    <p className='program-text'>Costco members are also spending more money than ever, with the average spend per member now over $3,500 per year. Part of the increase from 2021-2023 which was from inflation, which caused Costco to raise prices across their inventory.</p>
                    <p className='program-text'>So let's look at the combined chart below which outlines the year-over-year drivers of revenue per warehouse.</p>
                    <br/><img src={costcoue10} alt='Costco Unit Economics'/><br/>
                    <p className='program-text'>The chart demonstrates that Costco benefited from a strong increase in revenue per member and a modest increase in members per warehouse to drive revenue per warehouse growth in 2021 and 2022. </p>
                    <br/><br/>
                    <h2 className='program-sub-header'>Recap</h2>
                    <p className='program-text'>This was an example of how you can use unit economics to better understand a business. Revenue growth only tells the high level story of how the business is performing. Unit economics helps us tell the why.</p>
                    <p className='program-text'>Unit economics are not limited to revenue metrics. Expense unit economics are also helpful indicators of how the business is performing. A cost example for Costco would be: what is driving Costco's operating margin higher? Is the company marking up products higher or are the warehouses becoming more efficient?</p>
                    <br/><br/><br/>
                </Paper>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Box>
    </Box>
  </ThemeProvider>
  );
}