import React from 'react';
import AppBarStyle from '../components/AppBarStyle';
import { alpha } from '@mui/material';
import { 
  Container, 
  Box,
  Stack
} from '@mui/material';
import { Helmet } from 'react-helmet';
import './org.css'

const AboutUs = () => {
  return (
    <>
    <Helmet>
      <title>Alcove School - About</title>
    </Helmet>
    <AppBarStyle/>
    <Box id="hero" sx={(theme) => ({ width: '100%',  backgroundImage: theme.palette.mode === 'light' ? 'linear-gradient(180deg, #dbedff, #FFF)' : `linear-gradient(#02294F, ${alpha('#090E10', 0.0)})`, backgroundSize: '100% 40%', backgroundRepeat: 'no-repeat',})}><br />
      <Container sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', pt: { xs: 14, sm: 20, md: 14, lg: 14 }, }}>
        <Stack spacing={2} useFlexGap sx={{ width: { xs: '90%', sm: '70%', lg: '50%' } }}>

        <h1 className='page-title'>About Us</h1>
        
        <p className='page-text'>
          Alcove School's <b>mission</b> is to be the world's best business school. That means creating a curriculum that is grounded in real-world experience, free of cost, and self-paced.
        </p>
        <p className='page-text'>
          We are just getting started to fulfill this mission. We currently have two programs available, both of which were created by business professionals who volunteer their time to create programs based on relevant work experience.
        </p>
        <p className='page-text'>
          We understand the challenges of building an "unaccredited" business school. However, we believe the <b>trend of companies favoring real-world skills over credentials</b> will continue to grow. The days of requiring a two-year MBA to break into a management position get more numbered by the day.
        </p>
        <br/>
        <p className='page-subtitle'>Who We Aren't</p>
        <p className='page-text'>
          We are not an accredited degree mill that takes your money in exchange for a piece of paper. Our only goal is that you land your dream job through taking one or many of our programs.
        </p>
        <p className='page-text'>
          We're not in the business of making money. We don't charge tuition, we don't charge fees, we don't even collect email addresses or phone numbers.
        </p>
        <br/>
        <p className='page-subtitle'>Who We Are</p>
        <p className='page-text'>
          Volunteers who want to provide equal access to a world-class business education.
        </p>
        <p className='page-text'>
          We want to help students, whether college-aged or mid-career professionals, develop business skills that are directly applicable to top business jobs.
        </p>

        <br/>
        <p className='page-subtitle'>Contact Info</p>
        <li><p className='page-text-lines'>Job/career help: <i>careers@alcoveschool.com</i></p></li>
        <li><p className='page-text-lines'>Need help with one of our programs? <i>learn@alcoveschool.com</i></p></li>
        <li><p className='page-text-lines'>Want to help build Alcove School? <i>volunteer@alcoveschool.com</i></p></li>
        <li><p className='page-text-lines'>General questions/press: <i>hello@alcoveschool.com</i></p></li>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        
        </Stack>
    </Container> 
    </Box>
    </>
  );
};

export default AboutUs;
