import * as React from 'react';
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import Drawer from '@mui/material/Drawer';
import MenuIcon from '@mui/icons-material/Menu';
import './component.css';


function AppAppBar({ mode, toggleColorMode }) {
  const [open, setOpen] = React.useState(false);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  return (
    <div>
      <AppBar position="fixed" sx={{ boxShadow: 0, bgcolor: 'transparent', backgroundImage: 'none', mt: 2.5, }} >
        <Container maxWidth="lg">
          <Toolbar
            variant="regular"
            sx={(theme) => ({
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              flexShrink: 0,
              borderRadius: '22px',
              bgcolor: 'rgba(255, 255, 255, 0.4)',
              backdropFilter: 'blur(24px)',
              border: '1px solid',
              borderColor: 'divider',
              boxShadow: `0 0 2px #dbdbdb, 1px 1.5px 2px -1px r#dbdbdb, 4px 4px 12px -2.5px #dbdbdb`
            })}
          >
            <Box
              sx={{
                flexGrow: 1,
                display: 'flex',
                alignItems: 'center',
                px: 0,
              }}
            >
            <Typography>
                <Link to="/" className="header-logo">Alcove School of Business</Link>
            </Typography>
               <Box sx={{ display: { xs: 'none', md: 'flex' }, ml: '12px' }}>
                <MenuItem sx={{ py: '6px', px: '16px', ml: '15px', }}>
                  <Typography variant="body2" color="text.primary">
                    <Link to="/programs" className="header-item-link">Programs</Link>
                  </Typography>
                </MenuItem>
                <MenuItem sx={{ py: '6px', px: '16px', }}>
                  <Typography variant="body2" color="text.primary">
                    <Link to="/financials" className="header-item-link">Financials</Link>
                  </Typography>
                </MenuItem>
                <MenuItem sx={{ py: '6px', px: '16px' }}>
                  <Typography variant="body2" color="text.primary">
                  <Link to="/blog" className="header-item-link">Blog</Link>
                  </Typography>
                </MenuItem>
                <MenuItem sx={{ py: '6px', px: '16px' }}>
                  <Typography variant="body2" color="text.primary">
                    <Link to="/about" className="header-item-link">About</Link>
                  </Typography>
                </MenuItem>
              </Box>
            </Box>
            <Box
              sx={{
                display: { xs: 'none', md: 'flex' },
                gap: 0.5,
                alignItems: 'center',
              }}>
                {/* <Typography variant="body2">
                    <Link to="/about" className="certification">Certification</Link>
                </Typography> */}
            </Box>


            <Box sx={{ display: { sm: '', md: 'none' } }}>
              <Button variant="text" aria-label="menu" onClick={toggleDrawer(true)} sx={{ minWidth: '60px', p: '4px', color: 'black' }}>
                <MenuIcon/>
              </Button>
              <Drawer anchor="right" open={open} onClose={toggleDrawer(false)}>
                <Box sx={{ minWidth: '60dvw', p: 2, backgroundColor: 'background.paper', flexGrow: 1,}}>
                  <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'end',   flexGrow: 1,}}>
                  </Box>
                  <MenuItem>
                    <Link to="/programs" className="header-item-link">Programs</Link>
                  </MenuItem>
                  <MenuItem>
                    <Link to="/financials" className="header-item-link">Financials</Link>
                  </MenuItem>
                  <MenuItem>
                    <Link to="/blog" className="header-item-link">Blog</Link>
                  </MenuItem>
                  <MenuItem>
                    <Link to="/about" className="header-item-link">About</Link>
                  </MenuItem>
                  <Divider />
                  <MenuItem>
                    <Link to="/" className="header-item-link"><b>Home</b></Link>
                  </MenuItem>
                </Box>
              </Drawer>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </div>
  );
}

AppAppBar.propTypes = {
  mode: PropTypes.oneOf(['dark', 'light']).isRequired,
  toggleColorMode: PropTypes.func.isRequired,
};

export default AppAppBar;