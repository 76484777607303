import * as React from 'react';
import AppBarStyle from '../components/AppBarStyle';
import { alpha } from '@mui/material';
import { 
    Container, 
    Typography, 
    Grid, 
    Card, 
    CardContent, 
    CardMedia,
    Box
  } from '@mui/material';
import { Link } from "react-router-dom";
import Button from '@mui/material/Button';
import { Helmet } from 'react-helmet';
//import HomeIcon1 from '../images/homeicon1.png';
//import HomeIcon2 from '../images/homeicon2.png';
//import HomeIcon3 from '../images/homeicon3.png';
import sqlprogram2 from '../images/sqlprogram2.png';
import stratfin1 from '../images/stratfin1.png';
import finstate from '../images/finstate.png';

  
const Programs = () => {
  return (
    <>
      <Helmet>
        <title>Alcove School - Programs</title>
      </Helmet>
      <AppBarStyle/>
      <Box id="hero" sx={(theme) => ({ width: '100%',  backgroundImage: theme.palette.mode === 'light' ? 'linear-gradient(180deg, #dbedff, #FFF)' : `linear-gradient(#02294F, ${alpha('#090E10', 0.0)})`, backgroundSize: '100% 70%', backgroundRepeat: 'no-repeat',})}><br />
      <Container sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', pt: { xs: 14, sm: 20, md: 14, lg: 14 }, }}>
      <h1 className='page-header'><b>Our Programs</b></h1>
        <Grid container spacing={4} my={4}>
            <Grid item xs={12} sm={12} md={4} lg={4} >
              <Card>
                <CardMedia
                  component="img"
                  height="170"
                  image={stratfin1}
                />
                <CardContent>
                  <Typography gutterBottom variant="h6" component="div">
                    Strategic Finance Program
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Explore what we consider the most interesting career path in the business world.
                  </Typography>
                  <br/>
                  {/* backgroundColor: '#1b213b' */}
                  <Button size="small" sx={{backgroundColor: '#1b213b', color: 'white'}}>
                    <Link to="/stratfin/welcome" target='_blank'><p className='button-text'>&nbsp;Start Learning&nbsp;</p></Link>
                </Button>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <Card>
                <CardMedia
                  component="img"
                  height="170"
                  image={sqlprogram2}
                />
                <CardContent>
                  <Typography gutterBottom variant="h6" component="div">
                    SQL for Business
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Learn all the SQL you need to know to level up your business skills.
                  </Typography>
                  <br/>
                  <Button size="small" sx={{backgroundColor: '#1b213b', color: 'white'}}>
                    <Link to="/sql" target='_blank'><p className='button-text'>&nbsp;Start Learning&nbsp;</p></Link>
                </Button>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
            <Card>
                <CardMedia
                  component="img"
                  height="170"
                  image={finstate}
                />
                <CardContent>
                  <Typography gutterBottom variant="h6" component="div">
                    Financial Statements 101
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Mastering the three financial statements are critical to any business career.
                  </Typography>
                  <br/>
                  <Button size="small" sx={{backgroundColor: '#1b213b', color: 'white'}}>
                    <Link><p className='button-text'>&nbsp;&nbsp;Coming Soon&nbsp;&nbsp;</p></Link>
                </Button>

                </CardContent>
              </Card>
            </Grid>
        </Grid>
   </Container>
   </Box></>
  );
};

export default Programs;
