import * as React from 'react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { mainListItems, secondaryListItems } from '../listitems';
import '../sql.css'

const drawerWidth = 260;

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
    },
  }),
);

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme({
    typography: {
      fontFamily: "Inter"
    },
  });

export default function Welcome() {
  const [open, setOpen] = React.useState(true);
  const toggleDrawer = () => {
    setOpen(!open);
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <Drawer variant="permanent" open={open}>
          <Toolbar sx={{ display: 'flex', alignItems: 'center', backgroundColor: '#1b213b', justifyContent: 'flex-end', px: [1],}}>
            <h3 className='program-title'>SQL For Business</h3>
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon  style={{ color: 'white' }}/>
            </IconButton>
          </Toolbar>
          <Divider />
          <List component="nav">
            {mainListItems}
            <Divider sx={{ my: 1 }} />
            {secondaryListItems}
          </List>
        </Drawer>
        <Box component="main" sx={{ backgroundColor: 'white', flexGrow: 1, height: '100vh', overflow: 'auto', }}>
          <Container maxWidth="lg" sx={{ mt: 4, mb: 2, ml: 1, mr: 1}}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={2} lg={2}></Grid>
              <Grid item xs={12} md={8} lg={8}>
                <Paper sx={{p: 2,display: 'flex', flexDirection: 'column', boxShadow: 'none'}}>

                  <h1 className='program-header'>Getting Data</h1><br/>
                    <p className='program-text'>We need SQL to get data from databases and these are two commmands are the only two that appear in every single SQL query, <b>SELECT</b> and <b>FROM</b>.</p>
                    <p className='program-text'><b>SELECT</b> will be the first word in nearly every query you write. It tells the database that you want to retrieve data from it. Remember, as business anaylsts, we only care about getting data from the database.</p>
                    <p className='program-text'><b>FROM</b> tells the database which data table you want to access. A database can contain thousands of data tables so we must clarify which table we want to query.</p>
                    <br/><Divider/><br/>
                    <p className='program-text'>Our first example in this section involves pulling data from the simplest data table ever. The data table is called <i>continents</i> and has seven rows of data - one for each of the seven continents. It also only has two columns of data. The first being the name of the continent itself and the second column being the continents population.</p>
                    <p className='program-text'>Here is what the data table looks like:</p>
                    <br/>

                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 350,}} aria-label="simple table">
                            <TableHead>
                            <TableRow sx={{backgroundColor: '#1b213b'}}>
                                <TableCell sx={{color: 'white', lineHeight: '1.2'}}><b>name</b></TableCell>
                                <TableCell sx={{color: 'white', lineHeight: '1.2'}}><b>population</b></TableCell>
                            </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow><TableCell component="th" scope="row">Europe</TableCell><TableCell component="th" scope="row">740,433,713</TableCell></TableRow>
                                <TableRow><TableCell component="th" scope="row">Africa</TableCell><TableCell component="th" scope="row">1,460,481,772	</TableCell></TableRow>
                                <TableRow><TableCell component="th" scope="row">Asia</TableCell><TableCell component="th" scope="row">4,753,079,726</TableCell></TableRow>
                                <TableRow><TableCell component="th" scope="row">North America</TableCell><TableCell component="th" scope="row">604,182,517</TableCell></TableRow>
                                <TableRow><TableCell component="th" scope="row">Australia</TableCell><TableCell component="th" scope="row">46,004,866</TableCell></TableRow>
                                <TableRow><TableCell component="th" scope="row">Antarctica</TableCell><TableCell component="th" scope="row">0</TableCell></TableRow>
                                <TableRow><TableCell component="th" scope="row">South America</TableCell><TableCell component="th" scope="row">439,719,009</TableCell></TableRow>
                            </TableBody>
                        </Table>
                        </TableContainer>

                        <br/>
                        <p className='program-text'>This data table exists electronically in a data center, so we need to use SQL to tell the data center that we want to access it. To view the entire data table, we write the following SQL code:</p>
                        

                  <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', backgroundColor: '#f1f1f1', marginTop: '20px'}}>
                      <p className='program-text-single'>SELECT  &nbsp; * &nbsp; FROM &nbsp;continents</p>
                  </Paper>

                  <br/>
                        <p className='program-text'>This is how it works in theory. Let's actually run our first real SQL query.</p>
              
                    
                    
                    <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', backgroundColor: '#dee8fa', marginTop: '20px'}}>
                      <h3 className='program-practice-header'>Practice</h3>
                      <p className='program-text'>We are going to use a website called DB Fiddle that allows us to create and query data tables. Open this <b><a target='_blank' rel='noreferrer' href='https://www.db-fiddle.com/f/pVh16g1hrWc42HzeJYHpaw/8'>link</a></b> in a new tab to get started. </p>
                      <p className='program-text'>The page may look overwhelming but all we are going to use is 'Query SQL' section on the right to write our SQL and the 'Run' button at the top of the page to run our queries.</p>
                      <p className='program-text'>Go ahead and copy and paste, or type yourself, this code: <b>SELECT * FROM continents</b> in the section titled 'Query SQL'.</p>
                      <p className='program-text'>And then click the 'Run' button the top of the page.</p>
                      <p className='program-text'>Congrats, you just ran your first SQL query!</p>
                   </Paper>
                  </Paper>
              </Grid>
              <Grid item xs={12} md={2} lg={2}></Grid>
              <Grid item xs={12} md={2} lg={2}></Grid>
            </Grid>
          </Container>    
        </Box>
      </Box>
    </ThemeProvider>
  );
}