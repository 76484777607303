import * as React from 'react';
import { Link } from "react-router-dom";
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import HomeIcon from '@mui/icons-material/Home';
import { mainListItems, secondaryListItems } from '../listitems';
import '../stratfin.css';

import chipotlewbr from '../pdfs/chipotlewbr.pdf'

const drawerWidth = 260;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
    },
  }),
);

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme({
    typography: {
      fontFamily: "Inter"
    },
  });

export default function BusinessReviews() {
  const [open, setOpen] = React.useState(true);
  const toggleDrawer = () => {
    setOpen(!open);
  };

  return (

    // Theme Template.
    <ThemeProvider theme={defaultTheme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar position="absolute" open={open}>
          <Toolbar sx={{ pr: '24px', backgroundColor: '#1b213b'}} >
            <IconButton edge="start" color="inherit" aria-label="open drawer" onClick={toggleDrawer} sx={{ marginRight: '36px', ...(open && { display: 'none' }),}}>
              <MenuIcon />
            </IconButton>
            <Typography component="h1" fontFamily={"Inter"} variant="h6" color="inherit" noWrap sx={{ flexGrow: 1 }}><b>Strategic Finance Program</b></Typography>
            <Link to="/" className="link">
            <IconButton color="inherit">
                <HomeIcon />
            </IconButton>
            </Link>
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <Toolbar sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', px: [1],}}>
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <Divider />
          <List component="nav">
            {mainListItems}
            <Divider sx={{ my: 1 }} />
            {secondaryListItems}
          </List>
        </Drawer>

        <Box component="main" sx={{ flexGrow: 1, height: '100vh', overflow: 'auto',}}>
          <Toolbar />
                
          <Container maxWidth="lg" sx={{ mt: 4, mb: 2, ml: 1, mr: 1}}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={2} lg={2}></Grid>
              <Grid item xs={12} md={8} lg={8}>
                <Paper sx={{p: 2,display: 'flex', flexDirection: 'column', boxShadow: 'none'}}>


    
    
    <h1 className='program-header'>Business Reviews</h1><br/>
    <p className='program-text'>We'll learn what a business review is by demonstrating one. We created a fake Weekly Business Review (WBR) for Chipotle containing metrics their management team likely tracks on a weekly basis.</p>
    <p className='program-practice'>Open the <a href={chipotlewbr} target="_blank" rel="noreferrer">Chipotle Weekly Business Review</a> in a new tab and keep it open for reference throughout this lesson.</p>

    <h2 className='program-sub-header'>A Successful Business Review</h2>
        <p className='program-text'>What makes a successful business review? We put together this list, in order, to ensure no one has to sit through any more pointless meetings.</p>
        <ol className='program-text'>
            <li><b>Engaging:</b> without engagement, achieving the rest of this list is impossible. A disengaged audience makes for terrible meetings where nothing is accompished and hurts morale. The first goal of a business review should be to keep it engaging. More on this later.</li><br/>
            <li><b>Input metrics:</b> this is taken straight from Amazon. You should be focusing on metrics that you can control. Amazon doesn't discuss how much revenue they made each week, they talk about metrics that matter to customers: shipping times, selection, in-stock rate.</li><br/>
            <li><b>Skip business as usual:</b> do not waste time discussing metrics that don't need to be discussed. If a metric is "business as usual" - just say so and move on to the next metric.</li><br/>
            <li><b>Keep the meeting moving:</b> the meeting owner should ensure the group does not get held up on one topic for too long. It is important that all metrics that need to be covered gets covered. For metrics that require further discussion, it should be discussed after the meeting or at the very end of the meeting if time allows.</li><br/>
            <li><b>Adjust metrics list:</b> well-run metric meetings will have a core set of metrics that don't change, metrics that get added as needed, and also metrics that get removed from the meeting if they are not valuable to the group.</li><br/>
        </ol>

    <br/>
    <h2 className='program-sub-header'>Checklist</h2>
        <p className='glossary'>Whether you are setting up a WBR or already have one, review this checklist to ensure your meeting is being run effectively.</p>
        <ul className='program-text'>
            <li>Does the meeting result in several action items? More action items = good. As action items imply that the metrics are leading to further questions/investigations and that meeting attendees are engaged.</li><br/>
            <li>What percent of the meeting attendee list actually attend? A meeting with conistently low attendance probably means the meeting is not worth people's time.</li><br/>
            <li>Is there time to cover every metric? This is a test to ensure your meeting isn't being hijacked.</li><br/>
            <li>Have you asked for feedback from meeting attendees on how to improve the meeting?</li><br/>

       </ul>

    </Paper>
</Grid>
<Grid item xs={12} md={2} lg={2}></Grid>
<Grid item xs={12} md={2} lg={2}></Grid>

            </Grid>
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
}