import * as React from 'react';
import { Link } from "react-router-dom";
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import HomeIcon from '@mui/icons-material/Home';
import { mainListItems, secondaryListItems } from '../listitems';
import '../stratfin.css'

import cmgir1 from '../images/cmgir1.png';
import cmgir2 from '../images/cmgir2.png';

const drawerWidth = 260;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
    },
  }),
);

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme({
    typography: {
      fontFamily: "Inter"
    },
  });

export default function Research() {
  const [open, setOpen] = React.useState(true);
  const toggleDrawer = () => {
    setOpen(!open);
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar position="absolute" open={open}>
          <Toolbar
            sx={{
              pr: '24px', // keep right padding when drawer closed
              backgroundColor: '#1b213b' 
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: '36px',
                ...(open && { display: 'none' }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              component="h1"
              fontFamily={"Inter"}
              variant="h6"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              <b>Strategic Finance Program</b>
            </Typography>
            <Link to="/" className="link">
            <IconButton color="inherit">
                <HomeIcon />
            </IconButton>
            </Link>
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <Toolbar
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              px: [1],
            }}
          >
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <Divider />
          <List component="nav">
            {mainListItems}
            <Divider sx={{ my: 1 }} />
            {secondaryListItems}
          </List>
        </Drawer>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
        >
          <Toolbar />

          <Box component="main" sx={{ backgroundColor: 'white', flexGrow: 1, height: '100vh', overflow: 'auto', }}>
          <Container maxWidth="lg" sx={{ mt: 4, mb: 2, ml: 1, mr: 1}}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={2} lg={2}></Grid>
                <Grid item xs={12} md={8} lg={8}>
                  <Paper sx={{p: 2,display: 'flex', flexDirection: 'column', boxShadow: 'none'}}>

                    <h1 className='program-header'><b>Research</b></h1>
                    <br/>
                    <p className='program-text'>Doing great research is another skill that is not required in strategic finance, but is a skill that can take you to the next level. <b>Research is fun</b> if you love business and are a genuinely curious.</p>   
                    <br/>
                    <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', backgroundColor: '#dee8fa' }}>
                    <h2 className='program-sub-header'>Example 1: Uber Eats Partnership With Chipotle</h2>
                    <p className='program-text'>Just days into the pandemic, Uber Eats and Chipotle announced a national partnership that would allow Chipotle to be ordered diretly from the Uber Eats app for the first time.</p>
                    </Paper><br/>
                    <p className='program-text'>How did the Uber Eats strategic finance determine how much to charge Chipotle? Uber Eats charges restaurants a fee on each order, generally 20-30% of the order total. But Chipotle is a national brand and would demand a lower fee.</p>
                    <p className='program-text'>To determine how much Chipotle could afford to pay, you could open up Chipotle's financial statements as they are a public company.</p>
                    <img src={cmgir1} alt='Chipotle'/><br/>
                    <p className='program-text'>Chipotle's income statement reveals their unit economics. They break out their operating costs into two buckets: restaurant operating expenses and company overhead.</p>
                    <p className='program-text'>Focusing on restaurant operating expenses, we can calculate that Chipotle made a 20% margin on each order in 2019 on average. Food costs made up 33% of revenue, labor was 26% of revenue, rent and utilities were 7% of revenue, with miscellaneous costs making up 14% of revenues.</p>
                    <p className='program-text'>So now Uber knows that Uber's profit margin after deducting food and labor costs is 41%. So during fee negotiations with Chipotle, Uber would know how much Chipotle could technically afford to pay and still break even on each order.</p>
                    <p className='program-text'>Another important factor is Chipotle's average order value. If Uber charges Chipotle 20% in fees per order, how revenue does that equal per order? If you simply Google "Chipotle average order value", you would find this chart from a Citigroup investment bank research report.</p><br/>
                    <img src={cmgir2} alt='Chipotle'/><br/>
                    <p className='program-text'>Chipotle has one of the higher average order values of the major fast food restaurants, another piece of valuable information for modeling the deal.</p>
                    <p className='program-text'>By doing this research, Uber could be better prepared for negotations with Chipotle. This research is a competitive advantage and a strong strategic finance team would not only how to find this data, but then incorporate it into their financial models to influence key business decisions.</p>
                    <br/>
                    <Divider/>
                    <br/>

                    <h2 className='program-sub-header'>Best Research Sources</h2>
                    <br/>
                    <p className='program-text'><b>Public filings.</b> Public companies are required to report their financial statements every quarter. These filings contain validated data directly from the company that makes for the perfect information source. Looking at company filings should be the first step for any research involing public companies.</p>
                    <p className='program-text'><b>News.</b> The second best source is from business journalism. They will cover the major headlines and important macro trends.</p>
                    <p className='program-text'><b>Research reports.</b> This research will likely cost a hefty subscription price but could contain invaluable data into your company's market share or important industry trends.</p>
                    <p className='program-text'><b>Guerilla research</b> How did Costco's former CEO know how much Sam's Club was charging for the same product that Costco sells? He would walk into his local Sam's Club. Guerilla research is doing valuable research that isn't scalable. Just visiting a competitor's website to check thier pricing structure is another example.</p>
                    <p className='program-text'><b>LinkedIn.</b> An underrated source for finding important competitior information. You can use LinkedIn to estimate how big a competitor's sales team is growing, who a company hired to fill an important role, or which roles a company is hiring for. You can find all of this by searching LinkedIn and is a great source of unstructured data that can provide clues into how companies are operating. </p>
                    <p className='program-text'><b>Glassdoor.</b> Company reviews from employees can be telling. Some employees will let it slip if a product is underperforming, if a department's mangement team is driving employees away, or even revealing unethical conduct. All of this is important research you could use with the caveat that it is unverified information.  </p>
                </Paper>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Box>
    </Box>
  </ThemeProvider>
  );
}