import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import AutoFixHighRoundedIcon from '@mui/icons-material/AutoFixHighRounded';
import ConstructionRoundedIcon from '@mui/icons-material/ConstructionRounded';
import QueryStatsRoundedIcon from '@mui/icons-material/QueryStatsRounded';
import SettingsSuggestRoundedIcon from '@mui/icons-material/SettingsSuggestRounded';
import SupportAgentRoundedIcon from '@mui/icons-material/SupportAgentRounded';
import ThumbUpAltRoundedIcon from '@mui/icons-material/ThumbUpAltRounded';

const items = [
  {
    icon: <SettingsSuggestRoundedIcon />,
    title: '100% Free',
    description:
      'Building the worlds best business education starts with free access for everyone. ',
  },
  {
    icon: <ConstructionRoundedIcon />,
    title: 'Reality, Not Theory',
    description:
      'Our programs are grounded in actual business problems, not academic theories.',
  },
  {
    icon: <ThumbUpAltRoundedIcon />,
    title: 'Teacher-First Educators',
    description:
      'Our educators are driven by helping students learn, not by research or tenure.',
  },
  {
    icon: <AutoFixHighRoundedIcon />,
    title: 'Practical Career Guidance',
    description:
      'Built by working business professionals who make hiring decisions.',
  },
  {
    icon: <SupportAgentRoundedIcon />,
    title: 'Global Classroom',
    description:
      'Meet and network with fellow students from around the world.',
  },
  {
    icon: <QueryStatsRoundedIcon />,
    title: 'Real World Practice',
    description:
      'Enjoy practice problems involving real companies and real-world problems.',
  },
];

export default function Highlights() {
  return (
    <Box
      id="highlights"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        color: 'white',
        bgcolor: '#1b213b',
      }}
    >
      <Container
        sx={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: { xs: 3, sm: 6 },
        }}
      >
        <Box
          sx={{
            width: { sm: '100%', md: '60%'},
            textAlign: { sm: 'left', md: 'center' },
          }}
        >
          <Typography component="h2" variant="h4">
            <b>Not Your Average Business School</b>
          </Typography><br/>
          <Typography variant="body1" sx={{ color: 'grey.400' }}>
            Alcove School was built from the ground up to provide the best student experience possible. 
          </Typography>
        </Box>
        <Grid container spacing={2.5}>
          {items.map((item, index) => (
            <Grid item xs={12} sm={6} md={4} lg={4} key={index}>
              <Stack
                direction="column"
                color="inherit"
                component={Card}
                spacing={1}
                useFlexGap
                sx={{
                  p: 3,
                  border: '2px solid',
                  borderColor: '#fbaf40',
                  background: 'transparent',
                  backgroundColor: '#1b213b',
                }}
              >
                <Box sx={{ opacity: '50%' }}>{item.icon}</Box>
                <div>
                  <Typography fontWeight="medium" gutterBottom>
                    {item.title}
                  </Typography>
                  <Typography variant="body2" sx={{ color: 'grey.400' }}>
                    {item.description}
                  </Typography>
                </div>
              </Stack>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
}