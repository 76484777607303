import * as React from 'react';
import { Link } from "react-router-dom";
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import HomeIcon from '@mui/icons-material/Home';
import { mainListItems, secondaryListItems } from '../listitems';
import '../stratfin.css';

import chipotlewbr from '../pdfs/chipotlewbr.pdf'

const drawerWidth = 260;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
    },
  }),
);

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme({
    typography: {
      fontFamily: "Inter"
    },
  });

export default function Metrics() {
  const [open, setOpen] = React.useState(true);
  const toggleDrawer = () => {
    setOpen(!open);
  };

  return (

    // Theme Template.
    <ThemeProvider theme={defaultTheme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar position="absolute" open={open}>
          <Toolbar sx={{ pr: '24px', backgroundColor: '#1b213b'}} >
            <IconButton edge="start" color="inherit" aria-label="open drawer" onClick={toggleDrawer} sx={{ marginRight: '36px', ...(open && { display: 'none' }),}}>
              <MenuIcon />
            </IconButton>
            <Typography component="h1" fontFamily={"Inter"} variant="h6" color="inherit" noWrap sx={{ flexGrow: 1 }}><b>Strategic Finance Program</b></Typography>
            <Link to="/" className="link">
            <IconButton color="inherit">
                <HomeIcon />
            </IconButton>
            </Link>
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <Toolbar sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', px: [1],}}>
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <Divider />
          <List component="nav">
            {mainListItems}
            <Divider sx={{ my: 1 }} />
            {secondaryListItems}
          </List>
        </Drawer>

        <Box component="main" sx={{ flexGrow: 1, height: '100vh', overflow: 'auto',}}>
          <Toolbar />
                
          <Container maxWidth="lg" sx={{ mt: 4, mb: 2, ml: 1, mr: 1}}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={2} lg={2}></Grid>
              <Grid item xs={12} md={8} lg={8}>
                <Paper sx={{p: 2,display: 'flex', flexDirection: 'column', boxShadow: 'none'}}>


    
    
    <h1 className='program-header'>Metrics</h1>
        <p className='program-text'>Metrics is the work of compiling, analyzing, and presenting business data to leadership teams, usually in a format of emails and meetings on a regular cadence.</p>
        <p className='program-text'>StratFin teams will generally be responsible for metrics in smaller organizations while larger organizations have dedicated data teams that are responsible for metrics reporting.</p>

    <br/>
    <h2 className='program-sub-header'>Business Reviews</h2>
        <p className='program-text'>Most StratFin teams will be involved with business reviews, regularly scheduled meetings (usually weekly or monthly) dedicated to reviewing key business data points and financials.</p>
        <p className='program-practice'>Check out what a WBR looks like: <a href={chipotlewbr} target="_blank" rel="noreferrer">Chipotle Weekly Business Review</a>.</p>
        <p className='program-text'>Weekly Business Reviews (WBRs) are used in many companies. WBRs are weekly 30-60 minute meetings where the leadership team (CEO, CFO, COO, etc) reviews data points for their most important metrics in order to guage the direction of the business.</p>
        <p className='program-text'>The leadership team will review anywhere from 5 to 200 metrics (depending on the size of the company). Some of the key metrics a company will track are: revenue, number of total users, number of sign-ups, churn, retention, revenue per store, and anything that can be measured.</p>

    <h3 className='program-sub-header-unbold'>StratFin Responsibilities</h3>
    <p className='program-text'>StratFin's involvement can vary from just sitting in on the meeting to preparing and running the meeting entirely.</p>
    <p className='program-text'>One organization we worked in had the strategic finance team run the entire process. On Mondays, the StratFin team would pull business data from the database and import the data into a template that contained charts and graphs of how the business performed last week. The StratFin team would then analyze the data to identify any trends that might appear in the data. If a trend was worth highlighting, a smaller anaylsis (called a deep-dive) could be performed. </p>
    <p className='program-text'>The actual WBR meeting was held on Tuesday, where the five person StratFin team joined the eight members of the leadership team and a few VPs to discuss last week's buisness performance.</p>

    <br/>
    <h2 className='program-sub-header'>Dashboards</h2>
    <p className='program-text'>Dashboards represent another area of responsbility for StratFin. Dashboards are collections of charts and graphs that are build within a specific dashboarding tool - like Looker, PowerBI, or Tableau.</p>
    <p className='program-text'>Dashboards are perfect for data that allows employees to constantly track key metrics on a consistent basis, saving time from co-workers bugging you to ask questions they probably could find themselves.</p>

    <h3 className='program-sub-header-unbold'>StratFin Responsibilities</h3>
    <p className='program-text'>A strong StratFin team will create their own dashboards instead of outsourcing the work to the Data or BizOps teams. </p>


    </Paper>
</Grid>
<Grid item xs={12} md={2} lg={2}></Grid>
<Grid item xs={12} md={2} lg={2}></Grid>

            </Grid>
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
}