import * as React from 'react';
import { alpha } from '@mui/material';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import AppBarStyle from '../components/AppBarStyle';
import { Helmet } from 'react-helmet';
// import Divider from '@mui/material/Divider';
// import Paper from '@mui/material/Paper';
import './blog.css'

// Images
import Disney1 from './images/Disney1.png';
import Disney2 from './images/Disney2.png';
import Disney3 from './images/Disney3.png';
import Disney4 from './images/Disney4.png';
import Disney5 from './images/Disney5.png';
import Disney6 from './images/Disney6.png';
import Disney7 from './images/Disney7.png';
import Disney8 from './images/Disney8.png';
import Disney9 from './images/Disney9.png';
import Disney10 from './images/Disney10.png';



export default function Netflix() {
  return (
    <>    
    <Helmet><title>Disney's Streaming Profitability Concerns</title></Helmet>
    <AppBarStyle/>
    <Box id="hero" sx={(theme) => ({ width: '100%',  backgroundImage: theme.palette.mode === 'light' ? 'linear-gradient(180deg, #99d98c, #FFF)' : `linear-gradient(#02294F, ${alpha('#090E10', 0.0)})`, backgroundSize: '100% 200px', backgroundRepeat: 'no-repeat',})}><br />
      <Container sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', paddingBottom: '20px', pt: { xs: 14, sm: 20 }, }}>
        <Stack spacing={2} useFlexGap sx={{ width: { xs: '100%', sm: '70%', lg: '60%' } }}>
          <Typography variant="h1" sx={{ display: 'flex', flexDirection: { md: 'row' }, alignSelf: 'center', textAlign: 'center', fontSize: 'clamp(3.0rem, 8vw, 3.9rem)', pb: '8px', maxWidth: '92%'}}>
            <b>Disney's Streaming Profitability Concerns</b>
          </Typography>
          <br/><br/>
          <p className='blog-text'>Investors were taken aback when Disney released its Q4 earnings earlier this month as the company reported it lost $1.5 billion from its streaming service business last quarter.</p>
          <br/><img src={Disney1} alt='Disney'/><br/>
          <p className='blog-text'>Disney’s streaming business, which includes Disney+, ESPN+ and Hulu, was considered Disney’s “savior” in recent years as the streaming business was expected to generate strong profits to replace the company’s legacy television network business. Investors are starting to change their tone as the streaming business continues to lose money and does not have a clear path to become profitable in the near-term.</p>
          <p className='blog-text'>We will analyze the business model for Disney’s streaming service business in this write-up.</p>
          <img src={Disney2} alt='Disney'/>
          <h3 className='blog-sub-header'>Subscriber Growth</h3>
          <p className='blog-text'>The numbers don’t lie. Disney has grown its streaming business faster than expected. Disney+ launched in a handful of countries in Q4 2019 and combined with ESPN+ and Hulu has now surpassed Netflix in terms of total subscribers.</p>
          <img src={Disney3} alt='Disney'/><br/>
          <p className='blog-text'>Disney has accumulated new subscribers through its strategy of setting low monthly prices and bundling its three streaming services, Disney+, ESPN+ and Hulu, together.</p>
          <p className='blog-text'>Purchasing Disney+, ESPN+, and Hulu separately would cost a consumer about $25 per month but Disney is currently selling a bundle of all three for $13.99 per month. For reporting purposes, when a subscriber purchases all three Disney streaming services through a bundle, Disney recognizes that purchase as three separate subscriptions.</p>
          <p className='blog-text'>In addition to reporting the number of subscribers per each service, Disney also breaks out how much revenue they make per month for each service:</p>
          <img src={Disney4} alt='Disney'/>
          <p className='blog-text'>Disney+ starts at $7.99 per month in the United States, so why is Disney only making $4.24 per month for it? Part of the reason can be attributed to “bundling” which we mentioned earlier. Because the bundle is about half the cost of purchasing each subscription separately, Disney must report the revenue from each service proportionally.</p>
          <p className='blog-text'>Another cause of the low monthly revenue is international subscribers.</p>
          <p className='blog-text'>71% of Disney+ subscribers are outside the US and Canada, in which customers pay a lower average subscription price per month. 52% of these international subscribers are for Disney+ Hotstar’s service, which is the leading streaming service in India and other Southeast Asian countries. Due to spending power and foreign currency exchange rates, Disney actually makes less than $1 per month on average for a Hotstar subscriber.</p>
          <p className='blog-text'>We highlight these points to reflect that Disney’s ability to surpass subscriber count expectations was partially attributed to low prices and bundling. So while Disney has more paying subscribers than Netflix, they make less per subscriber on average.</p>
          <img src={Disney5} alt='Disney'/>
          <p className='blog-text'>This spread in average cost has widened as Netflix has raised prices while Disney has expanded internationally, especially into India.</p>
          <p className='blog-text'>This leads to our final chart of this section which outlines how much each company makes in revenue from streaming:</p>
          <img src={Disney6} alt='Disney'/><br/>
          <p className='blog-text'>Our readers know that revenue is only half of the equation to calculate a company’s profit. We know that Disney has doubled streaming revenue in the last two years but is still losing more and more money. So what does this imply?</p>
          <h3 className='blog-sub-header'>Ballooning Expenses</h3>
          <p className='blog-text'>The streaming service business model is simple: a company produces or licenses tv shows and movies and attempts to sell this content to consumers for more than it acquired the content for.</p>
          <p className='blog-text'>While the business model is simple, the business couldn’t be more difficult. Disney has had to face the following questions in the past three years:</p>
            <ul>
                <li className='bullet'>How much is a specific tv show or movie worth to the users?</li>
                <li className='bullet'>Should Disney+ just be licensed Disney content or should we produce originals?</li>
                <li className='bullet'>How do we allocate content between Disney and Hulu?</li>
                <li className='bullet'>How much should we sell the streaming bundle for? Should we sell it at all?</li>
                <li className='bullet'>Should we continue to sell some Disney-owned content to Netflix and other streaming competitors or disallow it and put all of the content on our services?</li>
            </ul>
          <p className='blog-text'>So far Disney has ended most content licenses to its competitors, although some were very profitable for Disney, and decided to exclusively host this content on their streaming services.</p>
          <p className='blog-text'>Disney has also continued to produce original shows like The Mandalorian and WandaVision for its streaming services. These costs are known as “programming costs” and have increase steadily the past three years:</p>
          <br/><img src={Disney7} alt='Disney'/>  
          <p className='blog-text'>On top of content costs, Disney also spends over $1.5 billion per quarter on advertising and overhead for its streaming business.</p>
          <img src={Disney8} alt='Disney'/><br/>
          <p className='blog-text'>So as Disney streaming revenue has flatlined, expenses have continue to grow each quarter. This is the main factor for Disney’s worsening operating profit the past year and a half:</p>
          <img src={Disney9} alt='Disney'/><br/>
          <h3 className='blog-sub-header'>Looking To The Future</h3>
          <p className='blog-text'>Disney essentially bet the house on its streaming service when it launched Disney+ in 2019. The streaming service forced the company to back out of highly profitability licensing deals with other entertainment companies and is taking content resources away from its traditional linear networks business.</p>
          <p className='blog-text'>In his return to Disney this month, Bob Iger has already said he will slow hiring and focus on making the streaming service business profitable. One of the first pieces of business will be overseeing the launch of the Disney+ ad-supported model.</p>
          <p className='blog-text'>Starting next week, Disney will offer a Disney+ subscription tier that includes ads, something that Hulu already has but Disney+ hasn’t until now. The company is hoping this will drive more cost-conscious customers to sign-up for Disney+ as this option will be cheaper than the ad-free option.</p>
          <p className='blog-text'>The new ad-supported tier will also further diversify Disney’s revenue away from subscription fees which make up 80% of its streaming revenues. If you were curious, “pay-per-view” revenue includes revenue from streaming UFC on ESPN+ as well as “Premier Access” events on Disney+ that allows subscribers to purchase new movie releases in the Disney+ app.</p>
          <br/><img src={Disney10} alt='Disney'/><br/>
          <p className='blog-text'>If Disney follows the Netflix playbook of steadily raising prices and building out an ad-supported model while creating more content that people love, there is no doubt Disney should be able to make the streaming business a cash cow.</p>
          <p className='blog-text'>This strategy will be easier said than done and no one knows that more than Bob Iger.</p>
          <br/>
          <br/>
          <br/>
        </Stack>
      </Container>
    </Box>
    </>
  );
}