import * as React from 'react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { mainListItems, secondaryListItems } from '../listitems';
import '../sql.css'

const drawerWidth = 260;

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
    },
  }),
);

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme({
    typography: {
      fontFamily: "Inter"
    },
  });

export default function Fitlering() {
  const [open, setOpen] = React.useState(true);
  const toggleDrawer = () => {
    setOpen(!open);
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <Drawer variant="permanent" open={open}>
          <Toolbar sx={{ display: 'flex', alignItems: 'center', backgroundColor: '#1b213b', justifyContent: 'flex-end', px: [1],}}>
            <h3 className='program-title'>SQL For Business</h3>
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon  style={{ color: 'white' }}/>
            </IconButton>
          </Toolbar>
          <Divider />
          <List component="nav">
            {mainListItems}
            <Divider sx={{ my: 1 }} />
            {secondaryListItems}
          </List>
        </Drawer>
        <Box component="main" sx={{ backgroundColor: 'white', flexGrow: 1, height: '100vh', overflow: 'auto', }}>
          <Container maxWidth="lg" sx={{ mt: 4, mb: 2, ml: 1, mr: 1}}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={2} lg={2}></Grid>
              <Grid item xs={12} md={8} lg={8}>
                <Paper sx={{p: 2,display: 'flex', flexDirection: 'column', boxShadow: 'none'}}>

                  <h1 className='program-header'>Filtering Data</h1><br/>
                    <p className='program-text'>There <b>WHERE</b> statement is one of the most used commands in SQL. We use SQL to access large amounts of data and we almost always will need to filter it.</p>                    
                   
                    <h2 className='program-sub-header-unbold'>The Dataset</h2>
                    <p className='program-text'>Let's do some examples using YouTube channel data. This sample data table is called <i>youtube_subscriber_data</i> and has 3.5 billion rows - one row for each YouTube channel that has ever been created.</p>
                    <p className='program-text'>The data table has five columns:</p>
                    <ul>
                        <li className='bullet'><b>user_name:</b> the YouTube channels name</li>
                        <li className='bullet'><b>created_date:</b> the date the account was created</li>
                        <li className='bullet'><b>country:</b> the country the account was crated in</li>
                        <li className='bullet'><b>category:</b> which group YouTube categorizes the account (music, sports, etc.)</li>
                        <li className='bullet'><b>subscriber_count</b> how many current subscribers the account has</li>
                    </ul>

                    <p className='program-text'>Here is a sample of ten rows of the table. Remember the full data table has over 3 billion rows.</p>

                    <br/>
                      <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 350,}} aria-label="simple table">
                            <TableHead>
                            <TableRow sx={{backgroundColor: '#1b213b'}}>
                                <TableCell sx={{color: 'white', lineHeight: '1.2'}}>user_name</TableCell>
                                <TableCell sx={{color: 'white', lineHeight: '1.2'}} align="right">created_date</TableCell>
                                <TableCell sx={{color: 'white', lineHeight: '1.2'}} align="right">country</TableCell>
                                <TableCell sx={{color: 'white', lineHeight: '1.2'}} align="right">category</TableCell>
                                <TableCell sx={{color: 'white', lineHeight: '1.2'}} align="right">subscriber_count</TableCell>
                            </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                <TableCell component="th" scope="row">Mr. Beast</TableCell>
                                <TableCell align="right">02/19/2012</TableCell>
                                <TableCell align="right">United States</TableCell>
                                <TableCell align="right">Entertainment</TableCell>
                                <TableCell align="right">302,930,103</TableCell>
                                </TableRow>
                                <TableRow sx={{ backgroundColor: '#f4f4f4' }}>
                                <TableCell component="th" scope="row">Blackpink</TableCell>
                                <TableCell align="right">06/28/2016</TableCell>
                                <TableCell align="right">South Korea</TableCell>
                                <TableCell align="right">Music</TableCell>
                                <TableCell align="right">94,201,780</TableCell>
                                </TableRow>
                                <TableRow>
                                <TableCell component="th" scope="row">Justin Bieber</TableCell>
                                <TableCell align="right">01/15/2007</TableCell>
                                <TableCell align="right">Canada</TableCell>
                                <TableCell align="right">Music</TableCell>
                                <TableCell align="right">73,211,949</TableCell>
                                </TableRow>
                                <TableRow sx={{ backgroundColor: '#f4f4f4' }}>
                                <TableCell component="th" scope="row">PewDiePie</TableCell>
                                <TableCell align="right">04/29/2010</TableCell>
                                <TableCell align="right">Sweden</TableCell>
                                <TableCell align="right">Entertainment</TableCell>
                                <TableCell align="right">111,784,392</TableCell>
                                </TableRow>
                                <TableRow>
                                <TableCell component="th" scope="row">T-Series</TableCell>
                                <TableCell align="right">03/13/2006</TableCell>
                                <TableCell align="right">India</TableCell>
                                <TableCell align="right">Music</TableCell>
                                <TableCell align="right">269,848,012</TableCell>
                                </TableRow>
                                <TableRow sx={{ backgroundColor: '#f4f4f4' }}>
                                <TableCell component="th" scope="row">WWE</TableCell>
                                <TableCell align="right">05/10/2007</TableCell>
                                <TableCell align="right">United States</TableCell>
                                <TableCell align="right">Sports</TableCell>
                                <TableCell align="right">103,199,857</TableCell>
                                </TableRow>
                                <TableRow>
                                <TableCell component="th" scope="row">Cocomelon</TableCell>
                                <TableCell align="right">09/01/2006</TableCell>
                                <TableCell align="right">United States</TableCell>
                                <TableCell align="right">Education</TableCell>
                                <TableCell align="right">178,842,421</TableCell>
                                </TableRow>
                                <TableRow sx={{ backgroundColor: '#f4f4f4' }}>
                                <TableCell component="th" scope="row">Canal KondZilla</TableCell>
                                <TableCell align="right">03/21/2012</TableCell>
                                <TableCell align="right">Brazil</TableCell>
                                <TableCell align="right">Music</TableCell>
                                <TableCell align="right">67,129,733</TableCell>
                                </TableRow>
                                <TableRow>
                                <TableCell component="th" scope="row">Pinkfong</TableCell>
                                <TableCell align="right">12/13/2011</TableCell>
                                <TableCell align="right">South Korea</TableCell>
                                <TableCell align="right">Education</TableCell>
                                <TableCell align="right">76,848,529</TableCell>
                                </TableRow>
                                <TableRow sx={{ backgroundColor: '#f4f4f4' }}>
                                <TableCell component="th" scope="row">Ed Sheeran</TableCell>
                                <TableCell align="right">08/08/2006</TableCell>
                                <TableCell align="right">United Kingdom</TableCell>
                                <TableCell align="right">Music</TableCell>
                                <TableCell align="right">54,733,176</TableCell>
                                </TableRow>




                            </TableBody>
                        </Table>
                        </TableContainer>

                  <br/>
                  
                  {/* First Example Query */}
                  <h2 className='program-sub-header-unbold'>Example Query #1</h2><br/>
                    <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', backgroundColor: '#f1f1f1'}}>
                        <p className='program-text-single'>SELECT</p>
                        <p className='program-text-single'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;*</p>
                        <p className='program-text-single'>FROM</p>
                        <p className='program-text-single'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;youtube_subscriber_data</p>
                        <p className='program-text-single'>WHERE</p>
                        <p className='program-text-single'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;country = 'United States'</p>
                    </Paper>
                    <br/>
                    <p className='program-text'>This query display all rows for YouTube accounts based in the United States:</p>
                    <br/>
                      <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 350,}} aria-label="simple table">
                            <TableHead>
                            <TableRow sx={{backgroundColor: '#1b213b'}}>
                                <TableCell sx={{color: 'white', lineHeight: '1.2'}}>user_name</TableCell>
                                <TableCell sx={{color: 'white', lineHeight: '1.2'}} align="right">created_date</TableCell>
                                <TableCell sx={{color: 'white', lineHeight: '1.2'}} align="right">country</TableCell>
                                <TableCell sx={{color: 'white', lineHeight: '1.2'}} align="right">category</TableCell>
                                <TableCell sx={{color: 'white', lineHeight: '1.2'}} align="right">subscriber_count</TableCell>
                            </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                <TableCell component="th" scope="row">Mr. Beast</TableCell>
                                <TableCell align="right">02/19/2012</TableCell>
                                <TableCell align="right">United States</TableCell>
                                <TableCell align="right">Entertainment</TableCell>
                                <TableCell align="right">302,930,103</TableCell>
                                </TableRow>
                                <TableRow sx={{ backgroundColor: '#f4f4f4' }}>
                                <TableCell component="th" scope="row">WWE</TableCell>
                                <TableCell align="right">05/10/2007</TableCell>
                                <TableCell align="right">United States</TableCell>
                                <TableCell align="right">Sports</TableCell>
                                <TableCell align="right">103,199,857</TableCell>
                                </TableRow>
                                <TableRow>
                                <TableCell component="th" scope="row">Cocomelon</TableCell>
                                <TableCell align="right">09/01/2006</TableCell>
                                <TableCell align="right">United States</TableCell>
                                <TableCell align="right">Education</TableCell>
                                <TableCell align="right">178,842,421</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                        </TableContainer>

                  <br/>
                    <p className='program-text'>For syntax, we write the column name we want to filter followed by an equals sign. If we are filtering a column that contains words, we must put the words in quotation marks so SQL knows it is looking for words and not numbers.</p>
                    <br/><Divider/><br/>

                    {/* Second Example Query */}
                    <h2 className='program-sub-header-unbold'>Example Query #2</h2><br/>
                    <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', backgroundColor: '#f1f1f1'}}>
                        <p className='program-text-single'>SELECT</p>
                        <p className='program-text-single'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;*</p>
                        <p className='program-text-single'>FROM</p>
                        <p className='program-text-single'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;youtube_subscriber_data</p>
                        <p className='program-text-single'>WHERE</p>
                        <p className='program-text-single'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;subscriber_count &gt; 100000000</p>
                    </Paper>
                    <br/>
                    <p className='program-text'>Here is an example of filtering a query based on numbers. In this case we want to show YouTube accounts with more than 100 million subscribers. There are five:</p>
                    <br/>
                      <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 350,}} aria-label="simple table">
                            <TableHead>
                            <TableRow sx={{backgroundColor: '#1b213b'}}>
                                <TableCell sx={{color: 'white', lineHeight: '1.2'}}>user_name</TableCell>
                                <TableCell sx={{color: 'white', lineHeight: '1.2'}} align="right">created_date</TableCell>
                                <TableCell sx={{color: 'white', lineHeight: '1.2'}} align="right">country</TableCell>
                                <TableCell sx={{color: 'white', lineHeight: '1.2'}} align="right">category</TableCell>
                                <TableCell sx={{color: 'white', lineHeight: '1.2'}} align="right">subscriber_count</TableCell>
                            </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                <TableCell component="th" scope="row">Mr. Beast</TableCell>
                                <TableCell align="right">02/19/2012</TableCell>
                                <TableCell align="right">United States</TableCell>
                                <TableCell align="right">Entertainment</TableCell>
                                <TableCell align="right">302,930,103</TableCell>
                                </TableRow>
                                <TableRow sx={{ backgroundColor: '#f4f4f4' }}>
                                <TableCell component="th" scope="row">PewDiePie</TableCell>
                                <TableCell align="right">04/29/2010</TableCell>
                                <TableCell align="right">Sweden</TableCell>
                                <TableCell align="right">Entertainment</TableCell>
                                <TableCell align="right">111,784,392</TableCell>
                                </TableRow>
                                <TableRow>
                                <TableCell component="th" scope="row">T-Series</TableCell>
                                <TableCell align="right">03/13/2006</TableCell>
                                <TableCell align="right">India</TableCell>
                                <TableCell align="right">Music</TableCell>
                                <TableCell align="right">269,848,012</TableCell>
                                </TableRow>
                                <TableRow sx={{ backgroundColor: '#f4f4f4' }}>
                                <TableCell component="th" scope="row">WWE</TableCell>
                                <TableCell align="right">05/10/2007</TableCell>
                                <TableCell align="right">United States</TableCell>
                                <TableCell align="right">Sports</TableCell>
                                <TableCell align="right">103,199,857</TableCell>
                                </TableRow>
                                <TableRow>
                                <TableCell component="th" scope="row">Cocomelon</TableCell>
                                <TableCell align="right">09/01/2006</TableCell>
                                <TableCell align="right">United States</TableCell>
                                <TableCell align="right">Education</TableCell>
                                <TableCell align="right">178,842,421</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                        </TableContainer>

                  <br/>
                    <p className='program-text'>Other common number filters include: less than, equal to, greater than or equal to, and less than or equal to.</p>
                    <br/><Divider/><br/>

                    {/* Example Query 3*/}
                    <h2 className='program-sub-header-unbold'>Example Query #3</h2><br/>
                    <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', backgroundColor: '#f1f1f1'}}>
                        <p className='program-text-single'>SELECT</p>
                        <p className='program-text-single'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;*</p>
                        <p className='program-text-single'>FROM</p>
                        <p className='program-text-single'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;youtube_subscriber_data</p>
                        <p className='program-text-single'>WHERE</p>
                        <p className='program-text-single'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;country != 'United States</p>
                        <p className='program-text-single'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;AND category = 'Music'</p>
                    </Paper>
                    <br/>
                    <p className='program-text'>Here is a curveball - two filters. The first filter uses <b>!=</b> which means 'is not equal to'. In this case we are filtering for all accounts NOT based in the United States.</p>
                    <p className='program-text'>Second, we add a second filter, which we use <b>AND</b> to designate second filter. This filter selects only music accounts. So both filters combined leaves us with music accounts not based in the United States: </p>
                    <br/>
                      <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 350,}} aria-label="simple table">
                            <TableHead>
                            <TableRow sx={{backgroundColor: '#1b213b'}}>
                                <TableCell sx={{color: 'white', lineHeight: '1.2'}}>user_name</TableCell>
                                <TableCell sx={{color: 'white', lineHeight: '1.2'}} align="right">created_date</TableCell>
                                <TableCell sx={{color: 'white', lineHeight: '1.2'}} align="right">country</TableCell>
                                <TableCell sx={{color: 'white', lineHeight: '1.2'}} align="right">category</TableCell>
                                <TableCell sx={{color: 'white', lineHeight: '1.2'}} align="right">subscriber_count</TableCell>
                            </TableRow>
                            </TableHead>
                            <TableBody>
                            <TableRow >
                                <TableCell component="th" scope="row">Blackpink</TableCell>
                                <TableCell align="right">06/28/2016</TableCell>
                                <TableCell align="right">South Korea</TableCell>
                                <TableCell align="right">Music</TableCell>
                                <TableCell align="right">94,201,780</TableCell>
                                </TableRow>
                                <TableRow sx={{ backgroundColor: '#f4f4f4' }}>
                                <TableCell component="th" scope="row" >T-Series</TableCell>
                                <TableCell align="right">03/13/2006</TableCell>
                                <TableCell align="right">India</TableCell>
                                <TableCell align="right">Music</TableCell>
                                <TableCell align="right">269,848,012</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                        </TableContainer>

                  <br/>
                    <p className='program-text'>There is no limit to the number of filters you can apply to a query. In most cases, you will need to apply several queries to narrow down your dataset.</p>
                    <br/><Divider/><br/>

                    {/* Example Query 4*/}
                    <h2 className='program-sub-header-unbold'>Example Query #4</h2><br/>
                    <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', backgroundColor: '#f1f1f1'}}>
                        <p className='program-text-single'>SELECT</p>
                        <p className='program-text-single'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;user_name,</p>
                        <p className='program-text-single'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;created_date,</p>
                        <p className='program-text-single'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;category,</p>
                        <p className='program-text-single'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;subsciber_count</p>
                        <p className='program-text-single'>FROM</p>
                        <p className='program-text-single'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;youtube_subscriber_data</p>
                        <p className='program-text-single'>WHERE</p>
                        <p className='program-text-single'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;category IN ('Entertainment', 'Sports')</p>
                        <p className='program-text-single'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;AND created_date BETWEEN '2010-01-01' AND '2015-01-01'</p>
                        <p className='program-text-single'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;AND subscriber_count {">="} 200000000</p>
                        <p className='program-text-single'>ORDER BY</p>
                        <p className='program-text-single'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;subscriber_count DESC</p>
                    </Paper>
                    <br/>
                    <p className='program-text'>Here is a curveball - two filters. The first filter uses <b>!=</b> which means 'is not equal to'. In this case we are filtering for all accounts NOT based in the United States.</p>
                    <p className='program-text'>Second, we add a second filter, which we use <b>AND</b> to designate second filter. This filter selects only music accounts. So both filters combined leaves us with music accounts not based in the United States: </p>
                    <br/>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 350,}} aria-label="simple table">
                            <TableHead>
                            <TableRow sx={{backgroundColor: '#1b213b'}}>
                                <TableCell sx={{color: 'white', lineHeight: '1.2'}}>user_name</TableCell>
                                <TableCell sx={{color: 'white', lineHeight: '1.2'}} align="right">created_date</TableCell>
                                <TableCell sx={{color: 'white', lineHeight: '1.2'}} align="right">category</TableCell>
                                <TableCell sx={{color: 'white', lineHeight: '1.2'}} align="right">subscriber_count</TableCell>
                            </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                <TableCell component="th" scope="row">Mr. Beast</TableCell>
                                <TableCell align="right">02/19/2012</TableCell>
                                <TableCell align="right">Entertainment</TableCell>
                                <TableCell align="right">302,930,103</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                        </TableContainer>
                  <br/>
                    <p className='program-text'>There is no limit to the number of filters you can apply to a query. In most cases, you will need to apply several queries to narrow down your dataset.</p>
                    <br/><Divider/><br/>




                </Paper>
              </Grid>
              <Grid item xs={12} md={2} lg={2}></Grid>
              <Grid item xs={12} md={2} lg={2}></Grid>
            </Grid>
          </Container>    
        </Box>
      </Box>
    </ThemeProvider>
  );
}