import * as React from 'react';
import { Link } from "react-router-dom";
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import HomeIcon from '@mui/icons-material/Home';
import { mainListItems, secondaryListItems } from '../listitems';
import '../stratfin.css';

import GoogleIncomeStatement from '../pdfs/GoogleFY23IncomeStatement.pdf';
import GoogleIS1 from '../images/googleis1.png';
import GoogleIS2 from '../images/googleis2.png';
import GoogleIS3 from '../images/googleis3.png';
import GoogleIS4 from '../images/googleis4.png';

const drawerWidth = 260;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
    },
  }),
);

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme({
    typography: {
      fontFamily: "Inter"
    },
  });

export default function GoogleIS() {
  const [open, setOpen] = React.useState(true);
  const toggleDrawer = () => {
    setOpen(!open);
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar position="absolute" open={open}>
          <Toolbar
            sx={{
              pr: '24px', // keep right padding when drawer closed
              backgroundColor: '#1b213b' 
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: '36px',
                ...(open && { display: 'none' }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              component="h1"
              fontFamily={"Inter"}
              variant="h6"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              <b>Strategic Finance Program</b>
            </Typography>
            <Link to="/" className="link">
            <IconButton color="inherit">
                <HomeIcon />
            </IconButton>
            </Link>
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <Toolbar
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              px: [1],
            }}
          >
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <Divider />
          <List component="nav">
            {mainListItems}
            <Divider sx={{ my: 1 }} />
            {secondaryListItems}
          </List>
        </Drawer>
        <Box
          component="main"
          sx={{
            
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
        >
          <Toolbar />
          <Container maxWidth="lg" sx={{ mt: 4, mb: 2, ml: 1, mr: 1}}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={2} lg={2}></Grid>
              <Grid item xs={12} md={8} lg={8}>
                <Paper sx={{p: 2,display: 'flex', flexDirection: 'column', boxShadow: 'none'}}>

<h1 className='glossary-header'>Google's Income Statement</h1>
    <p>We'll start with one the easiest income statements to read, Google's. A screenshot of their income statement is below, but you can also open their&nbsp;
    <a href={GoogleIncomeStatement} target="_blank" rel="noreferrer">income statement</a>
    &nbsp;in a new tab so you can revert back to it throughout this lesson as needed.</p>

<img src={GoogleIS1} alt='Google Income Statement'/><br/>
<p className='glossary'>We are going to go through the income statement line-by-line to ensure we cover everything, starting with the text at the very top.</p>    
<p className='glossary'>At the top, we have <b>Alphabet Inc.</b> If you follow business news, you'll know that Google created a parent company called Alphabet, where Google is still the primary business but other smaller businesses roll up under Alphabet. So this income statement is Alphabet's, where Google makes up the vast majority of their business.</p>          
<p className='glossary'>Next is <b>Consolidated Statements of Income</b> which is just a fancy word for income statement. All your looking at is Google's income statement.</p>
<p className='glossary'>The next line seems basic, but its important. It classifies that all numbers on the page are in millions of dollars, expect for the 'per share' amounts, which are in the last two lines of the income statement. So if you see the number $100,000 on this income statement, it actually represents $100,000,000,000, aka $100 billion.</p>
<p className='glossary'>That was a quick intro of the boring parts of the income statement, let's dive into the numbers.</p>

<br/>
<h2 className='glossary'>Time Periods</h2>
<p className='glossary'>Income statements could measure a company's performance over any time period: an hour, a day, a month, a quarter, or a year. However, public companies are only required by law to share their income statements with investors quarterly and annually. Outside of public reporting laws, most companies will create monthly income statements, but will keep them internally and won't be shared with investors.</p>
<p className='glossary'>The income statement for Google is an annual income statement, as we can tell by the following wording.</p>    

<img src={GoogleIS2} alt='Google Income Statement'/><br/>
<p className='glossary'><b>Year</b> tells us that this is annual income statement and <b>December 31</b> tells us which date the income statement goes through. So the 2021 column in this income statement represents Google's performance from January 1, 2021 through December 31, 2021. This may seem intuitive, but we'll see later that some companies like Apple have fiscal years that don't end in December.</p>
<p className='glossary'>Most income statements that you see will have two or three years worth of data in it, organized in columns. This is because companies and investors compare results year-over-year, so historical data is important to compare current performance with.</p>
<p className='glossary'>And to bring the point home, here is what a quarterly income statement would look like. The income statement looks exactly the same, you just have to be aware of what months and time frame the income statement is referring to.</p><br/>
<img src={GoogleIS3} alt='Google Income Statement'/><br/>


<br/>
<h2 className='glossary'>Revenue</h2>
<p className='glossary'>The first line item on every income statement will be revenue. Revenue, sometimes also labeled 'Sales', is the amount a company makes from selling its goods or services. In Google's case, they made $307.4 billion in revenue in 2023, up 9% from $282.8 billion in 2022.</p>
<p className='glossary'>Google owns YouTube, so every YouTube ad you watch is more money in Google's pocket that will show up in the income statement. </p>

<br/>
<h2 className='glossary'>Expenses</h2>
<p className='glossary'>The second major part of an income statement are the expenses. This category includes everything that a company spends money on: the cost to make their product, employee salaries, office rent, and insurance costs.</p>
<p className='glossary'>We classify expenses into two categories, cost of goods sold (red) and operating expenses (orange).</p>

<br/><img src={GoogleIS4} alt='Google Income Statement'/><br/>
<p className='glossary'>Cost of goods sold, also referred to as 'cost of sales' or 'cost of revenues', are the company's costs to build their product. Back to the Apple example, that iPhone may have $500 worth of parts that Apple paid for and assume it costs $100 in employee costs to assemble each phone. The total costs to make each iPhone would be $600, meaning that Apple's cost of goods sold would be $600.</p>
<p className='glossary'>Of course Apple has other expenses that don't involve just making products. These operating expenses include employee salaries for Apple employees who work in marketing or office costs. A company can classify their operating expenses into different buckets.</p>
<p className='glossary'>For Google, they break out operating expenses into three groups:</p>
<ul>
    <li>Research and development: this expense category includes costs like software engineer salaries and some data center costs.</li>
    <li>Sales and marketing: includes advertising costs and also salaries for sales and marketing employees.</li>
    <li>General and administrative: primarily includes salaries for company overhead like finance, human resources, and legal. Office rent and utilities would also be included in this group.</li>
</ul>
</Paper>
</Grid>
<Grid item xs={12} md={2} lg={2}></Grid>


<Grid item xs={12} md={2} lg={2}></Grid>
<Grid item xs={12} md={8} lg={8}>
    <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', backgroundColor: '#dee8fa' }}>
        <h2 className='glossary'>Practice</h2>
        <a href='https://docs.google.com/spreadsheets/d/1WaLUa57uEKStVRF7deDIVpB9wI8ttQG0MSCaDaAgImQ/edit?gid=0#gid=0' target="_blank" rel="noreferrer" >Link</a>
    </Paper>
</Grid>
<Grid item xs={12} md={2} lg={2}></Grid>

            </Grid>
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
}