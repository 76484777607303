import * as React from 'react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { mainListItems, secondaryListItems } from '../listitems';
import '../sql.css'

const drawerWidth = 260;

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
    },
  }),
);

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme({
    typography: {
      fontFamily: "Inter"
    },
  });

export default function Fitlering() {
  const [open, setOpen] = React.useState(true);
  const toggleDrawer = () => {
    setOpen(!open);
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <Drawer variant="permanent" open={open}>
          <Toolbar sx={{ display: 'flex', alignItems: 'center', backgroundColor: '#1b213b', justifyContent: 'flex-end', px: [1],}}>
            <h3 className='program-title'>SQL For Business</h3>
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon  style={{ color: 'white' }}/>
            </IconButton>
          </Toolbar>
          <Divider />
          <List component="nav">
            {mainListItems}
            <Divider sx={{ my: 1 }} />
            {secondaryListItems}
          </List>
        </Drawer>
        <Box component="main" sx={{ backgroundColor: 'white', flexGrow: 1, height: '100vh', overflow: 'auto', }}>
          <Container maxWidth="lg" sx={{ mt: 4, mb: 2, ml: 1, mr: 1}}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={2} lg={2}></Grid>
              <Grid item xs={12} md={8} lg={8}>
                <Paper sx={{p: 2,display: 'flex', flexDirection: 'column', boxShadow: 'none'}}>

                  <h1 className='program-header'>Calculations</h1><br/>
                    <p className='program-text'>You will often need to calculate numbers in SQL. Calculation commands are important because it allows you to perform routine math on a massive dataset much faster than you could in Excel since Excel can only handle so much data. </p>                    
                    <p className='program-text'>The primary commands we use are <b>SUM</b>, <b>AVG</b>, <b>COUNT</b>, <b>MAX</b>, and <b>MIN</b>. They are very similar to the Excel functions and perform the same way. To use these commands, we simply put what we want to calculate between parentheses after the command, like this.</p>
                    

                    <br/>
                    <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', backgroundColor: '#f1f1f1'}}>
                        <p className='program-text-single'>SELECT</p>
                        <p className='program-text-single'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;SUM(revenue)</p>
                        <p className='program-text-single'>FROM</p>
                        <p className='program-text-single'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;financials_table</p>
                    </Paper>
                    <br/>

                    <p className='program-text'>Or like this.</p>

                    <br/>
                    <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', backgroundColor: '#f1f1f1'}}>
                        <p className='program-text-single'>SELECT</p>
                        <p className='program-text-single'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;MAX(player_height)</p>
                        <p className='program-text-single'>FROM</p>
                        <p className='program-text-single'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;nba_players_list</p>
                    </Paper>
                    <br/>

                    <h2 className='program-sub-header-unbold'>The Dataset</h2>
      
                    <p className='program-text'>The examples in this section are about McDonald's. The data table, <i>transactions</i>, lists every purchase made at every McDonald's globally from July 1, 2024 to July 7, 2024.</p>
                    <p className='program-text'>Each row represents one purchase (transaction) and McDonald's does over 70 million transactions per day. So with 7 days of data, this data table would have almost 500 million rows, far more than you could ever analyze in Excel or Google Sheets.</p>
                    <p className='program-text'> Let's take a look at the six columns in this data table:</p>
   
                    <ul>
                        <li className='bullet'><b>transaction_id:</b> a randomly generated identifier for each purchase</li>
                        <li className='bullet'><b>date:</b> the date of the purchase</li>
                        <li className='bullet'><b>restaurant_id:</b> McDonald's indentifier for every restaurant they operate</li>
                        <li className='bullet'><b>country:</b> which country the McDonald's is located in</li>
                        <li className='bullet'><b>item_count:</b> number of menu items purchased</li>
                        <li className='bullet'><b>sales:</b> the dollar value of the purchase in US Dollars</li>
                    </ul>
                    
                    <p className='program-text'>And here is just a sample of 10 rows, out of the 500 million rows in the data table:</p>
                    <br/>
                        <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 350,}} aria-label="simple table">
                            <TableHead>
                            <TableRow sx={{backgroundColor: '#1b213b'}}>
                                <TableCell sx={{color: 'white', lineHeight: '1.2'}}><b>transaction_id</b></TableCell>
                                <TableCell sx={{color: 'white', lineHeight: '1.2'}}><b>date</b></TableCell>
                                <TableCell sx={{color: 'white', lineHeight: '1.2'}}><b>restaurant_id</b></TableCell>
                                <TableCell sx={{color: 'white', lineHeight: '1.2'}}><b>country</b></TableCell>
                                <TableCell sx={{color: 'white', lineHeight: '1.2'}}><b>item_count</b></TableCell>
                                <TableCell sx={{color: 'white', lineHeight: '1.2'}}><b>sales</b></TableCell>
                            </TableRow>
                            </TableHead>
                            <TableBody>
                            <TableRow><TableCell component="th" scope="row">393r32ht904</TableCell><TableCell component="th" scope="row">07/02/2024</TableCell><TableCell component="th" scope="row">2934</TableCell><TableCell component="th" scope="row">United Kingdom</TableCell><TableCell component="th" scope="row">2</TableCell><TableCell component="th" scope="row">$11.43</TableCell></TableRow>
                            <TableRow><TableCell component="th" scope="row">1wg484030n3</TableCell><TableCell component="th" scope="row">07/02/2024</TableCell><TableCell component="th" scope="row">3029</TableCell><TableCell component="th" scope="row">Philippines</TableCell><TableCell component="th" scope="row">1</TableCell><TableCell component="th" scope="row">$2.99</TableCell></TableRow>
                            <TableRow><TableCell component="th" scope="row">4949w28r92n</TableCell><TableCell component="th" scope="row">07/04/2024</TableCell><TableCell component="th" scope="row">12953</TableCell><TableCell component="th" scope="row">Brazil</TableCell><TableCell component="th" scope="row">3</TableCell><TableCell component="th" scope="row">$13.32</TableCell></TableRow>
                            <TableRow><TableCell component="th" scope="row">a1029349ws1</TableCell><TableCell component="th" scope="row">07/03/2024</TableCell><TableCell component="th" scope="row">15492</TableCell><TableCell component="th" scope="row">Canada</TableCell><TableCell component="th" scope="row">6</TableCell><TableCell component="th" scope="row">$25.28</TableCell></TableRow>
                            <TableRow><TableCell component="th" scope="row">9b484e1s6g7</TableCell><TableCell component="th" scope="row">07/04/2024</TableCell><TableCell component="th" scope="row">5839</TableCell><TableCell component="th" scope="row">France</TableCell><TableCell component="th" scope="row">1</TableCell><TableCell component="th" scope="row">$6.99</TableCell></TableRow>
                            <TableRow><TableCell component="th" scope="row">3c28d29353j</TableCell><TableCell component="th" scope="row">07/02/2024</TableCell><TableCell component="th" scope="row">9383</TableCell><TableCell component="th" scope="row">China</TableCell><TableCell component="th" scope="row">2</TableCell><TableCell component="th" scope="row">$4.65</TableCell></TableRow>
                            <TableRow><TableCell component="th" scope="row">6z392847b5q</TableCell><TableCell component="th" scope="row">07/06/2024</TableCell><TableCell component="th" scope="row">820</TableCell><TableCell component="th" scope="row">United States</TableCell><TableCell component="th" scope="row">4</TableCell><TableCell component="th" scope="row">$15.03</TableCell></TableRow>
                            <TableRow><TableCell component="th" scope="row">8r938v30203</TableCell><TableCell component="th" scope="row">07/01/2024</TableCell><TableCell component="th" scope="row">2039</TableCell><TableCell component="th" scope="row">Canada</TableCell><TableCell component="th" scope="row">3</TableCell><TableCell component="th" scope="row">$9.76</TableCell></TableRow>
                            <TableRow><TableCell component="th" scope="row">492w82001h9</TableCell><TableCell component="th" scope="row">07/07/2024</TableCell><TableCell component="th" scope="row">4221</TableCell><TableCell component="th" scope="row">Canada</TableCell><TableCell component="th" scope="row">1</TableCell><TableCell component="th" scope="row">$3.99</TableCell></TableRow>
                            <TableRow><TableCell component="th" scope="row">7k920144989</TableCell><TableCell component="th" scope="row">07/05/2024</TableCell><TableCell component="th" scope="row">1882</TableCell><TableCell component="th" scope="row">Turkey</TableCell><TableCell component="th" scope="row">3</TableCell><TableCell component="th" scope="row">$10.83</TableCell></TableRow>
                            </TableBody>
                        </Table>
                        </TableContainer>
                    <br/>


                    <h2 className='program-sub-header-unbold'>Queries</h2>
                    <br/>
                    <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', backgroundColor: '#f1f1f1'}}>
                        <p className='program-text-single'>SELECT</p>
                        <p className='program-text-single'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;SUM(sales)</p>
                        <p className='program-text-single'>FROM</p>
                        <p className='program-text-single'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;transactions</p>
                    </Paper>
                    <br/>
                    <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', backgroundColor: '#f1f1f1'}}>
                        <p className='program-text-single'><b>Output</b></p>
                        <p className='program-text-single'>5,576,204,639</p>

                    </Paper>
                    <br/>




                    
                    </Paper>
              </Grid>
              <Grid item xs={12} md={2} lg={2}></Grid>
              <Grid item xs={12} md={2} lg={2}></Grid>
            </Grid>
          </Container>    
        </Box>
      </Box>
    </ThemeProvider>
  );
}