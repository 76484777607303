import * as React from 'react';
import { Link } from "react-router-dom";
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import HomeIcon from '@mui/icons-material/Home';
import { mainListItems, secondaryListItems } from '../listitems';
import '../stratfin.css'

//import deepdive1 from '../images/deepdive1.png';
//import deepdive2 from '../images/deepdive2.png';

const drawerWidth = 260;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
    },
  }),
);

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme({
    typography: {
      fontFamily: "Inter"
    },
  });

export default function DeepDive() {
  const [open, setOpen] = React.useState(true);
  const toggleDrawer = () => {
    setOpen(!open);
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar position="absolute" open={open}>
          <Toolbar
            sx={{
              pr: '24px', // keep right padding when drawer closed
              backgroundColor: '#1b213b' 
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: '36px',
                ...(open && { display: 'none' }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              component="h1"
              fontFamily={"Inter"}
              variant="h6"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              <b>Strategic Finance Program</b>
            </Typography>
            <Link to="/" className="link">
            <IconButton color="inherit">
                <HomeIcon />
            </IconButton>
            </Link>
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <Toolbar
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              px: [1],
            }}
          >
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <Divider />
          <List component="nav">
            {mainListItems}
            <Divider sx={{ my: 1 }} />
            {secondaryListItems}
          </List>
        </Drawer>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
        >
          <Toolbar />

          <Box component="main" sx={{ backgroundColor: 'white', flexGrow: 1, height: '100vh', overflow: 'auto', }}>
          <Container maxWidth="lg" sx={{ mt: 4, mb: 2, ml: 1, mr: 1}}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={2} lg={2}></Grid>
                <Grid item xs={12} md={8} lg={8}>
                  <Paper sx={{p: 2,display: 'flex', flexDirection: 'column', boxShadow: 'none'}}>

                    <h1 className='program-header'><b>Deep Dives</b></h1>
                    <br/>
                    <p className='program-text'>You will not find a section like this in any other finance course. This section teaches you a skill that only highly analytical stratfin professionals possess, or even know about.</p>   
                    <p className='program-text'>At its simplest, a deep dive is an analysis where you look into metrics at their most granular level to uncover insights. Here is an example:</p>
                    <br/>
                    {/* <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', backgroundColor: '#dee8fa' }}>
                    <h2 className='program-sub-header'>The $5 Million Day of Work</h2>
                    <p className='program-text'>This example is a real story where a StratFin team uncovered $5 million in fraud by doing a simple deep dive analysis. The charts and data in this section represent fake data in order to protect company and user data, but are used to provide visual examples.</p>
                    <p className='program-text'>The company in question is a delivery marketplace which partners with retailers in the United States to deliver products from stores directly to customer's homes.</p>
                    <p className='program-text'>A StratFin analyst at this company was looking at company metrics and noticed that new customer signups at one retailer in specific had spiked the last few months. We'll call this retailer, 'Retailer A'.</p>
                    <br/>
                    <img src={deepdive1} alt='Deep Dive'/><br/>
                    <p className='program-text'>A good analyst would see this chart and wonder why new signups for this retailer spiked 50% in February and have continued for three months.</p>
                    <p className='program-text'>The StratFin team asked out internally and no one had an explanation. There weren't any new sales or promotions and the retailer is historically known for their steady sales growth.</p>
                    <p className='program-text'>So the team was left with one solution. A deep dive into the numbers.</p>
                    <br/>
                    <img src={deepdive2} alt='Deep Dive'/><br/>
                    <p className='program-text'>One of the team's first checks was to analyze the signup growth by state. This ended up being the team's first clue as it revealed that the growth in new users was particulary large in four states.</p>
                    </Paper><br/>
 */}
                    

                </Paper>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Box>
    </Box>
  </ThemeProvider>
  );
}