import * as React from 'react';
import { alpha } from '@mui/material';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import AppBarStyle from '../components/AppBarStyle';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import { Helmet } from 'react-helmet';
import './blog.css'

// Images
import Netflix1 from './images/Netflix1.png'
import Netflix2 from './images/Netflix2.png'
import Netflix3 from './images/Netflix3.png'
import Netflix4 from './images/Netflix4.png'
import Netflix5 from './images/Netflix5.png'
import Netflix6 from './images/Netflix6.png'
import Netflix7 from './images/Netflix7.png'
import Netflix8 from './images/Netflix8.png'
import Netflix9 from './images/Netflix9.png'
import Netflix10 from './images/Netflix10.png'
import Netflix11 from './images/Netflix11.png'
import Netflix12 from './images/Netflix12.png'
import Netflix13 from './images/Netflix13.png'
import Netflix14 from './images/Netflix14.png'
import Netflix15 from './images/Netflix15.png'
import Netflix16 from './images/Netflix16.png'
import Netflix17 from './images/Netflix17.png'
import Netflix18 from './images/Netflix18.png'
import Netflix19 from './images/Netflix19.png'
import Netflix20 from './images/Netflix20.png'
import Netflix21 from './images/Netflix21.png'
import Netflix22 from './images/Netflix22.png'
import Netflix23 from './images/Netflix23.png'
import Netflix24 from './images/Netflix24.png'
import Netflix25 from './images/Netflix25.png'
import Netflix26 from './images/Netflix26.png'
import Netflix27 from './images/Netflix27.png'
import Netflix28 from './images/Netflix28.png'
import Netflix29 from './images/Netflix29.png'
import Netflix30 from './images/Netflix30.png'
import Netflix31 from './images/Netflix31.png'
import Netflix32 from './images/Netflix32.png'
import Netflix33 from './images/Netflix33.png'
import Netflix34 from './images/Netflix34.png'
import Netflix35 from './images/Netflix35.png'
import Netflix36 from './images/Netflix36.png'
import Netflix37 from './images/Netflix37.png'
import Netflix38 from './images/Netflix38.png'
import Netflix39 from './images/Netflix39.png'
import Netflix40 from './images/Netflix40.png'
import Netflix41 from './images/Netflix41.png'
import Netflix42 from './images/Netflix42.png'
import Netflix43 from './images/Netflix43.png'
import Netflix44 from './images/Netflix44.png'
import Netflix45 from './images/Netflix45.png'
import Netflix46 from './images/Netflix46.png'
import Netflix47 from './images/Netflix47.png'

export default function Netflix() {
  return (
    <>
    <Helmet><title>The History of Netflix in Charts</title></Helmet>
    <AppBarStyle/>
    <Box id="hero" sx={(theme) => ({ width: '100%',  backgroundImage: theme.palette.mode === 'light' ? 'linear-gradient(180deg, #e89094, #FFF)' : `linear-gradient(#02294F, ${alpha('#090E10', 0.0)})`, backgroundSize: '100% 150px', backgroundRepeat: 'no-repeat',})}><br />
      <Container sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', paddingBottom: '20px', pt: { xs: 14, sm: 20 }, }}>
        <Stack spacing={2} useFlexGap sx={{ width: { xs: '100%', sm: '70%', lg: '60%' } }}>
          <Typography variant="h1" sx={{ display: 'flex', flexDirection: { md: 'row' }, alignSelf: 'center', textAlign: 'center', fontSize: 'clamp(3.4rem, 8vw, 3.9rem)', pb: '8px', maxWidth: '92%'}}>
            <b>The History of <span style={{color: '#e50914'}}>Netflix</span> in Charts</b>
          </Typography>
          <br/><br/>
          <p className='blog-text'>You are reading the first edition in a series of deep dives to teach business principles through charts and graphs of a company’s history.</p>                    
          <p className='blog-text'>This series was inspired by Charlie Munger who said: “If I were teaching in a business school, I would have Value Line-type figures that took people through the entire history of General Motors. I would try and relate the changes in the graph and in the data to what happened in the business...I mean, there’s just one after another that are utterly fascinating.”</p>
          <p className='blog-text'>Netflix is the first edition in this series because of its fascinating history that teaches us almost everything there is to learn about business. To give an example of how the series this work, here is a chart of Netflix’s stock price every day since its IPO in 2002:</p>
          <img src={Netflix1} alt='Netflix Stock Chart History'/><br/>
          <p className='blog-text'>Each peak and trough in this chart is a story in Netflix's history. The history of Netlix includes 8 unique periods of sustained 100% year-over-year stock price gains and a remarkable 3 instances where Netflix's stock fell over 70% from all-time highs.</p>
          <p className='blog-text'>The following history will take you through each bump in this chart as told by former Netflix employees and business journalists. </p>
          <p className='blog-text'>Let’s get started by going back to 1997.</p>
          <Divider/>
          <h3 className='blog-sub-header'>A Slow Start</h3>
          <p className='blog-text'>Netflix was founded in 1997 and launched on April 14, 1998 as a DVD rental service with 30 employees and an inventory of 925 movie titles. On the day of the launch, customers ordered 137 DVDs and Netflix employees had to rush the packaged up DVDs to the local post office by 3pm so the postal service could process and send them out before closing for the day.</p>
          <p className='blog-text'>You will learn shortly that Netflix was not an overnight sucess, at least relative to Google and Amazon, who were also founded in the mid/late 1990’s. 
          </p>
          <img src={Netflix2} alt='Tech Launches'/><br/>
          <h3 className='blog-sub-header'>The Co-Founders</h3>
          <p className='blog-text'>The history of Netflix, like the history of any company, is a story of human decision making.</p>
          <p className='blog-text'>Netflix was founded by <b>Marc Randolph</b> and <b>Reed Hastings</b> in 1997 in the San Francisco Bay Area. Marc Randolph was a marketing specialist and serial entrepreneur in the Bay Area who had just left a job at Pure Atria. </p>
          <p className='blog-text'>Pure Atria was a company created by the 1996 merger of Atria Software and Pure Software, a developer tool company founded and led by Reed Hastings. Reed led the combined companies for only one year before it was acquired by Rational Software in one of the largest mergers in Silicon Valley history at the time.</p>
          <p className='blog-text'>Reed took a job as CTO of the new combined company but didn’t plan to stick around. The acquisition made him rich, to say the least, and he had other interests like educational reform that he wanted to pursue. But he had to stay on board until the acquisition was finalized.</p>
          <p className='blog-text'>Reed drove into the office everyday thinking of what was next, alongside his carpool buddy Marc Randolph, who lived nearby to Reed and was also planning on quitting once the acquisition went through. Unlike Reed, Marc still needed an income to support his young family.</p>

          <h3 className='blog-sub-header'>Carpool Ideas</h3>
          <p className='blog-text'>Reed and Marc discussed new business ideas in the car while they waited a few months before being able to quit.</p>
          <p className='blog-text'>The two were inspired by what Jeff Bezos was building in Seattle as books could now be purchased from your computer and delivered to your door. Reed and Marc landed on shipping VHS tapes as their new business idea, before discovering the cost and complexity of shipping an item as large as a VHS tape.</p>
          <p className='blog-text'>The idea would get new wings as they discovered that DVDs, a thin and lightweight product, would soon be replacing the bulky VHS tapes. Reed and Marc tested their business idea by buying a DVD and shipping it to their house. The DVD arrived at their house in perfect condition and the idea for Netflix was born.</p>


          <h3 className='blog-sub-header'>Founding</h3>
          <p className='blog-text'>Reed was still rich and not interested in starting another company but Marc, being a serial entrepreneur, was itching to get started. </p>
          <p className='blog-text'>Reed pitched in $2 million while Marc gathered investment money from family and friends to start the company. Reed would own 70% of the company due to the amount of money he was putting in but go ahead and enroll at Stanford to start his work in education. Marc would be the CEO and own 30% of Netflix.</p>
          <p className='blog-text'>NetFlix.com was incorporated on August 29,  1997 and Marc started hiring engineers to start building the website in time for launch early the next year.</p>


          <h3 className='blog-sub-header'>Early Customers</h3>
          <p className='blog-text'>Business at NetFlix.com was steady the first two months after launch but Marc and the leadership team noticed two trends:</p>
          <ol>
            <li className='bullet'>The majority of their revenue was coming from DVD sales, not rentals. Customers were treating Netflix like Amazon, a place to buy media online. </li>
            <li className='bullet'>The small percentage of customers that were renting DVDs were not coming back. Customer churn for the rental product was unexpectedly high.</li>
          </ol>
          <img src={Netflix3} alt='1998 Revenue Breakdown'/><br/>
          <p className='blog-text'>As Netflix was finding out what to do with the business, they got a call from Jeff Bezos.</p>

          <h3 className='blog-sub-header'>Meeting Amazon</h3>
          <p className='blog-text'>When Jeff Bezos called the Netflix team in the summer of 1998, Amazon was three years old. A very big three year old. Amazon had gone public the previous year, had just logged their first $100 million revenue quarter, growing revenue 300% year-over-year. </p>
          <p className='blog-text'>Amazon's success was obvious and the stock market took notice. Their stock tripled over just a few weeks in the summer of 1998.</p>
          <img src={Netflix4} alt='Amazon Stock Price'/><br/>
          <p className='blog-text'>Marc and Reed may not have been aware at the time, but Amazon wanted to put this newfound wealth to work. Jeff was going on an acquisition spree. </p>       
          <p className='blog-text'> The details of the meeting between Netflix and Amazon is detailed in Marc Randolph’s 2019 book titled <i>That Will Never Work: The Birth of Netflix and the Amazing Life of an Idea</i> (a must-read for business students). </p>
          <p className='blog-text'>To recap the chapter dedicated to the Amazon meeting, Marc and Reed flew to Seattle to meet with Bezos and Amazon’s CFO at the time, Joy Covey. Amazon offered Marc and Reed $15 million to acquire Netflix but Marc and Reed rejected the offer shortly after. </p>
          <p className='blog-text'>After the meeting with Amazon, Marc and Reed held a strategy session. Their top takeaway from the meeting was that Amazon was ready to go all-in to expand their product beyond books and DVDs/digital media was their next target. In other words, Netflix was about to get crushed.</p>
          <p className='blog-text'>So Marc and Reed made their first of many crucial pivots in Netflix’s history. Just a few months old, Netflix was going to switch it up.</p>
         
          <h3 className='blog-sub-header'>The First Pivot</h3>
          <p className='blog-text'>The first pivot did not end up being a product pivot, but a CEO pivot. </p>
          <p className='blog-text'>In the fall of 1998, Reed sat Marc down and said he had lost faith in him to run the business. The conversation came as Reed told Marc that he has made too many mistakes and showed poor judgment with recent decisions.</p>
          <p className='blog-text'>The poor decisions included…</p>
          <p className='blog-text'>Reed continued by telling Marc that would take over as CEO himself while allowing Marc to stay on as President. He allowed Marc to take on what he had experience in - customer relations, marketing, PR, design, and promotions. </p>
          <p className='blog-text'>The move sounds crazy but Reed was Netflix’s largest shareholder at the time and likely saw the potential in front of the company. The CEO switch occurred in September and Reed quickly got to work building out his new leadership team.</p>

          <h3 className='blog-sub-header'>The Dream Team</h3>
          <p className='blog-text'>Over the next 18 months, Reed Hastings would put together what should be considered one of the best leadership teams ever assembled. Even more impressive was how fast he put the team together. Let’s take a look at the hires:</p>
          <ul>
            <li className='bullet'><b>Patty McCord</b> was the first critical hire in November 1998. Patty was one of Reed’s most trusted employees at Pure Software so she was hired to run human resources at Netflix. Patty would famously reshape Netflix’s culture and become one of the most influential human resources leaders in the world.</li>
            <li className='bullet'><b>Neil Hunt</b> was hired by Reed two months later as VP of Internet Engineering. Neil would later become Chief Product Officer and stay at Netflix for 18 years.</li>
            <li className='bullet'><b>Barry McCarthy</b> joined Netflix in April 1999 where he would stay for 11 years before becoming CFO of Spotify and CEO of Peloton. Barry is known for being the pioneer of the direct listing as he famously took Spotify public using a direct listing instead of an IPO.</li>
            <li className='bullet'><b>Tom Dillon</b> joined the same month as the Head of Operations. Marc Randolph described him as “one of the most important hires that Netflix ever made”.</li>
            <li className='bullet'><b>Leslie Kilgore</b> was next on Reed’s hiring spree list. She joined Netflix in March 2000 as VP of Marketing before becoming Chief Marketing Officer leading Netflix’s marketing strategy for 12 years. </li>
            <li className='bullet'>And last but not least, <b>Ted Sarandos</b> was hired that March with the title of VP of Content Acquisitions. Ted would become one of the most influential people in Hollywood and join Reed as Co-CEO more than 20 years later.</li>
          </ul>

          <p className='blog-text'>The new hires joined Reed, Marc, and <b>Mitch Lowe</b> to round out the Netflix leadership team. Mitch Lowe was an early Netflix advisor pre- launch and joined the company full-time in 1998 as VP of Business Development. Mitch would stay in the entertainment business as he served as the President and COO of Redbox and later as CEO of MoviePass which infamously failed from an unsustainable business model.</p>
          <p className='blog-text'>Everyone talks about “dream teams” and “super teams” in sports but almost never in business. A company lives and dies by its leadership team and Netflix had a super team. </p>
          <p className='blog-text'>Reed deserves all the credit for injecting himself as CEO and building this all-star leadership team. And each individual of the team deserves credit for building Netflix into what it is today.</p>
          <p className='blog-text'>Sticking with the sports analogy, Reed assembled a team that would make Netflix a dynasty. Without this team, Netflix does not exist today. What transpires over the rest of the story is solely attributable to this early team.</p>

          <h3 className='blog-sub-header'>1998 Financials</h3>
          <p className='blog-text'>Netflix’s launch year was not pretty financially. The company reported revenue of $1.3 million and $12 million in operating expenses. For every dollar in revenue, Netflix lost eight dollars.</p>
          <img src={Netflix5} alt='1998 Financials'/><br/>
          <p className='blog-text'>Netflix was founded with around $2 million in cash so the company started raising money from investors immediately. They raised a Series B round in 1998 and a Series C round in 1999 to fund the losses. The funding was critical as Netflix would continue to lose even more money over the coming years.          </p>

          <h3 className='blog-sub-header'>The First Product Pivot</h3>
          <p className='blog-text'>Customers didn’t love renting movies on Netflix in 1998. The majority of the company’s revenue was coming from DVD sales and Netflix was still certain that Amazon would put them out of business if they focused on DVD sales.</p>
          <p className='blog-text'>In a bizarre decision, Reed and his team decided to stop selling DVDs altogether. That’s right, Netflx cut the product that was accounting for 56% of their revenue in an instant. </p>
          <p className='blog-text'>A public company would drown in shareholder lawsuits if they ever attempted this, but Reed himself was still the largest shareholder and he needed the company to focus. So with DVD sales no longer using up company resources, all eyes were on DVD rentals.</p>
          <p className='blog-text'>Reed and his new super team brainstormed ideas to boost the DVD rental business. They landed on the following strategy in the summer of 1999:</p>
            <ol>
            <li className='bullet'>Create a subscription service where customers would pay one monthly fee and could rent as many movies as they would like, instead of paying a fee per rental.</li>
            <li className='bullet'>emove late fees. Late fees were a pain point for movie lovers who had suffered burdensome late fees from Blockbuster. (A popular story is that Reed Hastings started Netflix because he had a $40 late fee from Blockbuster. While he was annoyed with late fees, it wasn’t the reason why the idea for Netflix was made).</li>
            <li className='bullet'>Allow customers to create an ordered list of movies they wanted to watch so Netflix could instantly ship them a DVD once one was returned. This allowed users to get a continuous flow of DVDs without having to order them one at a time.</li>
            </ol>
          <p className='blog-text'>The subscription service launched in September at a price of $15.95 per month. It allowed customers to rent four DVDs per month with the option to pay $3.98 for each additional DVD rental. Once a DVD was watched and shipped back to Netflix, they would immediately mail out the next DVD in the customer’s queue. The customer could hold on to a DVD as long as they wanted. No late fees.</p>
          <p className='blog-text'>The product was an instant success. Netflix grew the rental business 700% in 1999 to finish with $5 million in total revenue. DVD sales made up $152k through March before being completely shut down. </p>
          <img src={Netflix6} alt='1999 Revenue Segments'/><br/>
          <p className='blog-text'>Reed’s second bold decision was starting to pay off. A trend had started.</p>

          <h3 className='blog-sub-header'>Unlimited Rentals and Cinematch</h3>
          <p className='blog-text'>The Netflix team made two major product changes in February 2000. The first product change was an overhaul of the subscription plan just five months after first launching:</p>
          <ul className='list-format'>
            <li className='bullet'><b>Old subscription plan:</b> 4 DVD rentals with no late fees for $15.95 per month with an option to get more DVDs for $3.98 per additional DVD.</li>
            <li className='bullet'><b>New subscription plan:</b> Unlimited DVD rentals each month for $19.98 per month. Once a DVD was returned, Netflix would automatically send the next DVD in the queue. The only caveat being the customer could only have 4 DVDs out at a time.            </li>
          </ul>
          <p className='blog-text'>The second change was the launch of Cinematch, Netflix’s movie recommendation algorithm. The algorithm has placed an underrated role throughout Netflix’s history.</p>     
      
          <h3 className='blog-sub-header'>Layoffs</h3>
          <p className='blog-text'>US gross domestic product declined for the first time in Q3 2001 as the 9/11 terrorist attacks spooked the country on top of the dot com bubble which had popped the year before. </p>
          <p className='blog-text'>Netflix was not immune from the economic conditions as subscriber growth slowed during the year.</p>
          <img src={Netflix7} alt='Subscribers 2000'/><br/>
          <p className='blog-text'> The company was still losing money each quarter at a time when funding was drying up. In late September 2001, Reed and the leadership team made the decision to lay off over 30% of the 120 employees at Netflix. </p>
          <p className='blog-text'>It was one of the toughest days in Netflix’s history as employees were notified in the office and had to pack up their belongings, leaving just 80 employees left to run the company.</p>
          <p className='blog-text'>The layoffs were a turning point for Netflix in ways that we will revisit shortly.</p>

          <h3 className='blog-sub-header'>IPO</h3>
          <p className='blog-text'>The company figured it would give going public another shot. After failing to go public as the dot com bubble burst, the company had $16 million of cash in the bank and had lost $39 million the year before. The company wasn’t facing imminent bankruptcy but it would need cash sooner rather than later.</p>
          <img src={Netflix8} alt='2001 Net Income'/><br/>
          <p className='blog-text'>The company filed its S-1 with the SEC in March 2002 and went public on May 23rd. The IPO would give Netflix another $80 million to work with and they put it to good use.          </p>
          <img src={Netflix9} alt='Liquidity'/><br/>
          <p className='blog-text'>In its first quarterly earnings release as a public company, Netflix reported a 118% increase in subscribers and a 98% increase in revenue. </p>
          <p className='blog-text'>Three months later, it was even better. 122% subscriber growth and 116% revenue growth year-over-year in Q3 2002. Netflix also reported a net loss of $2 million, its best financial quarter in history.</p>
          <img src={Netflix10} alt='Subscriber Growth'/><br/>
          <p className='blog-text'>Despite the business success in 2002, the stock was down 70% just five months after the IPO. It turns out the stock price performance wasn’t the fault of Netflix.          </p>
          <img src={Netflix11} alt='Stock Price 2002'/><br/>
          <p className='blog-text'>For some added context around this time period in mid-2002, the stock market was bottoming out. The dot-com bubble had burst and the stock market was still letting air out well into 2002. </p>
          <img src={Netflix12} alt='Nasdaq'/><br/>
          <p className='blog-text'>Investors still treated Netflix like a dot com stock despite the company removing “.com” from its name earlier in the year.          </p>
          <img src={Netflix13} alt='Nasdaq vs Netflix'/><br/>
          <p className='blog-text'>This part of the company’s history is a good time to quote Benjamin Graham, the father of value investing: "In the short run, the market is a voting machine, but in the long run, it is a weighing machine".</p>
          <p className='blog-text'>Investors could not sell Netflix shares forever. They would have no choice if the company continued to put up strong performance.</p>


          <h3 className='blog-sub-header'>Ready For Takeoff</h3>
          <p className='blog-text'>Netflix stock has increased 200% in a year three times. This is the story of the first occasion.</p>
          <p className='blog-text'> “2003 will be a year of milestones with a laser focus on the fundamentals that have made us one of today’s most dynamic Internet companies: generally overnight delivery to more than 50 percent of our subscribers and a library of DVD titles that would be virtually impossible for any consumer to amass or access at their local video store” - Reed Hastings, Q4 2002 shareholder letter</p>
          <p className='blog-text'>Reed’s quote came in January 2003 as Netflix prepared for battle. </p>
          <p className='blog-text'>In November, Walmart began testing its own DVD rental service as Netflix began to take share from Walmart’s DVD sales. Walmart announced they would undercut Netflix on price and launch their rental service nationwide in 2003 for a price of $18.86 per month, $1 cheaper than Netflix. </p>
          <p className='blog-text'>In December, Blockbuster acquired a small online DVD rental service called DVD Rental Central. Blockbuster was the largest DVD rental company in the world and was just starting to plan its response to Netflix’s threat.</p>
          <p className='blog-text'>Reed’s quote outlined the plan the company would rely on for decades: provide the best quality movies and shows to consumers in the most convenient way possible.</p>
          <p className='blog-text'>To achieve this, Netflix was ready to invest.</p>
          <img src={Netflix14} alt='Content Spend'/><br/>
          <p className='blog-text'>Netflix spent $23 million in cash expanding their DVD library which was the same amount they spent on DVDs in all of the previous year. </p>
          <img src={Netflix15} alt='Gross Margin'/><br/>
          <p className='blog-text'>The investments in expanding their DVD library affected gross margin. Entertainment companies amortize content which spreads out the expense over a time period, instead of booking the expense at the time of purchase. 
          </p><p className='blog-text'>Netflix offset the gross margin declines by cautiously managing operating expenses. </p>
          <img src={Netflix16} alt='Netflix Chart'/><br/>
          
          <h3 className='blog-sub-header'>Profitability</h3>
          <p className='blog-text'>Netflix celebrated its first quarter of profitability in Q2 2003 - the first milestone in Reed’s “year of milestones”. </p>
          <img src={Netflix17} alt='Netflix Chart'/><br/>
          <p className='blog-text'>Netflix tripled its subscriber count, went public, and reached profitability in just seven quarters after the company had cut 30% of its employees. Even more impressive was the US economy was in a recession during the start of this time period. </p>
          <p className='blog-text'>Let’s dive into Netflix’s business model while we’re on the topic of profitability. Netflix posted how they think about their business model in their Q1 2003 shareholder letter. The letter contains a table that breaks down a customer’s lifetime value (abbreviated as LTV).</p>
          <p className='blog-text'>We converted the table into this chart.</p>
          <img src={Netflix18} alt='Netflix Chart'/><br/>
          <p className='blog-text'>Netflix’s analysis showed in Q1 2003 they expected to make $343 in revenue per subscriber and $102 in EBITDA (profit). Having a positive customer LTV is an important milestone in a company’s history.</p>
          <p className='blog-text'>Lifetime revenue per customer for a company like Netflix is calculated by taking the monthly subscription price ($19.95 per month at the time) multiplied by the how many months the customer is expected to be a customer for. The latter is calculated using a metric called “churn”. </p>
          <p className='blog-text'>Netflix’s churn is the percentage of all subscribers who cancel their Netflix subscription each month. Their churn in Q1 2003 was 5.8%, so an average of 5.8% of customers canceled their account each month from January to March 2003.</p>
          <p className='blog-text'>“How many months a customer is expected to be a subscriber for” is calculated as the inverse of churn. 1 divided by 5.8% = 17.2. This may sound confusing but think of it like this: if a single customer decreases their likelihood of subscribing by 5.8% each month, if you start at a 100% and subtract 5.8% each month you will end up at 0% between the 17th and 18th month. </p>
          <p className='blog-text'>To get lifetime revenue, take $19.95 per month and multiply 17.2 to get $343 - how much Netflix expects to make in revenue from each customer over their time period as a Netflix customer. </p>
          <p className='blog-text'>The second part of the lifetime value calculation is to subtract costs. Netflix has historically classified their costs into five buckets that are pretty standard across the industry:</p>
          <ul>
          <li className='bullet'>Cost of Revenue: includes packaging and postage for shipping out DVDs, the cost of the DVDs (remember these costs are amortized), and any revenue share that Netflix pays out to content studios as part of their licensing agreement. </li>
          <li className='bullet'>Fulfillment: the cost to run and staff fulfillment centers and customer support centers. Netflix also included credit card fees in this cost bucket.</li>
          <li className='bullet'>Marketing: includes spend on advertising and promotions as well as the salaries for employees on the marketing team.</li>
          <li className='bullet'>Technology and Development: all costs related to maintaining the website and algorithms including hardware and payroll costs. This line item is generally called ‘Research and Development’ nowadays. </li>
          <li className='bullet'>General and Administrative: this bucket contains salaries from executives and support teams like finance, accounting, and legal. Office space costs and insurance costs are also included here.</li>
          </ul>
          <p className='blog-text'>To calculate these line items at the customer-level, Netflix took the ratio of revenue to each line item from the P&L. Lastly, Netflix added back to depreciation and amortization as its a non-cash expense.</p>
          <p className='blog-text'>The result is that Netflix estimated they make $102 over a customer’s time on Netflix.</p>
          
          <h3 className='blog-sub-header'>The First Stock Drop: Blockbuster Copies Netflix</h3>
          <p className='blog-text'>August 11, 2004: Blockbuster announces its Netflix copycat, a DVD rental shipping service. </p>
          <p className='blog-text'>Not only was Blockbuster going after Netflix with the same service, they were going to undercut Netflix on pricing. Blockbuster announced its service would start at $19.99 per month, cheaper than Netflix $21.99 per month.</p>
          <p className='blog-text'>Netflix shares fall 6% on the announcement date and another 7% the rest of the week.</p>
          <p className='blog-text'>All eyes were on Netflix’s first earnings call since the Blockbuster announcement. And things can go sideways, or downward in this case, when everyone is watching. </p>
          <p className='blog-text'>October 15, 2004: Netflix’s stock has its worst trading day on record, dropping 41%. </p>
          <img src={Netflix19} alt='Netflix Chart'/><br/>

          <h3 className='blog-sub-header'>Building Streaming</h3>
          <p className='blog-text'>Netflix was always destined to be a streaming company. Reed and Marc knew when Netflix was founded in 1997 that it would be inevitable that video could be watched over the internet, the technical capabilities to do that just didn’t exist yet.</p>
          <p className='blog-text'>In 2000, Reed and the engineering team were trying to accelerate that by streaming movies over the internet. The result was it would take 16 hours for the movie to download and cost $10 in bandwidth fees. (Source: Fortune: Reed Hastings: Leader of the pack).</p>
          <p className='blog-text'>In late 2003, Netflix tried again. They built a $300 box that contained a hard drive to download movies. The downloads still took several hours. </p>
          <p className='blog-text'>“Then, in 2005, YouTube came along, showing the world you could watch videos instantly rather than wait hours for a movie to download. For Hastings it was a revelation. “It was immediately apparent that the click-and-watch approach was fantastic,” Hastings says. He killed the hard-drive device and put his team to work on a streaming machine, a sort of YouTube-in-a-box.” - Fortune </p>
          <p className='blog-text'>To get streaming to work in the late 2000’s, Netflix bought bandwidth in bulk from AT&T and Verizon and was able to get the cost to stream a movie down to 5 cents, significantly cheaper than the $1 it took to ship and receive back a DVD.</p>
          <img src={Netflix20} alt='Netflix Chart'/><br/>
          <p className='blog-text'>As the streaming service took off in popularity compared to its legacy DVD business, Netflix decided to go all-in on streaming. Their biggest move was in March 2011, when the company outbid its competitors and paid $100 million for the rights to House of Cards, which would go on to win Netflix their first Emmy award.</p>
          <img src={Netflix21} alt='Netflix Chart'/><br/>
          <p className='blog-text'>As the content on Netflix’s streaming service improved, they were able to add 10 million new subscribers from Q1 2010 to Q2 2011. As their subscriber count increased, revenue increased vertically.</p>
          <p className='blog-text'>The Netflix story would be boring if the story ended with their world domination. Luckily for us, their next stock price collapse was right around the corner. </p>
          <p className='blog-text'>After reaching a $15 billion market cap in July 2011, the stock would drop 78% over the next year and suffer its worst year-over-year decline on record:</p>
          <img src={Netflix22} alt='Netflix Chart'/><br/>
          <p className='blog-text'>So what caused the stock to collapse? It wasn’t from competition or macroeconomic conditions, it was a self-inflicted error.</p>

          <h3 className='blog-sub-header'>The Qwikster Debacle</h3>
          <p className='blog-text'>Just eight months after launch, Netflix customers are in love with the streaming service (starting at $7.99 per month) that can now be purchased separately from the ‘DVD by mail’ product (starting at $9.99 per month). </p>
          <p className='blog-text'>While increasing streaming popularity was great for Netflix’s subscriber count, content studios soon caught on and began demanding more money from Netflix for content licensing. In Q2 2011 alone, Netflix tripled the amount they spent on content from the previous quarter.          </p>
          <img src={Netflix23} alt='Netflix Chart'/><br/>
          <p className='blog-text'>Netflix faced a dilemma -  the $9.99 per month plan was too good of a value. </p>
          <p className='blog-text'>The $9.99 per month plan allowed subscribers to rent 1 physical DVD at a time with free access to the streaming service, which was free for all customers on any physical DVD rental plan. The idea to include streaming for “free” with every DVD rental plan was set in place since 2007 when Netflix first launched the streaming service. </p>
          <p className='blog-text'>This pricing strategy worked to perfection as it allowed Netflix to scale their streaming service in ways that other companies could not. Other streaming services struggled to gain subscribers at a reasonable price point because no streaming company had a sufficiently large enough digital library to be able to charge for. </p>
          <p className='blog-text'>By offering streaming for free, Netflix could utilize its existing customer base to grow streaming and then find a way to monetize it later</p>
          <p className='blog-text'>The problem was that in the future, Netflix would have to stop offering streaming for free. And that bill was coming due as streaming costs skyrocketed.</p>
          <Divider/>
          <p className='blog-text'>So on July 12th, Netflix announced a massive change to its pricing policy. The DVD plus streaming bundle was retired and customers would now need to purchase DVD and streaming separately, with both starting at $7.99 per month each. The pricing changes worked out like this:          </p>
         <ul>
            <li className="bullet">For existing streaming-only customers, there was no price change. Their plan would remain at $7.99 per month for access to the streaming service.</li>
            <li className="bullet">For existing subscribers on the 1-DVD plan, their price would actually decrease from $9.99 to $7.99 per month, but they would lose access to the streaming service. </li>
            <li className="bullet">For new and existing customers who wanted to rent physical DVDs and have access to the streaming service, they would need to pay for each separately at $7.99 per month, or $15.98 per month total. </li>
        </ul>
          <p className='blog-text'>Unfortunately, many of Netflix’s subscribers were in the third bucket. Their beloved $9.99 DVD plus streaming plan was now $15.98 per month, a 60% increase!</p>
          <p className='blog-text'>The pushback from customers was immediate. </p>
          <p className='blog-text'>CNN wrote the next day: “If Netflix had filed Tuesday's price-hike news in a movie genre, it would be either drama or horror. U.S. customers of the DVD-by-mail and Web-streaming service are storming the Internet to protest Netflix's plan to increase the prices of its most popular packages.”</p>
          <p className='blog-text'>Wired, a technology publication, wrote, “Netflix is enduring a world of hate for its price hike, which ups the cost for a combined DVD-rental and streaming plan by 60 percent. Customers and the media are having a field day taking the company to task for requiring customers to pay $6 more a month for the same, exact service.</p>
          <p className='blog-text'>The pricing change took immediate effect for new subscribers but wouldn’t apply to existing subscribers until September.</p>
          <p className='blog-text'>It was now a waiting game.</p>
          <Divider/>
          <p className='blog-text'>The first part of the waiting game was only two weeks long as Netflix was scheduled to release earnings on July 25th, just 13 days after the pricing changes were announced.</p>
          <p className='blog-text'>The analysts covering the stock came out firing with questions about the pricing change. We’ll copy over three questions from the earnings call Q&A section here so you can see how Netflix leadership responded:</p>
          <p className='blog-text'><b>Question 1: How are you feeling about the price increase two weeks since announcing it? Do you expect for some of the subscribers turning off with the pricing increase, that they will return later, once they try other alternatives?</b></p>
          <p className='blog-text'>Answer: Like any customer-driven organization, we feel bad about having customers upset with us, but we feel great about the amazing new content we're going to be able to license in the fourth quarter and next year, which will further propel our growth and our subscriber satisfaction. The price change takes effect upon each subscriber's renewal in September, so we don't have a full-range view of it. But so far, from what we've seen, we've been very pleased at the effects, and we're feeling great about the decision, as tough as it is.</p>
          <p className='blog-text'><b>Question 2: So a follow-up question on timing. Why did you implement the recent pricing changes now, when subscriber growth is near the highest you've ever had? Why not wait until you are further into your penetration curve?</b></p>
          <p className='blog-text'>Answer: Well, I think the reason we felt confident about doing it now, is the strength of streaming-only, really we got convinced that we can thrive on streaming-only and with the great new content we're going to be able to get with this pricing change, the timing, the best timing was now.</p>
          <p className='blog-text'><b>Question 3: Why did you decide to push through such a large pricing increase, versus a smaller, less disruptive one?</b></p>
          <p className='blog-text'>Answer: We didn't approach it as what percent price increase should one do, and I think if we had, we probably wouldn't have gone to 60%. So I can understand the question. We really approached it on that we should separate the businesses and the plans, because streaming-only was going to become a global offering, and we wanted to really focus on that global streaming offering, and DVD is an incredible US-based plan that's very profitable. It's very large, and we wanted it to be able to be successful and to have a group that was going to focus on it. And the pricing change, somewhat, was an outcome of that.</p>
          <Divider/>
          <p className='blog-text'>So to recap, the company decided to separate the two services because streaming was becoming too large and too global to be thrown in with the DVD plan. On the other side, the DVD business was likely in decline and Netflix leadership knew it would not be around forever.</p>
          <p className='blog-text'>Another product of the earnings release was this incredible Venn diagram, which is probably one of the only Venn diagrams we have ever seen in a shareholder letter.</p>
          <img src={Netflix24} alt='Netflix Chart'/><br/>
          <p className='blog-text'>The diagram outlines that Netflix expects 25 million total subscribers by the end of Q3 2011, with 10 million subscribed to streaming only, 3 million to DVD only, and the remaining 12 million to subscribe to both. This indicated that Netflix leadership was optimistic that despite the complaining, most current DVD subscribers would shift to buying both subscriptions or simply churning from the service. </p>
          <p className='blog-text'>Investors were not so certain about the plan as Netflix shares declined 5% the day after the earnings call. The earnings call was the tip of the iceberg as shares continued to fall throughout August as Netflix entered September down 20% from their all-time high in July.</p>
          <img src={Netflix25} alt='Netflix Chart'/><br/>
          
          <h3 className='blog-sub-header'>Starz Negotiations</h3>
          <p className='blog-text'>On September 1st, the same day Netflix’s new pricing policy took effect for existing subscribers, Starz issued a one paragraph press release announcing they were ending negotiations with Netflix and would pull their content from Netflix: </p>
          <p className='blog-text'>"Starz Entertainment has ended contract renewal negotiations with Netflix. When the agreement expires on February 28, 2012, Starz will cease to distribute its content on the Netflix streaming platform. This decision is a result of our strategy to protect the premium nature of our brand by preserving the appropriate pricing and packaging of our exclusive and highly valuable content. With our current studio rights and growing original programming presence, the network is in an excellent position to evaluate new opportunities and expand its overall business."</p>
          <p className='blog-text'>The announcement was a blow to Netflix as they communicated to customers and investors that the pricing policy change would help Netflix add more streaming content. This was the opposite of what was supposed to happen. Netflix’s stock dropped 9% on the news.</p>
          <p className='blog-text'>Behind the scenes, it was rumored that Netflix offered Starz as much as $300 million annually for the streaming rights - more than 10x the amount of the original deal that was expiring. </p>
          <p className='blog-text'>Netflix later said that the Starz content made up 8% viewing time on Netflix. The company was now facing a new challenge. They had to make up 8% of lost content and they were likely going to have to pay beyond top dollar for it.</p>
       
          <h3 className='blog-sub-header'>The Press Release</h3>
          <p className='blog-text'>Two weeks later, Netflix posted a new document on their website called “Guidance Update September 15, 2011”. For the uninitiated, posting a guidance update in the middle of a quarter is usually never a good sign.</p>
          <p className='blog-text'>The one page update came with a simple message: Netflix is expecting 1 million fewer domestic subscribers than originally expected just seven weeks ago. The memo came with a new Venn diagram explaining the update:</p>
          <img src={Netflix26} alt='Netflix Chart'/><br/>
          <p className='blog-text'>The Venn diagram shows that Netflix was roughly correct about the amount of streaming only and streaming + DVD customers, but misjudged the number of DVD only customers. In short, the DVD only plan was dwindling. </p>
          <p className='blog-text'>The stock dropped 19% that Thursday on the news. And for good measure, the stock dropped another 8% on Friday. </p>
          <p className='blog-text'>Netflix leadership must have walked into the office on Monday morning thinking it couldn’t get worse. Their stock dropped 24% just last week alone and customers were turning on them. And somehow on that Monday morning, it was getting even worse.</p>
          
          <h3 className='blog-sub-header'>Qwikster Is Born</h3>
          <p className='blog-text'>The night before, Sunday September 18th, the company posted a blog on its website written by Reed Hastings. The blog post is titled “An Explanation and Some Reflections” and the first line of the blog reads, “I messed up. I owe everyone an explanation.”</p>
          <p className='blog-text'>However, the blog post was not just a reflection or an apology. The blog post continues with Reed saying they are splitting the DVD business and the streaming business entirely. Separate websites, separate billing, separate CEOs, and separate names. The streaming service will retain the Netflix brand while the DVD rental business will be called Qwikster.</p>
          <p className='blog-text'>If you haven’t heard this story before, by now you are likely thinking we made this entire story up or that Netflix was pulling a prank on their customers. I wish either were true. </p>
          <p className='blog-text'>From the customer’s perspective, not only did their DVD and streaming bundle increase from $10 per month to $16 per month, they now needed to manage two different account logins and pay for each service separately each month. As expected, customers were furious.</p>
          <p className='blog-text'>Reed Hastings was correct that streaming, and not DVD rentals, would be Netflix’s future, but he was wrong that Netflix must separate the two businesses completely so that the company “could focus on” the streaming service.</p>
          <p className='blog-text'>Netflix had already made it clear it was focusing on streaming but it didn’t need to isolate its DVD subscribers who still made up over half of all Netflix customers even after the price increase. While it was clear the DVD business was shrinking, Netflix easily could have managed to keep the DVD business under the Netflix brand while dedicating less resources to it.</p>
          <p className='blog-text'>Investors were as disappointed as the customers. The stock dropped 7% on Monday and then another 10% on Tuesday.</p>
          <img src={Netflix27} alt='Netflix Chart'/><br/>
          <p className='blog-text'>The guidance update and Qwikster rebrand sent the stock down 38% in less than a week. In total, the stock was down 56% from July when the pricing change was announced.</p>
          <p className='blog-text'>The $15 billion streaming leader, run by the current Businessperson of the Year, shrunk to a $7 billion company in months.</p>
          <p className='blog-text'>The wildest part? The stock would drop another 59% in the coming months.</p>


          <h3 className='blog-sub-header'>RIP Qwikster (Sep 18, 2011 - Oct 10, 2011)</h3>
          <p className='blog-text'>Netflix published another blog post on the morning of Monday, October 10th with the title, “DVDs will be staying at netflix.com”. The brief 141-word blog post reads:</p>
          <br/>
          <p className='blog-quote'>It is clear that for many of our members two websites would make things more difficult, so we are going to keep Netflix as one place to go for streaming and DVDs.</p>
          <p className='blog-quote'>While the July price change was necessary, we are now done with price changes.</p>
          <p className='blog-quote'>We’re constantly improving our streaming selection. We’ve recently added hundreds of movies from Paramount, Sony, Universal, Fox, Warner Bros., Lionsgate, MGM and Miramax. Plus, in the last couple of weeks alone, we’ve added over 3,500 TV episodes from ABC, NBC, FOX, CBS, USA, E!, Nickelodeon, Disney Channel, ABC Family, Discovery Channel, TLC, SyFy, A&E, History, and PBS.</p>
          <p className='blog-quote'>We value our members, and we are committed to making Netflix the best place to get movies & TV shows.</p>
          <p className='blog-quote'>Thank you. -Reed</p>
          <br/>
          <p className='blog-text'>And just like that, Qwikster was dead. All eyes were now on Q3 earnings which would be released in two weeks.</p>
          
          <h3 className='blog-sub-header'>Q3 2011 Earnings</h3>
          <p className='blog-text'>On October 24, 2011, Netflix released its first earnings report since the Qwikster debacle. The earnings report would show how many subscribers actually unsubscribed from the company and provide the truth behind the media narrative that customers were fleeing.</p>
          <img src={Netflix28} alt='Netflix Chart'/><br/>
          <p className='blog-text'>The earnings release showed Netflix lost 300,000 paying subscribers, a far cry from the amount the media was predicting. However, Netflix was adding about 2 million subscribers each quarter leading up to the pricing change, so the net effect was likely around 2.3 million subscribers lost from the whole debacle.</p>
          <img src={Netflix29} alt='Netflix Chart'/><br/>

          <p className='blog-text'>Further damage from the earnings call was Netflix’s guidance. The company told investors they could lose up to 3 million DVD subscribers again next quarter and the company will be unprofitable to start next year as they expand into the UK and Ireland.          </p>
          <p className='blog-text'>This news did not sit well with investors. The stock lost 35% of its value in the next 24 hours with one analyst calling it a “nuclear winter scenario”.</p>
          <img src={Netflix30} alt='Netflix Chart'/><br/>
          <p className='blog-text'>The stock trickled down the rest of the year to close at a $3.6 billion market cap, down 60% for the 2011 calendar year.</p>
          
          <Divider/>
          <h3 className='blog-sub-header'>International</h3>
          
          <p className='blog-text'>To start 2012, Netflix expanded into Europe for the first time as they launched in the United Kingdom and Ireland. </p>
          <p className='blog-text'>The strong momentum continued through January as Netflix beat Q4 earnings which sent the stock up 20%. The executive team told investors that the worst of the subscriber cancellations was behind them and that they would only experience “modest” quarterly losses. 
          </p>
          <p className='blog-text'>Netflix led off their shareholder letter stating that subscribers watched 2 billion hours of streaming last quarter, which comes out to 30 hours per subscriber per month, or 1 hour per subscriber per day.</p>
          <p className='blog-text'>Management’s focus on streaming was paying off even after the many missteps last year.</p>


          <p className='blog-text'>The good news wouldn’t last long as competitors started to launch new products. In February, Verizon and RedBox announced a joint venture which would provide a DVD rental and streaming subscription product as the two companies looked to combine their individual capabilities. Later in the month, Comcast announced their own streaming service called StreamPix, undercutting Netflix by charging $4.99 per month. </p>
          <p className='blog-text'>Earnings season came around in April and as expected, Netflix reported its first quarterly loss since 2005.</p>
          <img src={Netflix31} alt='Netflix Chart'/><br/>
          <p className='blog-text'>The losses came as Netflix expanded internationally, with a focus on Europe where the company just launched.</p>
          <p className='blog-text'>Despite a rocky 2011, the domestic business was still making $200 million per quarter. We can see the international business starting to eat up more losses as it topped a $100 million contribution loss in Q1.</p>
          <img src={Netflix32} alt='Netflix Chart'/><br/>
          <p className='blog-text'>Netflix was up to 2.4 million subscribers internationally in just a year and a half. While representing only 10% of the company’s total subscriber count, it was clear that Netflix’s streaming service was working outside of the United States.</p>
          <img src={Netflix33} alt='Netflix Chart'/><br/>


          <Divider/>
          <h3 className='blog-sub-header'>2013: The Year of Netflix</h3>
          <p className='blog-text'>While Reed Hastings considered Netflix a streaming company many years ago, investors and the general public didn’t fully recognize its power until 2013. This was highly transformational year for the company:</p>
         <ul>
            <li className='bullet'>Netflix wins its first Emmy awards as David Fincher takes home the award for best director of a drama series for House of Cards while Netflix also won best casting and best cinematography for House of Cards. This marked the first time a streaming-service only television show had won an Emmy. </li>
            <li className='bullet'>After a massive Q1 earnings surprise that bumped the stock up 40%, Jim Cramer coined the term “FANG” to reference Facebook, Amazon, Netflix, and Google. Although much smaller in market cap than the others, Netflix was now in consideration with these other massive companies as transformational companies.</li>
            <li className='bullet'>Speaking of the stock, it rose 285% in 2013 to hit a record high and surpassed a $20 billion market cap.</li>
         </ul>
          <p className='blog-text'>Those were the external headlines in 2013. But within the company, the financials were showing the early stage of what would become the most powerful company in entertainment.</p>
          <p className='blog-text'>At first glance, 2013 looked like a solid year financially. Contribution profit started to climb as subscriber growth accelerated. </p>
          <img src={Netflix34} alt='Netflix Chart'/><br/>
          <p className='blog-text'>But this chart hides a much more interesting story. The real story was that streaming became profitable by the end of 2012 and would surpass the legacy DVD in profitability by the end of 2013. Not only that, but streaming profitability was skyrocketing up and to the right.          </p>
          <img src={Netflix35} alt='Netflix Chart'/><br/>
          <p className='blog-text'>Diving into the numbers even further tells another angle of the story.</p>
          <img src={Netflix36} alt='Netflix Chart'/><br/>
          <p className='blog-text'>Domestic streaming actually surpassed DVD in profitability in Q1 2013. Netflix was using profits from those two segments to subsidize the international streaming business which had just launched in the UK, Ireland, Finland, Sweden, Denmark, and Norway in 2012.</p>
          <p className='blog-text'>We say 2013 was transformational for the company because it would follow this playbook for the next 3 years: invest in content to grow the increasingly profitable US streaming business while using the remaining profits from the shrinking DVD business to subsidize international expansion.</p>
          <p className='blog-text'>It turns out investors liked the plan. The company's market cap crossed $20 billion for the first time.</p>
          <img src={Netflix37} alt='Netflix Chart'/><br/>

          <h3 className='blog-sub-header'>Financial Reset</h3>
          <p className='blog-text'>Netflix took a break from spending in the wake of the Qwikster mistake. The company limited hiring and saw headcount decline from 2011 to 2014. </p>
          <img src={Netflix40} alt='Netflix Chart'/><br/>
          <p className='blog-text'>The company was still growing more than 20% year-over-year so the hiring slowdown resulted in a rapid acceleration in revenue per employee. Revenue per employee is a metric that some investors use to measure how efficient a company is. Some investors believe revenue per employee should increase every year due to efficiencies. </p>
          <img src={Netflix39} alt='Netflix Chart'/><br/>
          <p className='blog-text'>Netflix making $2.5 million revenue per employee was very impressive. How did stack up to some of the other great companies?</p>
          <img src={Netflix42} alt='Netflix Chart'/><br/>
          <p className='blog-text'>Growing revenue while keeping headcount flat allowed the company to expand operating margins. </p>
          <img src={Netflix41} alt='Netflix Chart'/><br/>
          <p className='blog-text'>The company also limited international expansion in addition to hiring in 2012 and 2013. Netflix launched in the UK and Ireland the first week of 2012 and only rolled out to Finland, Sweden, Denmark, and Norway, and The Netherlands over the next two and a half years.</p>
          <p className='blog-text'>Rolling out Netflix in a new market requires increased marketing spend in addition to higher overhead costs like legal and accounting. The company was able to preserve cash by slowing down expansion plans in 2012 and 2013. </p>
          <p className='blog-text'>Investors rewarded the margin improvement with a market cap nearing $30 billion with the hope that margins would continue to scale as Netflix grew.</p>
          <img src={Netflix43} alt='Netflix Chart'/><br/>
          <p className='blog-text'>But per usual, Reed had other ideas. As operating margins reached 10% for the first time since 2011, Reed was ready to invest it back in the business. </p> 
          <p className='blog-text'>Netflix was becoming more than a household name in the United States. Reed wanted to make it that way around the world.</p>

          <h3 className='blog-sub-header'>Becoming a Verb</h3>
          <p className='blog-text'>Companies dream of becoming a verb. “Let me Google it”, “Let’s Uber there”, “I can Photoshop it”. Netflix became a verb, albeit in a slightly different manner.</p>
          <p className='blog-text'>The phrase “Netflix and chill” took off in 2015. While the phrase itself went viral because of its unserious meaning, from a business perspective it signified a shift in customer viewing habits. </p>
          <p className='blog-text'>Netflix was known as “the” streaming service for most of the 2010’s as many companies did not launch true competitors until the late-2010s. </p>
          <p className='blog-text'>Netflix’s product was so compelling that millions of young adults were not signing up for cable after moving out of their parents house. Pew Research conducted a survey in 2015 showing that only 65% of people age 18-29 saying they had cable or satellite TV, compared to 80% of the 50-64 age demographic and 86% of adults aged 65+.</p>
          <p className='blog-text'>It's not an accident that the number of cable subscribers in the US peaked in 2010, the year Netflix unveiled its first streaming-only subscription plan. At the time, the typical cable package was $50-75 per month while Netflix was starting at $7.99 per month in an economy where the unemployment rate was at its highest level in three decades. </p>
          <img src={Netflix38} alt='Netflix Chart'/>
          <p className='blog-text'>Netflix was becoming the primary choice of entertainment for an entire generation. The company’s grapple on the 15-35 age demographic would pay clear dividends as Netflix went from a verb to defining culture around the world.</p>

          <h3 className='blog-sub-header'>International Expansion</h3>
          <p className='blog-text'>Netflix celebrated the milestone of reaching 10 million international paid subscribers in early 2014. It took three years for Netflix to reach the milestone since it launched internationally while it took ten years to reach 10 million paid US subscribers.</p>
          <p className='blog-text'>The company reached 10 million international subscribers with limited expansion. Outside of North and South America, Netflix was available in just seven countries. </p>
          <p className='blog-text'>Netflix’s leadership team saw the potential of international expansion and decided to expand more into Europe. Netflix launched in France, Germany, Belgium, Austria, Luxembourg, and Switzerland in September 2014 while also launching in India, their first country in Asia.</p>
          <p className='blog-text'>Subscriber counts started exploding. Netflix added almost 5M net subscribers in Q1 2015 with over half of the new subscribers coming from international streaming. </p>
          <img src={Netflix44} alt='Netflix Chart'/>
          <p className='blog-text'>It was clear to Netflix’s leadership team that Netflix’s streaming service and content could be popular anywhere in the world. So as 2015 progressed, the Netflix team began plotting what launching in every country in the world would look like.</p>
          <p className='blog-text'>In the first week of January 2016, Reed Hastings prepared to give a keynote address at the Consumer Electronics Show (CES), one of the most important tech conferences in the world.</p>
          <p className='blog-text'>Reed’s keynote came with a surprise. He announced Netflix would enter 130 new countries immediately which would mean Netflix was available in almost every country where it was permitted:          </p>
          <p className='blog-text'>“Today you are witnessing the birth of a new global Internet TV network. With this launch, consumers around the world -- from Singapore to St. Petersburg, from San Francisco to Sao Paulo -- will be able to enjoy TV shows and movies simultaneously -- no more waiting. With the help of the Internet, we are putting power in consumers’ hands to watch whenever, wherever and on whatever device.”</p>
          <p className='blog-text'>In typical Netflix fashion, Reed had a vision and he was not afraid to act upon it immediately. Netflix’s stock closed 9% higher on the day of the announcement</p>
          <p className='blog-text'>Investors could now see the vision. Once Netflix spent upfront capital to expand to every country, the profits would start rolling in within a year or two.</p>
          <p className='blog-text'>The worldwide launch was an immediate success. Netflix added a record 6.7 million subscribers in that quarter with 4.5 million subscribers coming internationally. </p>
          <p className='blog-text'>Netflix would add 13 million international subscribers alone in 2016 setting up for a major milestone.</p>
          <p className='blog-text'>In Q3 2017, the number of international paid subscribers outnumbered domestic subscribers for the first time in Netflix’s history. </p>
          <img src={Netflix45} alt='Netflix Chart'/>
          <p className='blog-text'>The timing of the worldwide launch was impeccable. It was an open secret around the entertainment industry that the content giants were planning their own streaming services to combat Netflix.</p>
          <p className='blog-text'>Netflix saw this coming and decided to go all-in. It would take several years for the legacy content owners to build their own streaming service. The problem was that it was already too late.</p>
          <p className='blog-text'>Netflix was watched in around half of all households in the US and growing rapidly around the world. Netflix was the king of streaming and they were building a larger castle every passing day.</p>
         
          <h3 className='blog-sub-header'>Disney</h3>
          <p className='blog-text'>Disney started publicly discussing streaming plans in 2015. In February, Bob Iger hinted that streaming was going to be a part of Disney’s future but did not provide any timelines or plan. In November, Disney launched DisneyLife, a streaming service in the UK, as a trial run of a streaming service.</p>
          <p className='blog-text'>DisneyLife allowed UK customers to stream Disney shows and movies for $9.99 GBP per month. Disney chose the UK for the test run because of content rights. Disney owned their own streaming rights in the UK compared to the US where it had existing licensing contracts with companies like Starz and Netflix.</p>
          <p className='blog-text'>The reason Disney didn’t dive into streaming is because it was making too much money.</p>
          <img src={Netflix46} alt='Netflix Chart'/>
          <p className='blog-text'>Disney was a four-headed monster in 2015. All four of their business segments made over $1 billion in operating income each, all four were growing revenue, and all four had expanding margins over the last five years.</p>
          <p className='blog-text'>Bob Iger, Disney’s CEO since 2005, knew streaming was the future of television but was facing a lose-lose situation. </p>
          <p className='blog-text'>Giving companies like Netflix, Starz, and Showtime licensing access to their content meant billions in high-margin fees but that meant Disney didn’t have access to their content if they were to create a streaming service. </p>

          <h3 className='blog-sub-header'>Defining Culture</h3>
          <p className='blog-text'>The list of Netflix shows and movies that have captured pop-culture throughout the years is numerous:</p>
            <ul>
                <li className='bullet'>Stranger Things (2016)</li>
                <li className='bullet'>13 Reasons Why (2017)</li>
                <li className='bullet'>Bird Box (2018)</li>
                <li className='bullet'>Tiger King (2020)</li>
                <li className='bullet'>The Queen’s Gambit (2020)</li>
                <li className='bullet'>Squid Game (2021)</li>
                <li className='bullet'>Wednesday (2022)</li>
                <li className='bullet'>Dahmer (2022)</li>
            </ul>
          <p className='blog-text'>For years, the top halloween costumes each year were references to Netflix shows that premiered that year. The trend started with costumes of Eleven from Stranger Things and carried on to Joe Exotic from Tiger King to Wednesday Adams from Wednesday. </p>
          <p className='blog-text'>Beyond the characters popularity, the actors from Netflix shows were reaching massive stardom from their shows. In 2018, Netflix wrote in their shareholder letter:</p>
          <p className='blog-quote'>“We’re also thrilled that Netflix has been a launching pad for a new generation of global stars like Millie
Bobby Brown, Jacob Elordi, Noah Centineo and Gaten Matarazzo. When our service helps our talent
develop huge fan bases (from small followings to over 10 million Instagram followers), we can attract
the best talent in the world. This explosive growth in popularity is a good indicator that our shows and
stars are breaking out around the planet.”
</p>
          <p className='blog-text'>Netflix also included this chart in that letter:</p>
          <p className='blog-text'>Netflix was creating stars of young talent like Nickelodeon and Disney did in the 2000’s. Kids around the world were idolizing actors that arose from starring in Netflix shows.</p>


          <h3 className='blog-sub-header'>How to Lose $50 Billion </h3>
          <p className='blog-text'>The Netflix leadership team knew it was going to be ugly. On April 19, 2022 they would report first quarter earnings and have to tell investors they lost 200,000 subscribers last quarter, their first time losing subscribers in ten years.</p>
          <p className='blog-text'>And worse yet, their internal forecasts where showing that they would lose a further 2 million subscribers next quarter. </p>
          <p className='blog-text'>Because company leadership knew the financial results several weeks before having to publicly release them, they prepared the following pitch to investors (paraphrasing): </p>
          <p className='blog-text'>We know losing subscribers is unacceptable so we are going to launch two major changes that we have been testing the last few years. First, we are going to limit password sharing and force people sharing an account to pay their fair share. And second, we are finally going to roll out an ad-supported plan after years of resisting ads on Netflix.</p>
          <p className='blog-text'>Netflix opened the quarterly shareholder letter with the following: </p>
          <p className='blog-quote'>“Fellow shareholders, our revenue growth has slowed considerably as our results and forecast below show. Streaming is winning over linear, as we predicted, and Netflix titles are very popular globally. However, our relatively high household penetration - when including the large number of households sharing accounts - combined with competition, is creating revenue growth headwinds.”</p>
          <p className='blog-text'>They then released a number that surprised investors. Netflix estimated that 100 million households globally were using someone else’s Netflix account. The company was ready to put a stop to it.</p>
          <p className='blog-text'>The company made no mention of an ad-supported tier in the shareholder letter but Reed again shocked investors when he mentioned its possibility when he was asked a broad question about Netflix’s pricing strategy:</p>
          <p className='blog-quote'>“And one way to increase the price spread is advertising on low-end plans and to have lower prices with advertising. And those who have followed Netflix know that I've been against the complexity of advertising and a big fan of the simplicity of subscription. But as much I'm a fan of that, I'm a bigger fan of consumer choice. And allowing consumers who would like to have a lower price and are advertising-tolerant get what they want makes a lot of sense. So that's something we're looking at now. We're trying to figure out over the next year or 2. But think of us as quite open to offering even lower prices with advertising as a consumer choice.”</p>
          <p className='blog-text'>And there it is. Reed and Netflix officially dropped their opposition to ads on Netflix.</p>
          <p className='blog-text'>The call to action to get back to positive subscriber growth was monumental and represents one of the most important days in Netflix’s history. </p>
          <p className='blog-text'>Not everyone was impressed at the time, Netflix’s stock dropped 35% the next day.</p>
          <p className='blog-text'>Investors were now concerned. The shift to stop account sharing and launch ads came off as desperate. </p>
          <p className='blog-text'>Netflix’s stock continued to bleed. Over the next 15 trading days, Netflix would lose 3 percent or more in 8 of them. Netflix’s stock closed  at $166 per share on May 11th, down 76% from November and was now worth $74 billion.</p>



          <h3 className='blog-sub-header'>Reed Steps Down</h3>
          <p className='blog-text'>Reed Hastings announced he was stepping down as co-CEO and would assume the role of Executive Chairman as part of Netflix’s Q4 2022 earnings in January 2023.</p>
          <p className='blog-text'>Netflix would retain the Co-CEO model as they announced Greg Peters, Netflix’s Chief Operating Officer, would join Ted Sarandos as the other Co-CEO. </p>
          <p className='blog-text'>The news was not surprising as they had initiated the CEO transition plan back in 2020 when Ted Sarandos was promoted to Co-CEO. The timing coincided with the ad-supported plan and account sharing crackdowns which Greg had been managing. As these projects became the focus for Netflix it made sense for Greg to become a Co-CEO.</p>
          <p className='blog-text'>Reed would still be involved in high-level strategy discussions as executive chairman on the Board of Directors but would hand over day-to-day responsibilities to Ted and Greg. Ted’s role as managing all content-related decisions would remain unchanged while Greg would take on more responsibility in addition to leading the ad-supported plan and account sharing projects.</p>
          <p className='blog-text'>If anyone ever asks about Reed’s impact on Netflix after his 24 year run as CEO, just show them this chart:</p>
          <img src={Netflix47} alt='Netflix Chart'/><br/>

          <h3 className='blog-sub-header'>“Winning” The Streaming Wars</h3>
          <p className='blog-text'>Netflix was declared the winner of the streaming wars in 2023 by business and entertainment news outlets. News outlets make money from headlines and declaring Netflix the winner of the streaming wars drove clicks. In reality, the war was not a streaming war and there will never be a winner.</p>
          <p className='blog-text'>A more accurate headline would read: “Netflix profits hit record highs as competitors struggle to match Netflix’s ability to create entertainment that people like.”</p>
          <p className='blog-text'>Netflix outlined their competitive position in their Q1 2024 shareholder letter: “We have built a hard to replicate combination of a strong slate, superior recommendations, broad reach and intense fandom, which drives healthy engagement on Netflix. Improvement in these key areas is the best way to delight our members and continue to grow our business.”</p>
         
          <br/><Divider/>
          <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', backgroundColor: '#f2f2f2', marginTop: '20px'}}>
            <p className='blog-text'>This is a live history of Netflix that we continuously update. We hope (and expect) to make updates for decades to come through each chapter of Netflix's history.</p>
          </Paper><br/>

        </Stack>
      </Container>
    </Box>
    </>
  );
}