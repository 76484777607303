import * as React from 'react';
import AppBarStyle from '../components/AppBarStyle';
import { alpha } from '@mui/material';
import { Link } from "react-router-dom";
import { Container, Box,  Stack, Card, CardContent, CardMedia} from '@mui/material';
import { Helmet } from 'react-helmet';
import Netflix from '../blog/images/Netflix47.png'
import Disney2 from '../blog/images/Disney2.png'
  
const Blog = () => {
  return (
    <>
    <Helmet>
        <title>Alcove School - Blog</title>
      </Helmet>
    <AppBarStyle/>
        <Box id="hero" sx={(theme) => ({ width: '100%',  backgroundImage: theme.palette.mode === 'light' ? 'linear-gradient(180deg, #dbedff, #FFF)' : `linear-gradient(#02294F, ${alpha('#090E10', 0.0)})`, backgroundSize: '100% 220px', backgroundRepeat: 'no-repeat',})}><br />
            <Container sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', pt: { xs: 14, sm: 20, md: 14, lg: 14 }, }}>
                <Stack spacing={2} useFlexGap sx={{ width: { xs: '90%', sm: '70%', lg: '60%' } }}>

                    <h1 className='page-title'>Most Read</h1>
                    <br/>

                    <Link to="/blog/netflix" className='header-item-link'>
                        <Card sx={{ display: 'flex', boxShadow: 3 }}>
                            <Box sx={{ display: 'flex', flexDirection: 'column', width: '70%'}}>
                                <CardContent className='blog-card-header' sx={{ flex: '0 0 auto' }}>
                                    <b>The History of Netflix in Charts</b>
                                </CardContent>
                                <CardContent className='blog-card-text' sx={{pt: '0px', pd: '5px'}}>
                                    Over one hundred charts and graphs telling the story of how Netflix grew from a DVD rental service to a $250 billion entertainment company
                                </CardContent>
                            </Box>
                            <CardMedia component="img" sx={{ width: '30%', p: '10px'}} image= {Netflix} alt="Netflix"/>
                        </Card>
                    </Link>

                    <Link to="/blog/disney" className='header-item-link'>
                        <Card sx={{ display: 'flex', boxShadow: 3 }}>
                            <Box sx={{ display: 'flex', flexDirection: 'column', width: '70%'}}>
                                <CardContent className='blog-card-header' sx={{ flex: '0 0 auto' }}>
                                    <b>Disney's Streaming Profitability Concerns</b>
                                </CardContent>
                                <CardContent className='blog-card-text' sx={{pt: '0px', pd: '5px'}}>
                                Investors were taken aback when Disney released its Q4 earnings earlier this month as the company reported it lost $1.5 billion from its streaming service business last quarter
                                </CardContent>
                            </Box>
                            <CardMedia component="img" sx={{ width: '30%', p: '10px'}} image= {Disney2} alt="Netflix"/>
                        </Card>
                    </Link>


        </Stack>
     </Container>
   </Box></>
  );
};

export default Blog;
