import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import AppBarStyle from '../components/AppBarStyle';
import Divider from '@mui/material/Divider';
import { alpha } from '@mui/material';
import { Container, Grid, Box, Stack} from '@mui/material';
import { Helmet } from 'react-helmet';

const Financials = () => {
  return (
    <>
      <Helmet>
        <title>Alcove School - Financials</title>
      </Helmet>
        <AppBarStyle/>
            <Box id="hero" sx={(theme) => ({ width: '100%',  backgroundImage: theme.palette.mode === 'light' ? 'linear-gradient(180deg, #dbedff, #FFF)' : `linear-gradient(#02294F, ${alpha('#090E10', 0.0)})`, backgroundSize: '100% 270px', backgroundRepeat: 'no-repeat',})}><br />
                <Container sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', pt: { xs: 14, sm: 20, md: 14, lg: 14 }, }}>
                    <Stack spacing={2} useFlexGap sx={{ width: { xs: '90%', sm: '70%', lg: '60%' } }}>
                        <h1 className='page-header'><b>Our Financials</b></h1>
        
                        <p className='program-text'>We believe in publishing a monthly income statement to provide transparency into Alcove School's financials and business model. This also provides students another opportunity to analyze financial statements. But as you'll see, there isn't much to see.</p> 

            <Grid>
                <Grid>
                <h2>July 2024 Financial Update</h2>
                <p className='program-text'>July revenue was $0 as we continue to not charge any program fees. Operating expenses increased from $1.67 in June to $18.45 in July as we strated testing advertising on Google Ads and started payments for Google Workspace.</p>
                <br/><br/>
                <TableContainer component={Paper}>
                <Table sx={{ minWidth: 350 }} aria-label="simple table">
                    <TableHead>
                    <TableRow sx={{backgroundColor: '#1b213b'}}>
                        <TableCell sx={{color: 'white'}}><b>July 2024 Income Statement</b></TableCell>
                        <TableCell sx={{color: 'white'}} align="right">July 2024</TableCell>
                        <TableCell sx={{color: 'white'}} align="right">Year-to-Date</TableCell>
                    </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                        <TableCell component="th" scope="row">Revenue</TableCell>
                        <TableCell align="right">$0.00</TableCell>
                        <TableCell align="right">$0.00</TableCell>
                        </TableRow>
                        <TableRow>
                        <TableCell component="th" scope="row">Cost of Revenue</TableCell>
                        <TableCell align="right">$0.00</TableCell>
                        <TableCell align="right">$0.00</TableCell>
                        </TableRow>
                        <TableRow sx={{ backgroundColor: '#f4f4f4' }}>
                        <TableCell component="th" scope="row">Gross Margin</TableCell>
                        <TableCell align="right">$0.00</TableCell>
                        <TableCell align="right">$0.00</TableCell>
                        </TableRow>
                        <TableRow>
                        <TableCell component="th" scope="row">General and Administrative</TableCell>
                        <TableCell align="right">$5.38</TableCell>
                        <TableCell align="right">$7.04</TableCell>
                        </TableRow>
                        <TableRow>
                        <TableCell component="th" scope="row">Sales and Marketing</TableCell>
                        <TableCell align="right">$13.07</TableCell>
                        <TableCell align="right">$13.07</TableCell>
                        </TableRow>
                        <TableRow>
                        <TableCell component="th" scope="row">Research and Development</TableCell>
                        <TableCell align="right">$0.00</TableCell>
                        <TableCell align="right">$0.00</TableCell>
                        </TableRow>
                        <TableRow sx={{ backgroundColor: '#f4f4f4'}}>
                        <TableCell component="th" scope="row"><b>Operating Income</b></TableCell>
                        <TableCell align="right"><b>-$18.45</b></TableCell>
                        <TableCell align="right"><b>-$20.11</b></TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
                </TableContainer>
                <br/><br/><br/><Divider/><br/>
                <h2>June 2024 Financial Update</h2>
                <p className='program-text'>Our revenue for Jun'24 was $0 as we continue our mission to not charge students a penny. Our total expenses this month were $2, which represents our domain and hosting costs. For July, we expect to recognize Google Workspace spend as we begin a paid subscription with Google for email and document management services.</p>
                <br/><br/>
                <TableContainer component={Paper}>
                <Table sx={{ minWidth: 350 }} aria-label="simple table">
                    <TableHead>
                    <TableRow sx={{backgroundColor: '#1b213b'}}>
                        <TableCell sx={{color: 'white'}}><b>June 2024 Income Statement</b></TableCell>
                        <TableCell sx={{color: 'white'}} align="right">June 2024</TableCell>
                        <TableCell sx={{color: 'white'}} align="right">Year-to-Date</TableCell>
                    </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                        <TableCell component="th" scope="row">Revenue</TableCell>
                        <TableCell align="right">$0.00</TableCell>
                        <TableCell align="right">$0.00</TableCell>
                        </TableRow>
                        <TableRow>
                        <TableCell component="th" scope="row">Cost of Revenue</TableCell>
                        <TableCell align="right">$0.00</TableCell>
                        <TableCell align="right">$0.00</TableCell>
                        </TableRow>
                        <TableRow sx={{ backgroundColor: '#f4f4f4' }}>
                        <TableCell component="th" scope="row">Gross Margin</TableCell>
                        <TableCell align="right">$0.00</TableCell>
                        <TableCell align="right">$0.00</TableCell>
                        </TableRow>
                        <TableRow>
                        <TableCell component="th" scope="row">General and Administrative</TableCell>
                        <TableCell align="right">$1.67</TableCell>
                        <TableCell align="right">$1.67</TableCell>
                        </TableRow>
                        <TableRow>
                        <TableCell component="th" scope="row">Sales and Marketing</TableCell>
                        <TableCell align="right">$0.00</TableCell>
                        <TableCell align="right">$0.00</TableCell>
                        </TableRow>
                        <TableRow>
                        <TableCell component="th" scope="row">Research and Development</TableCell>
                        <TableCell align="right">$0.00</TableCell>
                        <TableCell align="right">$0.00</TableCell>
                        </TableRow>
                        <TableRow sx={{ backgroundColor: '#f4f4f4'}}>
                        <TableCell component="th" scope="row"><b>Operating Income</b></TableCell>
                        <TableCell align="right"><b>-$1.67</b></TableCell>
                        <TableCell align="right"><b>-$1.67</b></TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
                </TableContainer>
            </Grid>
        </Grid>
        <br/><br/><br/><br/><br/><br/><br/><br/>
        </Stack>
    </Container>
    </Box>
    </>
  );
};

export default Financials;
