import * as React from 'react';
import { Link } from "react-router-dom";
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { Helmet } from 'react-helmet';
//import Link from '@mui/material/Link';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import HomeIcon from '@mui/icons-material/Home';
import { mainListItems, secondaryListItems } from '../listitems';
import '../stratfin.css'

const drawerWidth = 260;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
    },
  }),
);

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme({
    typography: {
      fontFamily: "Inter"
    },
  });

export default function Welcome() {
  const [open, setOpen] = React.useState(true);
  const toggleDrawer = () => {
    setOpen(!open);
  };

  return (
    <>
    <Helmet>
        <title>Strategic Finance Program</title>
      </Helmet>
    <ThemeProvider theme={defaultTheme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar position="absolute" open={open}>
          <Toolbar
            sx={{
              pr: '24px', // keep right padding when drawer closed
              backgroundColor: '#1b213b' 
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: '36px',
                ...(open && { display: 'none' }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              component="h1"
              fontFamily={"Inter"}
              variant="h6"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              <b>Strategic Finance Program</b>
            </Typography>
            <Link to="/" className="link">
            <IconButton color="inherit">
                <HomeIcon />
            </IconButton>
            </Link>
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <Toolbar
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              px: [1],
            }}
          >
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <Divider />
          <List component="nav">
            {mainListItems}
            <Divider sx={{ my: 1 }} />
            {secondaryListItems}
          </List>
        </Drawer>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
        >
          <Toolbar />
       
          <Box component="main" sx={{ backgroundColor: 'white', flexGrow: 1, height: '100vh', overflow: 'auto', }}>
          <Container maxWidth="lg" sx={{ mt: 4, mb: 2, ml: 1, mr: 1}}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={2} lg={2}></Grid>
              <Grid item xs={12} md={8} lg={8}>
                <Paper sx={{p: 2,display: 'flex', flexDirection: 'column', boxShadow: 'none'}}>

                  <h1 className='program-header'>Welcome!</h1><br/>
                  <p className='program-text'>Strategic finance is the most interesting career path in business.</p>
                  <p className='program-text'>You will be involved in almost every significant company decision. Our goal is to help you develop the skills needed to make these decisions throughout your career, starting at the analyst level.</p>
                  
                  <br/>
                  <Divider/>
                  <br/>

                  <h2 className='program-sub-header'>The Hardest Problem In Business</h2> 
                  <p className='program-text'>You will be able to solve the hardest problems in business by the end of this program. One of the boldest strategic business decisions in history was the launch of Amazon Prime.</p>
                  <p className='program-text'>In the last section of this program, you will prepare the following using the real-world example of Amazon launching the Amazon Prime program twenty years ago. We use this example because it covers pretty much everything you will come across in your strategic finance job.</p>
                  <br/>
                  <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', backgroundColor: '#dee8fa' }}>
                    <h2 className='program-sub-header'>Amazon Prime Proposal</h2>
                    <p className='program-text'>Jeff Bezos calls you into his office with an idea. Jeff wants to create a membership program that allows for unlimited 2-day shipping for $79 per year.</p>
                    <p className='program-text'>Its 2004 and the idea is beyond radical.</p>
                    <p className='program-text'>However, you are the Head of Strategic Finance at Amazon so you are used to hearing crazy ideas and being asked to analyze complex projects. Jeff doesn't gives you any more details about the plan and wants you to do the following for him:</p>
                    <ul>
                      <li className='bullet'><b>Build a 5-year financial model</b> that estimates the total financial impact of the idea.</li>
                      <li className='bullet'><b>Lead coordination</b> with the operations, marketing, and engineering teams to bring them up to speed on the idea and get feedback on how to build it.</li>
                      <li className='bullet'><b>Do research</b> on similar services and other subscription-based companies.</li>
                      <li className='bullet'><b>Write a comprehensive memo</b> that outlines the project's opportunity, risk, and financial considerations and prepare to present it to Jeff and the executive team next month.</li>
                    </ul>

                    <p className='program-text'>Jeff then tells you to prepare for monitoring Amazon Prime once it launches in February of next year. He asks for the following:</p>
                    <ul>
                      <li className='bullet'><b>Set up a weekly business review</b> with all program leaders that tracks 150 key metrics that measure the program's performance.</li>
                      <li className='bullet'><b>Build the forecasting model</b> to monitor the program's financial performance that will be presented to Jeff and the executive team each month.</li>
                      <li className='bullet'><b>Prepare SQL data tables</b> so you can perform analysis and deep dives using granular user and product data.</li>
                      <li className='bullet'><b>Draft goals and OKRs</b> that internal teams will be measured on next year.</li>
                    </ul>
                  </Paper>
                  <br/>

                  <p className='program-text'>Sound difficult? This would take several months for an entire strategic finance team of 3-4 people to do. Very hard but very rewarding work.</p>
                  <p className='program-text'>This example is all about what strategic finance does. Strategic finance goes well beyond number crunching. It is a critical part of the business that leads strategy for business units and faciliates key projects like this one. </p>
                  <p className='program-text'>We hope this provides context to what strategic finance does and what you should be learning throughout this program. Take you time to master each section before reaching this project at the very end.</p>
                  <br/>
                  <Divider/>
                  <br/>
                  <h2 className='program-sub-header'>How This Program Works</h2> 
                  <ul>
                    <li className='bullet'><b>Self Paced</b>: this program should take 30-50 hours to complete, take your time to complete each section and do as much practice as you need.  </li>                    
                    <li className='bullet'><b>Practice</b>: we have built countless practice problems into this program. Practice until you feel comfortable with what you learn.</li>
                    <li className='bullet'><b>Certfication</b>: add this program to your resume when you complete it. </li>
                    <li className='bullet'><b>Career Help</b>: take advantage of our career resources. Email us at <i>careers@alcoveschool.com</i> and let us know how we can help you in your career.</li>
                  </ul>
                  <br/>
                  <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', backgroundColor: '#f2f2f2' }}>
                    <h2 className='program-sub-header'>Next Section</h2>
                    <p className='program-text'>Head to our <Link to="/stratfin/careers">Strategic Finance Career Guide</Link> to learn about your new career path.</p>
                  </Paper>
             
                </Paper>
              </Grid>
              <Grid item xs={12} md={2} lg={2}></Grid>
              <Grid item xs={12} md={2} lg={2}></Grid>
            </Grid>
          </Container>    
        </Box></Box></Box>
    </ThemeProvider>
    </>
  );
}