import * as React from 'react';
import { Link } from "react-router-dom";
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import HomeIcon from '@mui/icons-material/Home';
import { mainListItems, secondaryListItems } from '../listitems';
import '../stratfin.css'

import TeslaIS1 from '../images/teslais1.png';
import TeslaIS2 from '../images/teslais2.png';
import TeslaIS3 from '../images/teslais3.png';
import TeslaIS4 from '../images/teslais4.png';
import TeslaIS5 from '../images/teslais5.png';
import TeslaIS6 from '../images/teslais6.png';

const drawerWidth = 260;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
    },
  }),
);

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme({
    typography: {
      fontFamily: "Inter"
    },
  });

export default function TeslaIS() {
  const [open, setOpen] = React.useState(true);
  const toggleDrawer = () => {
    setOpen(!open);
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar position="absolute" open={open}>
          <Toolbar
            sx={{
              pr: '24px', // keep right padding when drawer closed
              backgroundColor: '#1b213b' 
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: '36px',
                ...(open && { display: 'none' }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              component="h1"
              fontFamily={"Inter"}
              variant="h6"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              <b>Strategic Finance Program</b>
            </Typography>
            <Link to="/" className="link">
            <IconButton color="inherit">
                <HomeIcon />
            </IconButton>
            </Link>
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <Toolbar
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              px: [1],
            }}
          >
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <Divider />
          <List component="nav">
            {mainListItems}
            <Divider sx={{ my: 1 }} />
            {secondaryListItems}
          </List>
        </Drawer>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
        >
          <Toolbar />

          <Box component="main" sx={{ backgroundColor: 'white', flexGrow: 1, height: '100vh', overflow: 'auto', }}>
          <Container maxWidth="lg" sx={{ mt: 4, mb: 2, ml: 1, mr: 1}}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={2} lg={2}></Grid>
                <Grid item xs={12} md={8} lg={8}>
                  <Paper sx={{px: 5, py: 2, display: 'flex', flexDirection: 'column', boxShadow: 'none'}}>

                    <h1 className='program-header'><b>Tesla's Income Statement</b></h1>
                    <br/>
                    <p className='program-text'>Tesla's income statement covers aspects we have not discussed yet, like gross margin by product, restructuring costs, and noncontrolling interests.</p>                        
                    <img src={TeslaIS1} alt='Tesla Income Statement'/><br/>
                    <p className='program-text'>This income statement may be overwhelming, but its not much different than Google's and Southwest. It just has more detail that is helpful to investors. </p>                        
                    <br/>
                    <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', backgroundColor: '#dee8fa' }}>
                      <h2 className='program-sub-header'>Practice</h2>
                      <p className='program-text'>This entire section is interactive. We are going to walk through each section of the income statement and then apply it to a spreadsheet of Tesla's income statement.</p>
                      <p className='program-text'>Open the <a href='https://docs.google.com/spreadsheets/d/1FK-rD2lqWGD13D4jhRRv2VjXwg5-25y70TK_r4dQ9y8/edit?gid=0#gid=0' target="_blank" rel="noreferrer" >Tesla Income Statement Workbook</a> in a new tab. We will start using it in a few minutes.</p>
                    </Paper>

                    <br/>
                    <p className='program-text'>Tesla breaks out their revenue segments on the income statement. If you want to read more about what each segment is, feel free to read more in <a href='https://ir.tesla.com/_flysystem/s3/sec/000162828024002390/tsla-20231231-gen.pdf' target='_blank' rel='noreferrer'>Tesla'a Annual Report</a>.</p>
                    <img src={TeslaIS2} alt='Tesla Income Statement'/><br/>
                    <p className='program-text'>Tesla also breaks out the cost of revenue per segment, which is pretty unqiue as many companies do not disclose the gross margin of their business lines to prevent competitors from seeing it.</p><br/>
                    <img src={TeslaIS3} alt='Tesla Income Statement'/><br/>
                    <p className='program-text'>Our first practice question involves calculating Tesla's gross profit and gross margin by product. The answer tells a very interesting story about Tesla's business during this time period.</p><br/>



                    <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', backgroundColor: '#dee8fa' }}>
                      <h2 className='program-sub-header'>Practice Question #1</h2>
                      <p className='program-text'>In the <a href='https://docs.google.com/spreadsheets/d/1FK-rD2lqWGD13D4jhRRv2VjXwg5-25y70TK_r4dQ9y8/edit?gid=0#gid=0' target="_blank" rel="noreferrer" >practice file</a> that you just opened, calculate Tesla's gross margin for each business segment, starting in line 22. You should input one formula and then drag the formula to each of the 15 cells.</p>
                    </Paper><br/>
                    
                    <p className='program-text'>The gross margin percentage (calculated as gross profit / revenue) will calculate automatically below it. Take a second to look at each line item and see what you notice.</p><br/>
                    <img src={TeslaIS4} alt='Tesla Income Statement'/><br/>
                    <p className='program-text'>Here are some takeaways:</p>
                    <ul>
                      <li className='bullet'>Automotive sales gross margins are declining. Tesla was in the middle of a price war in 2023 as many competitors, especially in China, started to ramp up EV production. Tesla was now producing over 1 million cars per year and there was a concern that Tesla was producing too many cars, leading to price cuts in order to avoid a backup of inventory. </li>
                      <li className='bullet'>The regulatory credits business line have a 100% gross margin, meaning they are pure profit. Tesla gets credits from governments around the world for producing emission free vehicles. They then take these credits and sell them to other car makers for a pure profit.</li>
                      <li className='bullet'>The services and energy businesses were gross margin negative in 2021, meaning Tesla was losing money on each product sold even before accounting for overhead costs.</li>
                    </ul>

                    <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', backgroundColor: '#dee8fa' }}>
                      <h2 className='program-sub-header'>Practice Question #2</h2>
                      <p className='program-text'>Calculate operating income in row 50. </p>
                    </Paper><br/>

                    <p className='program-text'>Hopefully that was easy. Operating income is what is left after subtracting operating expenses from gross profit.</p>
                    <p className='program-text'>Operating income is our favorite profitability metric because it reflects the company's true performance, unlike net income which can be affected by one-off investment or tax charges like we'll see shortly. When we're measuring how profitable a business is, the first thing we look at is operating margin.</p>
                    <br/>
                    <img src={TeslaIS5} alt='Tesla Income Statement'/><br/>
                    <p className='program-text'>Tesla's operating margin fell quite a bit in 2023, which we know is because of the large drop in gross margin. Operating expenses as a percent of revenues were only up slightly, from 8.8% in 2022 to 9.1% in 2023 implying that Tesla kept costs relatively in-line depsite the aggressive price cuts.</p>
                    <br/>
                    
                    <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', backgroundColor: '#dee8fa' }}>
                      <h2 className='program-sub-header'>Practice Question #3</h2>
                      <p className='program-text'>Calculate net income and profit margin in rows 58 and 59. </p>
                    </Paper><br/>
                    <p className='program-text'>We admit this one was more challenging. It can be difficult to know which line items are supposed to be positive or negative.</p>
                    <br/>
                    <img src={TeslaIS6} alt='Tesla Income Statement'/><br/>
                    <p className='program-text'>In general, interest income represents a positive number as its income the company is making while income expense is a cash outflow. What we need to look out for is the word 'net', as it implies the number could be positive or negative. "Other income, net" means that a positive number is income while a negative number is an expense. The opposite would be true if the line item was "Other expense, net". In this case a positive number would mean an expense.</p>
                    <p className='program-text'>The interesting part of this income statement is how Tesla reported negative $5 billion in taxes, meaning they earned $5 billion instead of paying taxes.</p>
                    <p className='program-text'>What Tesla did is beyond the scope of this course and should be in an advanced accounting course. The short version is that Tesla actually paid $1 billion in taxes in 2023, similar to its tax bill in 2022. The difference is Tesla recognized $6 billion in tax credit carry-forwards.</p>

      

                </Paper>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Box>
    </Box>
  </ThemeProvider>
  );
}