import * as React from 'react';
import { Link } from "react-router-dom";
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import HomeIcon from '@mui/icons-material/Home';
import { mainListItems, secondaryListItems } from '../listitems';
import '../stratfin.css';

import SouthwestIS1 from '../images/southwestis1.png';
import SouthwestIS2 from '../images/southwestis2.png';
import SouthwestIS3 from '../images/southwestis3.png';
import SouthwestIS4 from '../images/southwestis4.png';
const drawerWidth = 260;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
    },
  }),
);

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme({
    typography: {
      fontFamily: "Inter"
    },
  });

export default function SouthwestIS() {
  const [open, setOpen] = React.useState(true);
  const toggleDrawer = () => {
    setOpen(!open);
  };

  return (

    // Theme Template.
    <ThemeProvider theme={defaultTheme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar position="absolute" open={open}>
          <Toolbar sx={{ pr: '24px', backgroundColor: '#1b213b'}} >
            <IconButton edge="start" color="inherit" aria-label="open drawer" onClick={toggleDrawer} sx={{ marginRight: '36px', ...(open && { display: 'none' }),}}>
              <MenuIcon />
            </IconButton>
            <Typography component="h1" fontFamily={"Inter"} variant="h6" color="inherit" noWrap sx={{ flexGrow: 1 }}><b>Strategic Finance Program</b></Typography>
            <Link to="/" className="link">
            <IconButton color="inherit">
                <HomeIcon />
            </IconButton>
            </Link>
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <Toolbar sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', px: [1],}}>
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <Divider />
          <List component="nav">
            {mainListItems}
            <Divider sx={{ my: 1 }} />
            {secondaryListItems}
          </List>
        </Drawer>

        <Box component="main" sx={{ flexGrow: 1, height: '100vh', overflow: 'auto',}}>
          <Toolbar />
                
          <Container maxWidth="lg" sx={{ mt: 4, mb: 2, ml: 1, mr: 1}}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={2} lg={2}></Grid>
              <Grid item xs={12} md={8} lg={8}>
                <Paper sx={{p: 2,display: 'flex', flexDirection: 'column', boxShadow: 'none'}}>


    <h1 className='program-header'>Income Statement: Southwest Airlines</h1>
        <p className='program-text'>Southwest is an example of the variety you will see with income statements. Take a look at their income statement below and try to identify any differences with Google's income statement. We'll go through the main differences one-by-one.</p>

    <img src={SouthwestIS1} alt='Southwest Income Statement'/><br/>

    <br/>
    <h2 className='glossary'><b>Quarterly</b></h2>
    <p className='glossary'>One thing that should have stuck out was that this is a quarterly income statement. Public companies are required to release quarterly and annual income statements but most companies will create monthly income statements for internal use only. StratFin teams are often responsible for the analysis of monthly income statements that the accounting team usually creates.</p>

    <br/>
    <img src={SouthwestIS2} alt='Southwest Income Statement'/><br/>
    <h2 className='glossary'><b>Revenue Segments</b></h2>
    <p className='glossary'>Southwest reveals to investors how much they make in each revenue segment. Revenue segments are different businesses within a company that provide additional detail to investors so they can better understand the business. </p>
     <p className='glossary'>It's not surprising that Southwest makes the majority of their revenue from passengers (flight tickets, upgrades, in-flight purchases, etc). Freight revenue comes from shipping commerical goods on Southwest flights while 'other revenue' is primarily revenue from Southwest's credit card partnerships.</p>

    <br/>
    <img src={SouthwestIS3} alt='Southwest Income Statement'/><br/>
    <h2 className='glossary'><b>Expense Categories</b></h2>
    <p className='glossary'>The most unique aspect of this income statement is that Southwest breaks out expense line items into specific categories, unlike Google and other large companies that use generic line items like 'research and development' and 'general and administrative'. Southwest specifically breaks out their top expense line items like salaries, fuel, and manintenance and has a line item called 'other operating expenses' that covers everything else. </p>

    <br/>
    <h2 className='glossary'><b>Operating Loss</b></h2>
    <p className='glossary'>This line item is the exact same as 'operating income' expect that Southwest lost money in both quarters here so they labeled it 'operating loss' instead of 'operating profit'. The same is true for net income at the bottom. Southwest had negative profits in these quarters so it is represented as a net loss.</p>

    <br/>
    <img src={SouthwestIS4} alt='Southwest Income Statement'/><br/>
    <h2 className='glossary'><b>Other Income</b></h2>
    <p className='glossary'>This is a good example of how two income statements are rarely identical. Southwest has four line items under other revenue.</p>


</Paper>
</Grid>
<Grid item xs={12} md={2} lg={2}></Grid>


<Grid item xs={12} md={2} lg={2}></Grid>
{/* <Grid item xs={12} md={8} lg={8}>
    <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', backgroundColor: '#dee8fa' }}>
        <h2 className='glossary'>Practice</h2>
        <a href='https://docs.google.com/spreadsheets/d/1WaLUa57uEKStVRF7deDIVpB9wI8ttQG0MSCaDaAgImQ/edit?gid=0#gid=0' target="_blank" rel="noreferrer" >Link</a>
    </Paper>
</Grid>
<Grid item xs={12} md={2} lg={2}></Grid> */}

            </Grid>
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
}