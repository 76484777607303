import * as React from 'react';
import { Link } from "react-router-dom";
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import EmailIcon from '@mui/icons-material/Email';

// SQL specific
import StorageIcon from '@mui/icons-material/Storage';
import SourceIcon from '@mui/icons-material/Source';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import FormatColorTextIcon from '@mui/icons-material/FormatColorText';
import FilterListIcon from '@mui/icons-material/FilterList';
import JoinLeftIcon from '@mui/icons-material/JoinLeft';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import CalculateIcon from '@mui/icons-material/Calculate';
import BookIcon from '@mui/icons-material/Book';
import PublicIcon from '@mui/icons-material/Public';

import './sql.css'

export const mainListItems = (
  <React.Fragment>
    <Link to="/sql" className="link-text"><ListItemButton><ListItemIcon><StorageIcon /></ListItemIcon><ListItemText primary="Welcome" /></ListItemButton></Link>
    <Link to="/sql/getting-data" className="link-text"><ListItemButton><ListItemIcon><SourceIcon /></ListItemIcon><ListItemText primary="Getting Data"/></ListItemButton></Link>
    <Link to="/sql/sorting-data" className="link-text"><ListItemButton><ListItemIcon><SwapVertIcon /></ListItemIcon><ListItemText primary="Sorting Data"/></ListItemButton></Link>
    <Link to="/sql/formatting" className="link-text"><ListItemButton><ListItemIcon><FormatColorTextIcon /></ListItemIcon><ListItemText primary="Formatting"/></ListItemButton></Link>
    <Link to="/sql/filtering-data" className="link-text"><ListItemButton><ListItemIcon><FilterListIcon /></ListItemIcon><ListItemText primary="Filtering Data"/></ListItemButton></Link>
    <Link to="/sql/calculations" className="link-text"><ListItemButton><ListItemIcon><CalculateIcon /></ListItemIcon><ListItemText primary="Calculations" /></ListItemButton></Link>
    <Link to="/sql/combining" className="link-text"><ListItemButton><ListItemIcon><JoinLeftIcon /></ListItemIcon><ListItemText primary="Combining Data" /></ListItemButton></Link>
    <ListItemButton><ListItemIcon><LightbulbIcon /></ListItemIcon><ListItemText primary="Advanced SQL" /></ListItemButton>
    <Link to="/sql/realworld" className="link-text"><ListItemButton><ListItemIcon><PublicIcon /></ListItemIcon><ListItemText primary="Real World SQL" /></ListItemButton></Link>

  </React.Fragment>
);

export const secondaryListItems = (
  <React.Fragment>
    <Link to="/sql/resources" className="link-text">
    <ListSubheader component="div" inset>
      Need Help?
    </ListSubheader>
    <ListItemButton>
      <ListItemIcon>
        <EmailIcon />
      </ListItemIcon>
      <ListItemText primary="Resources" />
    </ListItemButton>
    </Link>
    <Link to="/sql/glossary" className="link-text">
    <ListItemButton>
      <ListItemIcon>
        <BookIcon />
      </ListItemIcon>
      <ListItemText primary="Glossary" />
    </ListItemButton>
    </Link>
  </React.Fragment>
);